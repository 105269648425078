import PropTypes from 'prop-types';

import { Field, getIn } from 'formik';

import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import { CountrySelect } from 'Components/CountrySelect';

const CountrySelectForm = ({ ariaRequired, name, label, list, theme, testId }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const isInputTouched = getIn(form.touched, name);
        const inputError = isInputTouched ? getIn(form.errors, name) : '';

        return (
          <CountrySelect
            ariaInvalid={Boolean(inputError)}
            ariaRequired={ariaRequired}
            error={isInputTouched && inputError && `Country ${inputError.toLowerCase()}`}
            label={label}
            list={list}
            onChange={event => form.setFieldValue(name, event.target.value)}
            testId={testId}
            theme={theme}
            value={field.value}
          />
        );
      }}
    </Field>
  );
};

CountrySelectForm.propTypes = {
  // Metadata for screen readers
  ariaRequired: PropTypes.bool,
  // Label to describe the input
  label: PropTypes.string.isRequired,
  // Name can be a nested path like newAddress.address1
  name: PropTypes.string.isRequired,
  // Test id for cypress
  testId: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  theme: PropTypes.oneOf([CHECKOUT_DEFAULT_THEME, CHECKOUT_OPTIMISATION_THEME]),
};

CountrySelectForm.defaultProps = {
  ariaRequired: false,
  testId: null,
  list: [],
  theme: null,
};

export default CountrySelectForm;
