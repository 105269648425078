import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

import { ReactComponent as SunIcon } from 'assets/images/skincare/skintypeMorning.svg';

const skintypeMorning = {
  name: 'skintype_morning',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/skintype-morning`,
  title: 'How does your skin feel in the morning before washing your face?',
  icon: <SunIcon />,
  subtitle:
    'Answer this based on a morning when you didn’t use any oils or creams the night before.',
  tip: {
    title: 'Why we ask:',
    content:
      'Understanding how your skin feels at different points in the day helps us to tailor your unique formulas. Because your skin repairs itself at night, it should recover from the previous day by the morning.',
  },
  options: [
    {
      value: 'tights',
      label: 'On the dry side',
      description: 'Face may feel tight or appear matte',
    },
    {
      value: 'balanced',
      label: 'Balanced',
      description: 'Face feels comfortable and relatively hydrated',
    },
    {
      value: 'oily',
      label: 'On the oily side',
      description: 'Face may feel slick or appear shiny',
    },
    {
      value: 'depends_on_area',
      label: 'Mixed',
      description: 'Some areas of the face are oily, while others feel dry or tight',
    },
    {
      value: 'not_sure',
      label: 'Not sure / I don’t know',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_skintype_morning,
  getAnswers: ({ selected }) => ({
    diag_skintype_morning: selected,
  }),
};

export default skintypeMorning;
