import currencies from 'constants/currencies';

import imageWithoutGift from 'assets/images/promotionalModals/trial-offer-skincare-minis-promotion-modal-without-gift.jpg';

const tracking = {
  modalClosing: 'Landing - SK Minis + HC trial Popup - Closed',
  modalOpening: 'Landing - SK Minis + HC trial Popup - Viewed',
  modalOpening2sec: 'Landing - SK Minis + HC trial Popup - 2 Sec Viewed',
};

const content = {
  banner: 'Get 50% off haircare or try skincare on Prose',
  popUpModal: {
    [currencies.USD]: {
      tracking,
      image: imageWithoutGift,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description: {
        mobile: (
          <>
            Claim your exclusive haircare trial offer by subscribing to any hair product. Subscribe
            to 3+ skin products to unlock your Starter Set.
            <br />
            Limited time only!
          </>
        ),
        desktop:
          'Claim your exclusive haircare trial offer by subscribing to any hair product. Subscribe to 3+ skin products to unlock your Starter Set. Limited time only!',
      },
      cta: {
        ctaLabel: 'unlock offer',
      },
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set ($65 value) when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent
          subscription orders will receive free shipping and 15% off. Offers subject to expire and
          valid only while supplies last. Cannot be applied to previous orders or non-subscribed
          items. Not valid for accessories or digital gift cards. Exceptions may apply. For full
          details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-v2-hc-sc-pop-up"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      tracking,
      image: imageWithoutGift,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description: {
        mobile: (
          <>
            Claim your exclusive haircare trial offer by subscribing to any hair product. Subscribe
            to 3+ skin products to unlock your Starter Set.
            <br />
            Limited time only!
          </>
        ),
        desktop:
          'Claim your exclusive haircare trial offer by subscribing to any hair product. Subscribe to 3+ skin products to unlock your Starter Set. Limited time only!',
      },
      cta: {
        ctaLabel: 'unlock offer',
      },
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent subscription orders
          will receive free shipping and 15% off. Offers subject to expire and valid only while
          supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for
          accessories or digital gift cards. Exceptions may apply. For full details on our return
          policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-v2-hc-sc-pop-up"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
  },
  bottomModal: {
    [currencies.USD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off</b> when you subscribe to haircare. Plus, get a{' '}
          <b>complimentary Skincare Starter Set</b> when you subscribe to 3+ skincare products.
        </>
      ),
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set ($65 value) when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent
          subscription orders will receive free shipping and 15% off. Offers subject to expire and
          valid only while supplies last. Cannot be applied to previous orders or non-subscribed
          items. Not valid for accessories or digital gift cards. Exceptions may apply. For full
          details on our return policy, click{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off</b> when you subscribe to haircare. Plus, get a{' '}
          <b>complimentary Skincare Starter Set</b> when you subscribe to 3+ skincare products.
        </>
      ),
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order. Get your Skincare Starter
          Set when you subscribe to Cleanser, Serum, and Moisturizer. Subsequent subscription orders
          will receive free shipping and 15% off. Offers subject to expire and valid only while
          supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for
          accessories or digital gift cards. Exceptions may apply. For full details on our return
          policy, click{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            here.
          </a>
          .
        </>
      ),
    },
  },
} as const;

export default content;
