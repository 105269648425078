import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'dux/app/hooks';

import CheckoutBlock from 'Apps/Checkout/Blocks/CheckoutBlock';
import SelectAddress from 'Apps/Checkout/Blocks/SelectAddress';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';
import { useCheckoutCategory } from 'Apps/Checkout/hooks/useCheckoutCategory';

import Spacer from 'Components/Spacer';
import { Typography } from 'Components/Typography';

import addOriginParam from 'utils/addOriginParam';

import { fetchCountries } from 'dux/countries/thunks';

import useCheckoutAddressInfosByCartType from '../hooks/useCheckoutAddressInfosByCartType';

const CheckoutAddress = ({
  isSubmitting,
  newAddress,
  params,
  onCancel,
  isCurrentStep,
  resetNewAddressForm,
}) => {
  const checkoutCategory = useCheckoutCategory();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    savedSelectedAddress,
    cartHasSubscription,
    hasCompletedShippingAddressSection,
    isCartV2,
  } = useCheckoutAddressInfosByCartType();

  const onEdit = () =>
    navigate(
      addOriginParam(params)(`/checkout/${checkoutCategory}/${checkoutSteps.SHIPPING_ADDRESS}`)
    );

  const selectedAddress = savedSelectedAddress || newAddress;
  const selectedCountry = newAddress.country;

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const isReadOnly = !isCurrentStep && hasCompletedShippingAddressSection;

  return (
    <CheckoutBlock
      dataTestId="addresses-block"
      id="checkout-address-block"
      onEdit={isReadOnly ? onEdit : undefined}
      title="Shipping address"
    >
      {!isReadOnly && (
        <>
          {!isCartV2 && cartHasSubscription && (
            <>
              <Typography align="left" color="noirDark" variant="p2">
                We’ll use this address for all of your subscription orders.
              </Typography>
              <Spacer size={20} />
            </>
          )}

          <SelectAddress
            isCurrentStep={isCurrentStep}
            isSubmitting={isSubmitting}
            onCancel={onCancel}
            resetNewAddressForm={resetNewAddressForm}
            selectedCountry={selectedCountry}
          />
        </>
      )}

      {isReadOnly && (
        // Address summary when not editing
        <>
          {/* User name */}
          <Typography
            align="left"
            color="noirDark"
            data-testid="address-form-readonly-name"
            variant="p2"
          >
            {selectedAddress.name}
          </Typography>

          {/* Address 1 */}
          <Typography
            align="left"
            color="noirDark"
            data-testid="address-form-readonly-1"
            variant="p2"
          >
            {selectedAddress.address1}
          </Typography>

          {/* Address 2 */}
          {selectedAddress.address2 && (
            <Typography
              align="left"
              color="noirDark"
              data-testid="address-form-readonly-2"
              variant="p2"
            >
              {selectedAddress.address2}
            </Typography>
          )}

          {/* Address city, state, zipcode and country */}
          <Typography align="left" color="noirDark" variant="p2">
            <span data-testid="address-form-readonly-city">{selectedAddress.city}</span>,{' '}
            <span data-testid="address-form-readonly-state">{selectedAddress.state}</span>{' '}
            <span data-testid="address-form-readonly-zipcode">{selectedAddress.zipcode}</span>{' '}
            <span data-testid="address-form-readonly-country">{selectedAddress.country}</span>
          </Typography>
        </>
      )}
    </CheckoutBlock>
  );
};

CheckoutAddress.propTypes = forbidExtraProps({
  isSubmitting: PropTypes.bool.isRequired,
  newAddress: PropTypes.shape({ country: PropTypes.string }).isRequired,
  params: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func,
  resetNewAddressForm: PropTypes.func.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
});

CheckoutAddress.defaultProps = {
  onCancel: null,
};

export default CheckoutAddress;
