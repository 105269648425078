/**
 * Intialize variables destined to the client with env value.
 */
export const initPublicEnvs = () => {
  const ENV = {
    REACT_APP_FLAG_SCALP_SERUM_CONTENT: process.env.REACT_APP_FLAG_SCALP_SERUM_CONTENT,
    REACT_APP_FLAG_SUBSCRIPTION_ITEM_REMOVAL_AB_TEST:
      process.env.REACT_APP_FLAG_SUBSCRIPTION_ITEM_REMOVAL_AB_TEST,
    REACT_APP_FLAG_POST_PURCHASE_POPUP_AB_TEST:
      process.env.REACT_APP_FLAG_POST_PURCHASE_POPUP_AB_TEST,
    REACT_APP_FLAG_POST_PURCHASE_PROMO_DESIGN_PHASE2_AB_TEST:
      process.env.REACT_APP_FLAG_POST_PURCHASE_PROMO_DESIGN_PHASE2_AB_TEST,
    REACT_APP_FLAG_KEY_ACTION_CARD_AB_TEST: process.env.REACT_APP_FLAG_KEY_ACTION_CARD_AB_TEST,
    REACT_APP_FLAG_SKINCARE_CONCERNS_ORDER_AB_TEST:
      process.env.REACT_APP_FLAG_SKINCARE_CONCERNS_ORDER_AB_TEST,
    REACT_APP_FLAG_NPS_MODAL: process.env.REACT_APP_FLAG_NPS_MODAL,
    REACT_APP_FLAG_SSS_SHIPPING_FEE_AB_TEST: process.env.REACT_APP_FLAG_SSS_SHIPPING_FEE_AB_TEST,
    REACT_APP_FLAG_SKINCARE_MINIS_FREQUENCY_AB_TEST:
      process.env.REACT_APP_FLAG_SKINCARE_MINIS_FREQUENCY_AB_TEST,
    REACT_APP_FLAG_SKINCARE_MINIS: process.env.REACT_APP_FLAG_SKINCARE_MINIS,
    REACT_APP_FLAG_POST_PURCHASE_PROMO_LAYOUT_AB_TEST:
      process.env.REACT_APP_FLAG_POST_PURCHASE_PROMO_LAYOUT_AB_TEST,
    REACT_APP_FLAG_FEEDBACK_AWARENESS_CTA_AB_TEST:
      process.env.REACT_APP_FLAG_FEEDBACK_AWARENESS_CTA_AB_TEST,
    REACT_APP_FLAG_VIDEO_IN_HOMEPAGE_HERO_AB_TEST:
      process.env.REACT_APP_FLAG_VIDEO_IN_HOMEPAGE_HERO_AB_TEST,
    REACT_APP_FLAG_SKINCARE_MINIS_PHASE_2: process.env.REACT_APP_FLAG_SKINCARE_MINIS_PHASE_2,
    REACT_APP_FLAG_PAYMENT_INTENT: process.env.REACT_APP_FLAG_PAYMENT_INTENT,
    REACT_APP_FLAG_ADDRESS_VALIDATION: process.env.REACT_APP_FLAG_ADDRESS_VALIDATION,
    REACT_APP_FLAG_PAYPAL: process.env.REACT_APP_FLAG_PAYPAL,
    REACT_APP_FLAG_DATA_DRIVEN_CONSULTATION: process.env.REACT_APP_FLAG_DATA_DRIVEN_CONSULTATION,
    REACT_APP_FLAG_TRIAL_OFFER: process.env.REACT_APP_FLAG_TRIAL_OFFER,
    REACT_APP_FLAG_DIGITAL_LEAFLET: process.env.REACT_APP_FLAG_DIGITAL_LEAFLET,
    REACT_APP_FLAG_PROMOTION_ACCESSORIES: process.env.REACT_APP_FLAG_PROMOTION_ACCESSORIES,
    REACT_APP_FLAG_BLACK_FRIDAY_2023: process.env.REACT_APP_FLAG_BLACK_FRIDAY_2023,
    REACT_APP_FLAG_CYBER_MONDAY_2023: process.env.REACT_APP_FLAG_CYBER_MONDAY_2023,
    REACT_APP_FLAG_GROWTHBOOK_FAKE_EXPERIMENT_AB_TEST:
      process.env.REACT_APP_FLAG_GROWTHBOOK_FAKE_EXPERIMENT_AB_TEST,
    REACT_APP_FLAG_PROSE_UK: process.env.REACT_APP_FLAG_PROSE_UK,
    REACT_APP_FLAG_SUBSCRIPTION_SNOOZE_BUTTON:
      process.env.REACT_APP_FLAG_SUBSCRIPTION_SNOOZE_BUTTON,
    REACT_APP_FLAG_BRIGHTBACK: process.env.REACT_APP_FLAG_BRIGHTBACK,
    REACT_APP_FLAG_SKINCARE_PROMOTIONAL_MODAL:
      process.env.REACT_APP_FLAG_SKINCARE_PROMOTIONAL_MODAL,
    REACT_APP_D2C_SALON: process.env.REACT_APP_D2C_SALON,
    REACT_APP_FRIENDBUY_ADVOCATE_AMOUNT: process.env.REACT_APP_FRIENDBUY_ADVOCATE_AMOUNT,
    REACT_APP_FRIENDBUY_REFERREE_AMOUNT: process.env.REACT_APP_FRIENDBUY_REFERREE_AMOUNT,
    REACT_APP_FRIENDBUY_SITE: process.env.REACT_APP_FRIENDBUY_SITE,
    REACT_APP_FRIENDBUY_WIDGET_ID: process.env.REACT_APP_FRIENDBUY_WIDGET_ID,
    REACT_APP_FRIENDBUY_MERCHANT_ID: process.env.REACT_APP_FRIENDBUY_MERCHANT_ID,
    REACT_APP_PROSE_INSTAGRAM_ID: process.env.REACT_APP_PROSE_INSTAGRAM_ID,
    REACT_APP_LIKESHOP_GALLERY_WIDGET_URL: process.env.REACT_APP_LIKESHOP_GALLERY_WIDGET_URL,
    REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID,
    REACT_APP_HEAP_ID: process.env.REACT_APP_HEAP_ID,
    REACT_APP_SEGMENT_KEY: process.env.REACT_APP_SEGMENT_KEY,
    REACT_APP_BRIGHTBACK_APP_ID: process.env.REACT_APP_BRIGHTBACK_APP_ID,
    REACT_APP_MEDIA_ROOT_URL: process.env.REACT_APP_MEDIA_ROOT_URL,
    REACT_APP_CLOUD_FUNCTIONS_URL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL,
    REACT_APP_PINTEREST_VERIFICATION: process.env.REACT_APP_PINTEREST_VERIFICATION,
    REACT_APP_ROOT_URL: process.env.REACT_APP_ROOT_URL,
    REACT_APP_SENTRY: process.env.REACT_APP_SENTRY,
    REACT_APP_SENTRY_RATE: process.env.REACT_APP_SENTRY_RATE,
    REACT_APP_ENV: process.env.REACT_APP_ENV,
    REACT_APP_SHOW_VERSION: process.env.REACT_APP_SHOW_VERSION,
    REACT_APP_STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    REACT_APP_VERSION_ALGO: process.env.REACT_APP_VERSION_ALGO,
    REACT_APP_VERSION_API: process.env.REACT_APP_VERSION_API,
    REACT_APP_VERSION_WWW: process.env.REACT_APP_VERSION_WWW,
    REACT_APP_FLAG_SUPPLEMENTS_REFILL_POUCHES:
      process.env.REACT_APP_FLAG_SUPPLEMENTS_REFILL_POUCHES,
    REACT_APP_FLAG_EXPRESS_CHECKOUT: process.env.REACT_APP_FLAG_EXPRESS_CHECKOUT,
    REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_SUBSCRIPTION:
      process.env.REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_SUBSCRIPTION,
    REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_CART: process.env.REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_CART,
    REACT_APP_SHOULD_LOAD_TRACKING: Boolean(
      process.env.REACT_APP_SHOULD_LOAD_TRACKING?.match(/true/i),
    ),
    SENTRY_TRACES_SAMPLE_RATE: process.env.NODE_ENV === 'production' ? 0.05 : 0,
    REACT_APP_DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME,
    REACT_APP_FLAG_ACCOUNT_CROSS_SELL_BANNER_AB_TEST:
      process.env.REACT_APP_FLAG_ACCOUNT_CROSS_SELL_BANNER_AB_TEST,
    REACT_APP_REDUX_LOGGER:
      process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REDUX_LOGGER ?? false : false,
  } as const;
  return ENV;
};
export type PublicEnv = ReturnType<typeof initPublicEnvs>;
