import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { Link } from 'react-router-dom';

import { useAppSelector as useSelector } from 'dux/app/hooks';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { Field, getIn } from 'formik';

import CheckoutBlock from 'Apps/Checkout/Blocks/CheckoutBlock';
import { CHECKOUT_OPTIMISATION_THEME } from 'Apps/Checkout/constants/checkoutInputThemes';

import PhoneTextField from 'Components/PhoneTextField';
import Spacer from 'Components/Spacer';
import TextOptin from 'Components/TextOptinComponents';
import { Typography } from 'Components/Typography';
import PaymentTextField from '../Components/PaymentTextField';

import { labelWithToolTipCheckoutOptimisation as marketingSmsLabelCheckoutOptimisation } from 'assets/content/marketingSmsOptin';
import { labelCheckoutOptimisation as transactionalSmsLabelCheckoutOptimisation } from 'assets/content/transactionalSmsOptin';

import { getHasCompletedAccountDetailsSection } from 'dux/checkout/selectors';

const Wrapper = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 300px;
  }
`;

const CheckoutCreateAccount = ({ isCurrentStep }) => {
  const hasCompletedAccountDetailsSection = useSelector(getHasCompletedAccountDetailsSection);

  const isReadyOnly = !isCurrentStep && hasCompletedAccountDetailsSection;

  return (
    <CheckoutBlock dataTestId="contact-summary" id="checkout-contact-block" title="Create account*">
      <Wrapper>
        <Typography variant="p1">
          Already have an account?{' '}
          <Link to="/signin?next=/checkout/accessories/account-details">Sign in</Link>
        </Typography>
        <Spacer size={10} />
        <PaymentTextField
          autoCorrect={false}
          label="First name*"
          name="account.firstName"
          theme={CHECKOUT_OPTIMISATION_THEME}
        />
        <PaymentTextField
          autoCorrect={false}
          label="Last name*"
          name="account.lastName"
          theme={CHECKOUT_OPTIMISATION_THEME}
        />
        <PaymentTextField
          autoCorrect={false}
          label="Email*"
          name="account.email"
          theme={CHECKOUT_OPTIMISATION_THEME}
        />
        <Field name="account.phone">
          {({ field, form }) => {
            const isInputTouched = getIn(form.touched, 'account.phone');
            const inputError = isInputTouched ? getIn(form.errors, 'account.phone') : '';

            return (
              <PhoneTextField
                autoCorrect={false}
                error={inputError}
                id="phone"
                label="Phone number*"
                onBlur={() => form.setFieldTouched('account.phone', true)}
                onChange={value => form.setFieldValue('account.phone', value)}
                theme={CHECKOUT_OPTIMISATION_THEME}
                value={field.value}
              />
            );
          }}
        </Field>
      </Wrapper>
      <Spacer size={10} />
      <Typography color="grey" variant="p3">
        By checking the box(es) below and entering your phone number above, you consent to receive
        recurring account-related text messages (such as order and shipping confirmation, or error
        alerts) and/or marketing text messages (such as ads, promotions, and special offers) from
        Prose from time to time at the number provided, including messages sent using an automatic
        telephone dialing system. You further agree to our{' '}
        <Link target="_blank" to="/terms">
          Terms of Use
        </Link>
        . Consent is not a condition of any purchase. You can opt-out at any time by replying STOP
        to any one of our messages or by logging into your Prose account and visiting your
        communication settings in your{' '}
        <Link target="_blank" to="/account/settings">
          Account Details
        </Link>
        . Message and data rates may apply. Message frequency varies. Visit our{' '}
        <Link target="_blank" to="/privacy">
          Privacy Policy
        </Link>{' '}
        for more information.
      </Typography>

      <Spacer size={10} />

      <TextOptin
        color="noirDark"
        content={transactionalSmsLabelCheckoutOptimisation}
        dataPrefix="transactional-sms"
        displayMode={isReadyOnly}
        fieldName="textOptin"
        isEditable={isCurrentStep}
        labelVariant="p2"
      />

      <TextOptin
        color="noirDark"
        content={marketingSmsLabelCheckoutOptimisation}
        dataPrefix="marketing-sms"
        displayMode={isReadyOnly}
        fieldName="textMarketingOptin"
        isEditable={isCurrentStep}
        labelVariant="p2"
      />
    </CheckoutBlock>
  );
};

CheckoutCreateAccount.propTypes = forbidExtraProps({
  isCurrentStep: PropTypes.bool.isRequired,
});

export default CheckoutCreateAccount;
