import PropTypes from 'prop-types';

import isString from 'lodash/fp/isString';
import toUpper from 'lodash/fp/toUpper';

import { legacyTheme, styled } from '@prose-ui/legacy';

import { Image } from 'Components/Image';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

import { formatPriceWithCurrency } from 'utils/currencies';

import { RedeemedGiftInfo } from './RedeemedGiftInfo';
import { Typography } from './Typography';

const TicketLineContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 14px;
  padding-left: ${({ isDiscountLine }) => isDiscountLine && '8px'};
`;

const Label = styled(Typography)`
  &:first-letter {
    text-transform: capitalize;
  }
`;
const Price = styled.div`
  display: flex;
  column-gap: ${legacyTheme.spacing.s4};
`;

const ImageContainer = styled(Image)`
  margin-right: 4px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Sublabel = styled(Typography)`
  background-color: rgba(226, 255, 93, 0.7);
  flex-grow: 0;
  margin-right: auto;
  padding: 0 3px;
  line-height: 16px;
`;

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TicketLineV2 = ({
  coupon,
  currency,
  dataTestId,
  id,
  isBold,
  isDiscountLine,
  label,
  price,
  priceDisplay,
  productKey,
  sideImage,
  shipmentLabel,
}) => {
  // Not a coupon (used for order history)
  const needsStrikeThrough = priceDisplay && !coupon && !/gift/i.test(priceDisplay);
  // Format price to string
  const originalPrice = isString(price)
    ? toUpper(price)
    : `${price < 0 ? '-' : ''}${formatPriceWithCurrency({
        price: Math.abs(price).toFixed(2),
        currency,
      })}`;

  return (
    <TicketLineContainer
      aria-labelledby={id}
      data-testid={dataTestId}
      isDiscountLine={isDiscountLine}
    >
      {sideImage && <ImageContainer alt={label} src={sideImage} width={33} />}

      <LabelContainer>
        <Label color={isDiscountLine ? 'grey' : 'noir'} id={id} medium={isBold} variant="p1">
          {label}
        </Label>

        {shipmentLabel && (
          <Sublabel align="left" variant="p2">
            {shipmentLabel}
          </Sublabel>
        )}
        {productKey === 'giftset-gift' && currency && (
          <RedeemedGiftInfo currency={currency} origin={origin} />
        )}
      </LabelContainer>

      <Price>
        {needsStrikeThrough ? (
          <PricesContainer>
            <Typography align="right" inline variant="p1">
              {isString(priceDisplay)
                ? priceDisplay
                : formatPriceWithCurrency({
                    price: Math.abs(priceDisplay).toFixed(2),
                    currency,
                  })}
            </Typography>
            <Typography align="right" aria-hidden="true" color="grey" variant="p3">
              <s>{originalPrice}</s>
            </Typography>
          </PricesContainer>
        ) : (
          <Typography
            color={isDiscountLine ? 'grey' : 'noir'}
            data-testid={`none-striked-${productKey}-price`}
            medium={isBold}
            variant="p1"
          >
            {originalPrice}
          </Typography>
        )}
      </Price>
    </TicketLineContainer>
  );
};

TicketLineV2.propTypes = {
  coupon: PropTypes.bool,
  currency: PropTypes.string,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  isBold: PropTypes.bool,
  isDiscountLine: PropTypes.bool,
  label: PropTypes.string.isRequired,
  // Original Price
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productKey: PropTypes.string,
  // New Price
  priceDisplay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shipmentLabel: PropTypes.string,
  sideImage: srcOrNextImageProp,
};

TicketLineV2.defaultProps = {
  currency: '',
  coupon: false,
  dataTestId: null,
  id: null,
  isBold: false,
  isDiscountLine: false,
  priceDisplay: null,
  productKey: '',
  shipmentLabel: null,
  sideImage: null,
};

export default TicketLineV2;
