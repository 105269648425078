import { MY_LIFESTYLE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

const vices = {
  name: 'vices',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/vices`,
  title: 'Do any of these habits apply to you?',
  subtitle:
    'Select all that apply. There’s no judgment — we ask because these habits can affect your skin.',
  options: [
    { label: 'I eat fast or processed food 3 times or more per week', value: 'processed_food' },
    { label: 'I drink alcohol 3 times or more per week', value: 'alcohol' },
    { label: 'I drink coffee 3 times or more per day', value: 'coffee' },
    { label: 'I eat sweets or dessert regularly', value: 'sweets' },
    { label: 'I smoke, vape, or am regularly exposed to cigarette smoke', value: 'smoke' },
    { label: 'None of these apply to me', value: 'none', unique: true },
  ],
  multi: true,
  tip: {
    title: 'Why we ask:',
    content:
      'These habits can lead to imbalances in our skin like dryness, breakouts, or premature aging.',
  },
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_vices,
  getAnswers: ({ selected }) => ({ profile_vices: selected }),
};

export default vices;
