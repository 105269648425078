import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { Button } from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import { Typography } from 'Components/Typography';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

import { formatPriceWithCurrency } from 'utils/currencies';

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Title = styled(Typography)`
  font-size: 20px;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 216px;
  min-width: 216px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: scroll;
  ${legacyTheme.breakpoints.up('sm')} {
    overflow-x: visible;
  }
`;

const Image = styled.img`
  height: 174px;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 24px 16px;
`;

const UnderLabel = styled(Typography)`
  font-size: 10px;
  color: ${theme.colors.primary[400]};
  letter-spacing: 1px;
`;

const Description = styled(Typography)`
  font-size: 10px;
  color: ${theme.colors.primary[400]};
  letter-spacing: 1px;
`;

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  width: 184px;
  min-width: 184px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
`;

const CardTitle = styled(Typography)`
  color: ${theme.colors.primary[400]};
`;

const UpsaleSectionCartV2 = ({ list, onAddProduct, isUpdating, currency }) => (
  <Container>
    <Title variant="h1">Accessorize your routine</Title>
    <Spacer size={16} />
    <CardsContainer>
      {list.map((product, index) => (
        <Card key={product.product_slug || product.slug}>
          <Image alt={product.altPicture} src={product.picture.src} />
          <Content>
            <CardTitle variant="p1">{product.label}</CardTitle>
            <Spacer size={8} />
            <UnderLabel variant="mono1">{product.underLabel}</UnderLabel>
            <Spacer size={8} />
            <Description variant="mono1">{product.description}</Description>
            <Spacer size={70} />
            <StyledButton
              data-testid={`${index}-add-button-upsell-product`}
              disabled={isUpdating}
              isLoading={isUpdating}
              onClick={() => onAddProduct(product)}
              variant="vert"
            >
              {`Add - ${formatPriceWithCurrency({ price: product.price, currency })}`}
            </StyledButton>
          </Content>
        </Card>
      ))}
    </CardsContainer>
  </Container>
);

UpsaleSectionCartV2.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      underLabel: PropTypes.string,
      description: PropTypes.string,
      picture: srcOrNextImageProp,
    })
  ).isRequired,
  onAddProduct: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  currency: PropTypes.string.isRequired,
};

UpsaleSectionCartV2.defaultProps = {
  isUpdating: false,
};

export default UpsaleSectionCartV2;
