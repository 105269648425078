import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { Button } from 'Components/LegacyButton';

import { handleKeyPress } from 'utils/a11y';

import CheckoutSummaryHeader from './OrderSummarHeader';
import OrderSummaryPromoCommunication from './OrderSummaryPromoCommunication';

const LowercaseOrderSummaryPromoCommunication = styled(OrderSummaryPromoCommunication)`
  text-transform: lowercase;
`;

const MobileFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.colors.neutral[100]};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
`;

const CTA = styled(Button)`
  margin: 0;
  position: relative;
`;

const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
`;

const CartMobileFooter = ({ ctaButtonProps, onToggleCart }) => (
  <MobileFooter>
    <Drawer
      aria-label="Open checkout ticket modal"
      data-click="order-summary-open"
      data-from="cart"
      data-testid="cart-order-summary-drawer"
      onClick={onToggleCart}
      onKeyPress={handleKeyPress(onToggleCart)}
      role="button"
      tabIndex={0}
    >
      <CheckoutSummaryHeader />
      <LowercaseOrderSummaryPromoCommunication />
    </Drawer>

    {ctaButtonProps.text && (
      <CTA
        data-testid="mobile-footer-checkout-ticket-button"
        disabled={ctaButtonProps.disabled}
        fullWidth
        isLoading={ctaButtonProps.isLoading}
        onClick={ctaButtonProps.onClick}
        type={ctaButtonProps.type}
        variant={ctaButtonProps.variant}
      >
        {ctaButtonProps.text}
      </CTA>
    )}
  </MobileFooter>
);

CartMobileFooter.propTypes = {
  ctaButtonProps: PropTypes.shape({
    text: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    variant: PropTypes.string,
  }),
  onToggleCart: PropTypes.func.isRequired,
};

CartMobileFooter.defaultProps = {
  ctaButtonProps: {},
};

export default CartMobileFooter;
