import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const yourSkinWrinkles = {
  name: 'wrinkles',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/wrinkles`,
  title: 'What kind of wrinkles can we help with?',
  subtitle: 'Select all that apply.',
  tip: {
    title: 'How to choose',
    content:
      'Fine lines and wrinkles tell the story of your life, and that makes them beautiful, but we get it: sometimes you want to keep a bit of mystery. Assessing the type of wrinkles you have will help us select the perfect active ingredients for your customized formulas.',
  },
  options: [
    {
      value: 'fine_lines',
      label: 'Fine lines',
      description: 'Shallow lines, most visible when you move your face',
    },
    {
      value: 'expression_lines',
      label: 'Expression lines',
      description: "Including crow's feet and lines around the mouth",
    },
    {
      value: 'vertical_lines',
      label: 'Vertical wrinkles',
      description: 'Including smile lines or creases between your eyebrows',
    },
    {
      value: 'deep_wrinkles',
      label: 'Deep wrinkles',
      description: 'Deeply penetrating lines, visible when the face is at rest',
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('fine_lines'),
  getInitialValue: answers => answers.diag_wrinkles,
  getAnswers: ({ selected }) => ({
    diag_wrinkles: selected,
  }),
  multi: true,
};

export default yourSkinWrinkles;
