import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import valuesOf from 'lodash/fp/values';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import { useGoToCart } from 'hooks/useGoToCart';
import { makeStyles } from 'legacyStyles';

import { RecommendedAddressModal } from 'Apps/Checkout/Components/AddressValidationModalCorrectAddress';
import { CHECKOUT_NEW_ADDRESS_FIELD_VALUE } from 'Apps/Checkout/constants/checkoutInputValues';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';
import BaseScene from 'Apps/Checkout/Scenes/BaseScene';

import { Button } from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import { Typography } from 'Components/Typography';
import AddressValidationModal from '../Components/AddressValidationModal';
import CheckoutSummaryAccordion from '../Components/CheckoutSummaryAccordion';

import CheckoutAccountContact from '../Blocks/CheckoutAccountContact';
import CheckoutAddress from '../Blocks/CheckoutAddress';
import CheckoutCreateAccount from '../Blocks/CheckoutCreateAccount';
import { CheckoutLegalBlock } from '../Blocks/CheckoutLegalBlock';
import CheckoutPayment from '../Blocks/CheckoutPayment';
import CheckoutTicketV2 from '../Blocks/CheckoutTicketV2';
import CheckoutExpressPayment from '../Blocks/ExpressCheckoutPayment';
import ShippingMethod from '../Blocks/ShippingMethod';

import addOriginParam from 'utils/addOriginParam';
import useResponsiveVariant from 'utils/useResponsiveVariant';
import { makeValidationsByCountry } from 'utils/validators';

import * as checkoutActions from 'dux/checkout/actions';
import * as accountCardsSelectors from 'dux/accountCards/selectors';
import { getIsAuthenticated } from 'dux/auth/selectors';
import {
  getCartV2IsFree,
  getCTAButtonLabelFromStepPropV2,
  getDefaultAddressPubkeyV2,
  getHasCompletedShippingAddressSectionV2,
  getIsCheckoutReadyForPaymentV2,
  getNextStepFromStepPropV2,
} from 'dux/cartV2/selectors';
import * as checkoutSelectors from 'dux/checkout/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import { getIsPaymentReady } from 'dux/checkoutPayment/selectors';
import * as userSelectors from 'dux/user/selectors';

import { useCheckoutCategory } from '../hooks/useCheckoutCategory';

const CheckoutContent = styled.section`
  grid-column-start: 1;
  width: 100%;
  max-width: 800px;
  padding: ${legacyTheme.props.minimumBlockPadding};
`;

const NextStepButton = styled(Button)`
  height: 48px;
  line-height: 48px;
`;

const useStyles = makeStyles({
  baseScene: {
    display: 'grid',
    alignItems: 'flex-start',
    justifyItems: 'end',
    gridTemplateColumns: '60% 40%',

    [legacyTheme.breakpoints.match('sm')]: {
      display: 'block',
    },
  },
});

const SectionBorder = styled.hr`
  margin: 24px 0;
  border: none;
  border-top: 1px solid rgba(40, 51, 51, 0.2);
`;

const validationForStep = {
  [checkoutSteps.ACCOUNT_DETAILS]: values => ({
    ...makeValidationsByCountry(values, [
      ['account.phone', 'required'],
      ['account.phone', 'phone'],
      ['account.firstName', 'required'],
      ['account.firstName', 'firstName'],
      ['account.lastName', 'required'],
      ['account.lastName', 'lastName'],
      ['account.email', 'required'],
      ['account.email', 'email'],
    ]),
  }),
  [checkoutSteps.CREATE_ACCOUNT]: values => ({
    ...makeValidationsByCountry(values, [
      ['account.phone', 'required'],
      ['account.phone', 'phone'],
      ['account.firstName', 'required'],
      ['account.firstName', 'firstName'],
      ['account.lastName', 'required'],
      ['account.lastName', 'lastName'],
      ['account.email', 'required'],
      ['account.email', 'email'],
    ]),
  }),
  [checkoutSteps.SHIPPING_ADDRESS]: values => ({
    ...(values.addressPubkey === CHECKOUT_NEW_ADDRESS_FIELD_VALUE
      ? makeValidationsByCountry(values, [
          ['newAddress.name', 'required'],
          ['newAddress.name', 'fullName'],
          ['newAddress.address1', 'required'],
          ['newAddress.address1', 'address1'],
          ['newAddress.address2', 'address2'],
          ['newAddress.city', 'required'],
          ['newAddress.state', 'required'],
          ['newAddress.state', 'state'],
          ['newAddress.country', 'required'],
          ['newAddress.country', 'country'],
          ['newAddress.zipcode', 'required'],
          ['newAddress.zipcode', 'zipcode'],
        ])
      : {}),
  }),
  [checkoutSteps.PAYMENT]: values => ({
    ...(values.cardPubkey === 'newCard'
      ? makeValidationsByCountry(values, [['nameOnCard', 'required']])
      : {}),
  }),
  // No validation for Success
  [checkoutSteps.SUCCESS]: () => ({}),
};

const CheckoutFormV2 = ({ params, step }) => {
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const checkoutCategory = useCheckoutCategory();
  const { classes } = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const goToCart = useGoToCart();
  const { isMobile } = useResponsiveVariant();
  const withOrigin = addOriginParam(params);

  const defaultAddressPubkey = useAppSelector(getDefaultAddressPubkeyV2);
  const defaultCardPubkey = useAppSelector(accountCardsSelectors.getPreselectedCardId);
  const phone = useAppSelector(userSelectors.getUserPhone);
  const textOptin = useAppSelector(userSelectors.getTextOptin);
  const textMarketingOptin = useAppSelector(userSelectors.getTextMarketingOptin);
  const consultationProfileCountry = useAppSelector(
    checkoutCartSelectors.getConsultationProfileCountryInCartSurvey
  );
  const consultationProfileZipcode = useAppSelector(
    checkoutCartSelectors.getConsultationProfileZipcodenCartSurvey
  );
  const isCheckoutReadyForPayment = useAppSelector(getIsCheckoutReadyForPaymentV2);
  const isFree = useAppSelector(getCartV2IsFree);
  const updateStatusIsLoading = useAppSelector(checkoutCartSelectors.getUpdateStatusIsLoading);
  const buttonLabel = useAppSelector(state => getCTAButtonLabelFromStepPropV2(state, { step }));
  const user = useAppSelector(userSelectors.getUser);
  const isPaymentReady = useAppSelector(getIsPaymentReady);
  const nextStep = useAppSelector(state => getNextStepFromStepPropV2(state, { step }));
  const hasCompletedAccountDetailsSection = useAppSelector(
    checkoutSelectors.getHasCompletedAccountDetailsSection
  );
  const hasCompletedShippingAddressSection = useAppSelector(
    getHasCompletedShippingAddressSectionV2
  );
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', goToCart);

    return () => {
      window.removeEventListener('popstate', goToCart);
    };
  }, []);

  const onSubmit = async (values, actions) => {
    const cardElement = elements.getElement(CardElement);
    // provide react props
    const stepByStepCheckoutSubmit = checkoutActions.createStepByStepCheckoutSubmitV2({
      step,
      checkoutCategory,
      stripe,
      params,
      cardElement,
    });
    // then provide formik values
    const { nextRoute } = await dispatch(stepByStepCheckoutSubmit(values, actions, setError));
    navigate(nextRoute);
  };

  const shippingAddressInitialValues = {
    addressPubkey: defaultAddressPubkey || CHECKOUT_NEW_ADDRESS_FIELD_VALUE,
    newAddress: {
      name: user ? `${user.first_name} ${user.last_name}` : '',
      address1: '',
      address2: '',
      city: '',
      country: consultationProfileCountry || '',
      state: '',
      zipcode: consultationProfileZipcode || '',
      label: defaultAddressPubkey ? '' : 'Home',
    },
  };

  const accountContactInitialValues = {
    phone,
    textOptin: Boolean(textOptin),
    textMarketingOptin: Boolean(textMarketingOptin),
    account: {
      firstName: user?.first_name ?? '',
      lastName: user?.last_name ?? '',
      email: user?.email ?? '',
      phone,
    },
  };

  const cardInitialValues = {
    cardPubkey: defaultCardPubkey || 'newCard',
    nameOnCard: '',
    saveCard: false,
  };

  const isCtaButtonLoading = (isSubmitting, cardPubkey) => {
    switch (step) {
      case checkoutSteps.PAYMENT:
        return (
          (!isPaymentReady && cardPubkey === 'newCard') ||
          isSubmitting ||
          updateStatusIsLoading ||
          !isCheckoutReadyForPayment
        );
      default:
        return isSubmitting;
    }
  };

  const isButtonDisabled =
    (step === checkoutSteps.ACCOUNT_DETAILS && hasCompletedAccountDetailsSection) ||
    (step === checkoutSteps.SHIPPING_ADDRESS && hasCompletedShippingAddressSection && !isFree);

  return (
    <Formik
      initialValues={{
        // CheckoutReduceAndReuse
        // Pumps opt-out by default
        wantsPumps: false,
        // CheckoutAccountContact
        ...accountContactInitialValues,
        // ConnectedCheckoutAddress
        ...shippingAddressInitialValues,
        // ConnectedCheckoutPayment
        ...cardInitialValues,
      }}
      onSubmit={onSubmit}
      validate={validationForStep[step]}
    >
      {({ values, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <BaseScene classes={{ content: classes.baseScene }} justifyContent="start">
            {isMobile && <CheckoutSummaryAccordion isSubmitting={isSubmitting} step={step} />}

            <CheckoutContent>
              {!isMobile && (
                <>
                  <Typography align="center" markupName="h1" variant="h2">
                    Checkout
                  </Typography>
                  {!isMobile && <SectionBorder />}
                </>
              )}

              {step === checkoutSteps.CREATE_ACCOUNT && <CheckoutCreateAccount isCurrentStep />}

              {isAuthenticated && (
                <>
                  <AddressValidationModal
                    address={values.newAddress}
                    isSubmitting={isSubmitting}
                    step={step}
                    submit={handleSubmit}
                  />
                  <RecommendedAddressModal
                    address={values.newAddress}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    step={step}
                    submit={handleSubmit}
                  />

                  <CheckoutAccountContact
                    isCurrentStep={step === checkoutSteps.ACCOUNT_DETAILS}
                    isSubmitting={isSubmitting}
                    onCancel={() => {
                      resetForm({
                        values: {
                          ...values,
                          ...accountContactInitialValues,
                        },
                      });
                      navigate(withOrigin(`/checkout/${checkoutCategory}/${nextStep}`));
                    }}
                    params={params}
                    phone={values.phone}
                    textMarketingOptin={values.textMarketingOptin}
                    textOptin={values.textOptin}
                  />

                  {step !== checkoutSteps.PAYMENT && <CheckoutExpressPayment />}

                  {hasCompletedAccountDetailsSection && (
                    <CheckoutAddress
                      isCurrentStep={step === checkoutSteps.SHIPPING_ADDRESS}
                      isSubmitting={isSubmitting}
                      newAddress={values.newAddress}
                      onCancel={() => {
                        resetForm({
                          values: {
                            ...values,
                            ...shippingAddressInitialValues,
                          },
                        });
                        navigate(withOrigin(`/checkout/${checkoutCategory}/${nextStep}`));
                      }}
                      params={params}
                      resetNewAddressForm={() =>
                        setFieldValue('newAddress', shippingAddressInitialValues.newAddress)
                      }
                    />
                  )}

                  {hasCompletedShippingAddressSection && <ShippingMethod />}
                </>
              )}

              {/* Traditional payment flow */}
              {hasCompletedShippingAddressSection && (
                <CheckoutPayment
                  cardPubkey={values.cardPubkey}
                  params={params}
                  saveCard={values.saveCard}
                  step={step}
                />
              )}

              {error && (
                <>
                  <Typography align="center" color="red" variant="p2">
                    {error}
                  </Typography>
                  <Spacer size={16} />
                </>
              )}

              <NextStepButton
                data-testid="checkout-next-step-button"
                disabled={isButtonDisabled || isCtaButtonLoading(isSubmitting, values.cardPubkey)}
                fullWidth
                isLoading={isCtaButtonLoading(isSubmitting, values.cardPubkey)}
                noMargin
                type="submit"
                variant="vert"
              >
                {buttonLabel}
              </NextStepButton>

              {step === checkoutSteps.PAYMENT && <CheckoutLegalBlock />}
            </CheckoutContent>

            {!isMobile && (
              <CheckoutTicketV2
                ctaButtonProps={{
                  disabled: isButtonDisabled || isCtaButtonLoading(isSubmitting, values.cardPubkey),
                  isLoading: isCtaButtonLoading(isSubmitting, values.cardPubkey),
                  text: buttonLabel,
                  type: 'submit',
                  variant: 'vert',
                }}
                isCheckout
              />
            )}
          </BaseScene>
        </form>
      )}
    </Formik>
  );
};

CheckoutFormV2.propTypes = {
  params: PropTypes.shape({
    has: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  step: PropTypes.oneOf(valuesOf(checkoutSteps)).isRequired,
};

export default CheckoutFormV2;
