import * as APIClient from './APIClient';

type Payload = {
  QID1: number;
  QID3_TEXT?: string;
  progress: number;
  finished: number;
  order_pubkey: string;
};

export const post = async (payload: Payload) => APIClient.post('/v1/nps', payload);
