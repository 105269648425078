import { createSelector } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import reduce from 'lodash/fp/reduce';
import sortBy from 'lodash/fp/sortBy';
import without from 'lodash/fp/without';

import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';

import { couponLabelFromOrigin } from 'constants/coupons';
import currencies from 'constants/currencies';
import { FRAGRANCE_FREE as fragranceFreeLabel } from 'constants/haircareFragrances';
import * as paymentMethodsStatuses from 'constants/paymentMethodsStatuses';
import {
  nonSubscribableProducts,
  products as defaultProducts,
  productsPrices,
  productsSlugs,
  skincareProducts,
} from 'constants/products';
import * as Status from 'constants/statuses';
import {
  couponPercentage as defaultCouponPercentage,
  getDefaultFrequencyByCategory,
  subscriptionCategories,
  subscriptionSubCategories,
} from 'constants/subscriptions';

import { FRAGRANCE_FREE as HAIRCARE_FRAGRANCE_FREE } from 'assets/content/haircareFragrances';

import { getFragranceDetails } from 'utils/fragrances';
import {
  computeSubscriptionCategory,
  isHaircareTopicals,
  isSkincareTopicals,
  isSupplements,
  isTopicals,
} from 'utils/subscriptionCategories';

import { getFragrancesData, getSkincareFragrancesData } from 'dux/consultation/selectors';
import { getSubscriptionProductsContent } from 'dux/products/selectors';
import {
  getHasAddresses,
  getHasAnsweredSupplementsQuestions,
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
  getHasPaymentMethods,
  getPaymentMethodsForSubscription,
  getPaymentMethodsStatus,
  getRecommendationsStatus,
  getUserAddresses,
  getUserAddressesStatus,
  getUserFragrance,
  getUserRecommendations,
  getUserSkincareFragrance,
} from 'dux/user/selectors';
import * as recommendationsStatuses from 'dux/user/constants/recommendationsStatus';

import * as forecastStatuses from './constants/forecastStatuses';
import * as snoozeStatuses from './constants/snoozeStatuses';

// state.subscriptions
export const getState = state => state.subscriptions;
// state.subscriptions.status
const getStatus = createSelector(getState, get('status'));

/**
 * Subscriptions
 */

// state.subscriptions.subscriptions
const getSubscriptions = createSelector(getState, state => flow(getOr([], 'subscriptions'))(state));

// state.subscriptions.subscriptions.length > 0
const getHasSubscriptions = createSelector(
  getSubscriptions,
  subscriptions => subscriptions?.length > 0
);

/** @type {(state: any, propsWithCategory: { category: string }) => Subscription | undefined} */
export const getSubscriptionByUXConcern = createSelector(
  getSubscriptions,
  (_state, { category } = {}) => category,
  (/** @type {Array<Subscription>} */ subscriptions, category) =>
    subscriptions.find(
      subscription =>
        // If no sub_category (old scenario)
        (!subscription?.sub_category && subscription.category === category) ||
        // Is on haircare topicals path (/haircare)
        (subscription?.sub_category === subscriptionSubCategories.TOPICALS &&
          subscription.category === category) ||
        // Is on haircare supplements path (/supplements)
        (subscription?.category === subscriptionCategories.HAIRCARE &&
          subscription?.sub_category === category)
    )
);

export const getActiveOrOnHoldSubscriptionByCategory = createSelector(
  getSubscriptions,
  (_state, { category } = {}) => category,
  (subscriptions, category) =>
    subscriptions.find(
      subscription =>
        // If no sub_category (old scenario)
        subscription.category === category && ['active', 'onhold'].includes(subscription.status)
    )
);

/**
 * Active subscriptions
 */

export const getActiveSubscriptions = createSelector(getSubscriptions, subscriptions =>
  subscriptions.filter(({ status }) => status === 'active')
);

/**
 * On-hold subscriptions
 */

export const getOnHoldSubscriptions = createSelector(getSubscriptions, subscriptions =>
  subscriptions.filter(({ status }) => status === 'onhold')
);

export const getIsSubscriptionActiveOrOnHold = createSelector(
  getSubscriptionByUXConcern,
  subscription => subscription?.status === 'active' || subscription?.status === 'onhold'
);

export const getIsSubscriptionOnHold = createSelector(
  getSubscriptionByUXConcern,
  subscription => subscription?.status === 'onhold'
);

export const getIsSubscriptionOnHoldBecauseOfFragrance = createSelector(
  getSubscriptionByUXConcern,
  subscription =>
    subscription?.status === 'onhold' && subscription?.status_reason === 'invalid_fragrance'
);

export const getIsSubscriptionOnHoldBecauseOfPaymentError = createSelector(
  getSubscriptionByUXConcern,
  subscription =>
    subscription?.status === 'onhold' &&
    (subscription?.status_reason === 'payment_error' ||
      subscription?.status_reason === 'uncaught_error' ||
      // Handling misspell reason for some users
      subscription?.status_reason === 'uncaugth_error')
);

export const getIsSubscriptionOnHoldBecauseOfOther = createSelector(
  getSubscriptionByUXConcern,
  subscription => subscription?.status === 'onhold' && subscription?.status_reason === 'other'
);

/**
 * Editable subscriptions
 */

export const getEditableSubscriptions = createSelector(getSubscriptions, subscriptions =>
  subscriptions.filter(({ status }) => status === 'active' || status === 'onhold')
);

export const getHasMultipleEditableSubscriptions = createSelector(
  getEditableSubscriptions,
  subscriptions => subscriptions?.length > 1
);

export const getEditableSubscriptionByCategory = createSelector(
  getSubscriptionByUXConcern,
  subscription => (['active', 'onhold'].includes(subscription?.status) ? subscription : null)
);

export const getLastEditableSubscription = createSelector(
  getEditableSubscriptions,
  subscriptions => subscriptions?.[0]
);

/** Subsume the 3 next functions * */
export const getLastEditableSubscriptionByUXCategory = createSelector(
  getEditableSubscriptions,
  (_state, { category }) => category,
  (subscriptions, category) => {
    switch (category) {
      case subscriptionCategories.SKINCARE:
        return subscriptions.find(subscription =>
          isSkincareTopicals({
            category: subscription.category,
            subcategory: subscription?.sub_category,
          })
        );

      case subscriptionCategories.SUPPLEMENTS:
        return subscriptions.find(subscription =>
          isSupplements(subscription.category, subscription?.sub_category)
        );

      case subscriptionCategories.HAIRCARE:
      default:
        return subscriptions.find(subscription =>
          isHaircareTopicals({
            category: subscription.category,
            subcategory: subscription?.sub_category,
          })
        );
    }
  }
);

export const getHaircareEditableSubscriptions = createSelector(
  getEditableSubscriptions,
  subscriptions =>
    subscriptions.find(subscription =>
      isHaircareTopicals({
        category: subscription.category,
        subcategory: subscription?.sub_category,
      })
    )
);

export const getSupplementsEditableSubscriptions = createSelector(
  getEditableSubscriptions,
  subscriptions =>
    subscriptions.find(subscription =>
      isSupplements(subscription.category, subscription?.sub_category)
    )
);

export const getSkincareEditableSubscriptions = createSelector(
  getEditableSubscriptions,
  subscriptions =>
    subscriptions.find(subscription =>
      isSkincareTopicals({
        category: subscription.category,
        subcategory: subscription?.sub_category,
      })
    )
);

export const getHasEditableHaircareOrSupplementsSubscriptions = createSelector(
  getHaircareEditableSubscriptions,
  getSupplementsEditableSubscriptions,
  (haircareSubscriptions, supplementsSubscriptions) =>
    !isEmpty(haircareSubscriptions) || !isEmpty(supplementsSubscriptions)
);

export const getHasEditableHaircareOrSupplementsAndSkincareSubscriptions = createSelector(
  getHasEditableHaircareOrSupplementsSubscriptions,
  getSkincareEditableSubscriptions,
  (hasHaircareOrSupplementsSubscriptions, skincareSubscriptions) =>
    hasHaircareOrSupplementsSubscriptions && !isEmpty(skincareSubscriptions)
);

export const getHasEditableHaircareOrSupplementsButNoSkincareSubscriptions = createSelector(
  getHasEditableHaircareOrSupplementsSubscriptions,
  getSkincareEditableSubscriptions,
  (hasHaircareOrSupplementsSubscriptions, skincareSubscriptions) =>
    hasHaircareOrSupplementsSubscriptions && isEmpty(skincareSubscriptions)
);

export const getMustHaveJarsLastSupplementsEditableSubscriptions = createSelector(
  getSupplementsEditableSubscriptions,
  getOr(true, 'must_have_jars')
);

/**
 * Canceled/Inactive subscriptions
 */

export const getIsSubscriptionCanceled = createSelector(
  getSubscriptionByUXConcern,
  subscription => subscription?.status === 'canceled'
);

export const getLastHaircareCanceledSubscription = createSelector(getSubscriptions, subscriptions =>
  subscriptions.find(
    subscription =>
      subscription.status === 'canceled' &&
      isHaircareTopicals({
        category: subscription?.category,
        subcategory: subscription?.sub_category,
      })
  )
);

const getLastSupplementsCanceledSubscription = createSelector(getSubscriptions, subscriptions =>
  subscriptions.find(
    subscription =>
      subscription.status === 'canceled' &&
      isSupplements(subscription?.category, subscription?.sub_category)
  )
);

export const getLastSkincareCanceledSubscription = createSelector(getSubscriptions, subscriptions =>
  subscriptions.find(
    subscription =>
      subscription.status === 'canceled' &&
      isSkincareTopicals({
        category: subscription?.category,
        subcategory: subscription?.sub_category,
      })
  )
);

export const getLastCanceledSubscriptionByUXCategory = createSelector(
  getSubscriptions,
  (_state, { category }) => category,
  (subscriptions, category) => {
    switch (category) {
      case subscriptionCategories.SKINCARE:
        return subscriptions.find(
          subscription =>
            subscription.status === 'canceled' &&
            isSkincareTopicals({
              category: subscription?.category,
              subcategory: subscription?.sub_category,
            })
        );
      case subscriptionSubCategories.SUPPLEMENTS:
        return subscriptions.find(
          subscription =>
            subscription.status === 'canceled' &&
            isSupplements(subscription?.category, subscription?.sub_category)
        );
      case subscriptionCategories.HAIRCARE:
      default:
        return subscriptions.find(
          subscription =>
            subscription.status === 'canceled' &&
            isHaircareTopicals({
              category: subscription?.category,
              subcategory: subscription?.sub_category,
            })
        );
    }
  }
);

/**
 * When resubscribed, the canceled subscription is untouched, a new subscription is created
 * So we need to filter the canceled subscription which have been reactivated (or put on-hold)
 * We can only display 1 haircare and 1 supplements
 */
export const getInactiveSubscriptions = createSelector(
  getLastHaircareCanceledSubscription,
  getLastSkincareCanceledSubscription,
  getLastSupplementsCanceledSubscription,
  getHaircareEditableSubscriptions,
  getSkincareEditableSubscriptions,
  getSupplementsEditableSubscriptions,
  getEditableSubscriptions,
  (
    lastHaircareCanceledSubscription,
    lastSkincareCanceledSubscription,
    lastSupplementsCanceledSubscription,
    haircareEditableSubscription,
    skincareEditableSubscription,
    supplementsEditableSubscription
  ) => {
    const inactiveSubscriptions = [];

    if (lastHaircareCanceledSubscription && !haircareEditableSubscription) {
      inactiveSubscriptions.push(lastHaircareCanceledSubscription);
    }

    if (lastSkincareCanceledSubscription && !skincareEditableSubscription) {
      inactiveSubscriptions.push(lastSkincareCanceledSubscription);
    }

    if (lastSupplementsCanceledSubscription && !supplementsEditableSubscription) {
      inactiveSubscriptions.push(lastSupplementsCanceledSubscription);
    }

    return inactiveSubscriptions;
  }
);

/**
 * Upsell subscriptions
 */

export const getUpsellSubscriptions = createSelector(
  getEditableSubscriptions,
  getInactiveSubscriptions,
  getHasCompletedHaircareConsultation,
  getHasAnsweredSupplementsQuestions,
  getHasCompletedSkincareConsultation,
  (
    editableSubscriptions,
    canceledSubscriptions,
    completedHaircareConsultation,
    hasAnsweredSupplementsQuestions,
    completedSkincareConsultation
  ) => {
    const haircareSubscription =
      editableSubscriptions.find(subscription =>
        isHaircareTopicals({
          category: subscription.category,
          subcategory: subscription?.sub_category,
        })
      ) ||
      canceledSubscriptions.find(subscription =>
        isHaircareTopicals({
          category: subscription.category,
          subcategory: subscription?.sub_category,
        })
      );
    const supplementsSubscription =
      editableSubscriptions.find(subscription =>
        isSupplements(subscription.category, subscription?.sub_category)
      ) ||
      canceledSubscriptions.find(subscription =>
        isSupplements(subscription.category, subscription?.sub_category)
      );
    const skincareSubscription =
      editableSubscriptions.find(subscription =>
        isSkincareTopicals({
          category: subscription.category,
          subcategory: subscription?.sub_category,
        })
      ) ||
      canceledSubscriptions.find(subscription =>
        isSkincareTopicals({
          category: subscription.category,
          subcategory: subscription?.sub_category,
        })
      );

    return [
      // If no subscription exists yet we propose it in upsell
      !haircareSubscription
        ? {
            category: subscriptionCategories.HAIRCARE,
            sub_category: subscriptionSubCategories.TOPICALS,
            hasAnsweredConsultationQuestions: completedHaircareConsultation,
          }
        : null,
      !supplementsSubscription
        ? {
            category: subscriptionCategories.HAIRCARE,
            sub_category: subscriptionSubCategories.SUPPLEMENTS,
            hasAnsweredConsultationQuestions: hasAnsweredSupplementsQuestions,
          }
        : null,
      !skincareSubscription
        ? {
            category: subscriptionCategories.SKINCARE,
            sub_category: subscriptionSubCategories.TOPICALS,
            hasAnsweredConsultationQuestions: completedSkincareConsultation,
          }
        : null,
    ].filter(el => el !== null);
  }
);

/**
 * Editable & canceled/inactive
 */

export const getLastEditableOrInactiveSubscription = createSelector(
  getSubscriptions,
  subscriptions =>
    subscriptions.find(({ status }) => ['active', 'onhold', 'canceled'].includes(status))
);

/**
 * Logs
 */

export const getSubscriptionLogReasons = createSelector(
  getState,
  getOr([], 'subscriptionLogReasons')
);

const getSubscriptionLogReasonsStatus = createSelector(
  getState,
  get('subscriptionLogReasonStatus')
);

const getSubscriptionLogReasonsError = createSelector(
  getSubscriptionLogReasonsStatus,
  status => status === Status.ERROR
);

const getIsSubscriptionLoadingLogReasons = createSelector(getSubscriptionLogReasonsStatus, status =>
  [Status.IDLE, Status.LOADING].includes(status)
);

/**
 * Loading
 */

export const getAreSubscriptionsLoading = createSelector(
  getStatus,
  getHasSubscriptions,
  (status, hasSubs) => [Status.IDLE, Status.LOADING].includes(status) && !hasSubs
);

const getSubscriptionCancelStatus = createSelector(getState, get('cancelSubscriptionStatus'));

export const getIsSubscriptionCancelLoading = createSelector(
  getSubscriptionCancelStatus,
  status => status === Status.LOADING
);

export const getIsSubscriptionCancelError = createSelector(
  getSubscriptionCancelStatus,
  status => status === Status.ERROR
);

export const getAreSubscriptionsLoaded = createSelector(
  getStatus,
  status => status === Status.SUCCESS
);

export const getAreSubscriptionsDataLoading = createSelector(
  getAreSubscriptionsLoading,
  getUserAddressesStatus,
  getPaymentMethodsStatus,
  getRecommendationsStatus,
  (isLoading, addressesStatus, paymentMethodsStatus, recommendationsStatus) =>
    isLoading ||
    [Status.IDLE, Status.LOADING].includes(addressesStatus) ||
    [paymentMethodsStatuses.IDLE, paymentMethodsStatuses.LOADING].includes(paymentMethodsStatus) ||
    [recommendationsStatus.IDLE, recommendationsStatuses.LOADING].includes(recommendationsStatus)
);

export const getAreSubscriptionsSummaryDataLoading = createSelector(
  getAreSubscriptionsLoading,
  getUserAddressesStatus,
  getPaymentMethodsStatus,
  getHasAddresses,
  getHasPaymentMethods,
  (isLoading, addressesStatus, paymentMethodsStatus, hasAddresses, hasPaymentMethods) =>
    isLoading ||
    ([Status.IDLE, Status.LOADING].includes(addressesStatus) && !hasAddresses) ||
    ([paymentMethodsStatuses.IDLE, paymentMethodsStatuses.LOADING].includes(paymentMethodsStatus) &&
      !hasPaymentMethods)
);

export const getAreSubscriptionsLoadingCancelData = createSelector(
  getAreSubscriptionsLoading,
  getIsSubscriptionLoadingLogReasons,
  (isLoadingSubscriptions, isLoadingLogReasons) => isLoadingSubscriptions || isLoadingLogReasons
);

/**
 * Error
 */

export const getSubscriptionsError = createSelector(getStatus, status => status === Status.ERROR);

export const getSubscriptionLoadingCancelDataError = createSelector(
  getSubscriptionsError,
  getSubscriptionLogReasonsError,
  (hasSubscriptionsError, hasLogReasonsError) => hasSubscriptionsError || hasLogReasonsError
);

export const getSubscriptionSnoozeError = createSelector(getState, get('snoozeError'));

/**
 * Address
 */

export const getLastEditableSubscriptionAddress = createSelector(
  getLastEditableSubscription,
  getUserAddresses,
  (subscription, addresses) =>
    addresses.find(({ pubkey }) => pubkey === subscription?.shipping_address)
);

/**
 * Payment method
 */

export const getLastEditableSubscriptionPaymentMethod = createSelector(
  getLastEditableSubscription,
  getPaymentMethodsForSubscription,
  (subscription, paymentMethods) =>
    paymentMethods.find(({ id }) => id === subscription?.payment_source)
);

export const getLastEditableOrInactiveSubscriptionPaymentMethod = createSelector(
  getLastEditableOrInactiveSubscription,
  getPaymentMethodsForSubscription,
  (subscription, paymentMethods) =>
    paymentMethods.find(({ id }) => id === subscription?.payment_source)
);

/**
 * Next start
 */

export const getSubscriptionNextStart = createSelector(
  getSubscriptionByUXConcern,
  getOr('', 'next_start')
);

export const getIsEditableSubscriptionTheNearestCharged = createSelector(
  getEditableSubscriptions,
  getSubscriptionByUXConcern,
  (editableSubscriptions, subscription) => {
    if (editableSubscriptions?.length === 1 && subscription?.pubkey) {
      return subscription.pubkey === editableSubscriptions[0].pubkey;
    }

    if (editableSubscriptions?.length > 1 && subscription?.category) {
      const otherSubscription = editableSubscriptions.find(
        editableSubscription =>
          editableSubscription?.sub_category !== subscription?.sub_category ||
          editableSubscription.category !== subscription?.category
      );

      if (
        dayjs(subscription.next_start).isSame(dayjs(otherSubscription.next_start)) &&
        isTopicals(subscription.category, subscription?.sub_category)
      ) {
        return true;
      }

      return dayjs(subscription.next_start).isBefore(dayjs(otherSubscription.next_start));
    }

    return false;
  }
);

/**
 * Shipping estimate
 */

export const getSubscriptionShippingEstimate = createSelector(
  getSubscriptionByUXConcern,
  subscription => get('shipping_estimate')(subscription)
);

/**
 * Created at
 *
 * Used by:
 * - SubscriberHero
 * - membership/selectors.js
 */
export const getMemberSince = createSelector(
  getLastEditableSubscription,
  flow(
    get('created_at'),
    createdAt => (createdAt && dayjs(new Date(createdAt)).format('YYYY')) || null
  )
);

/**
 * Discount
 *
 * Used by:
 * - FAQBlock
 * - SubscriberPercs
 */
export const getCouponPercentage = createSelector(
  getLastEditableSubscription,
  getOr(defaultCouponPercentage, 'discount_percentage')
);

/**
 * @typedef {Array<{
 *  items: Array<{product_type: string}>,
 *  next_charge: string,
 *  shipping_estimate: {min: string, max: string},
 * }>} SubscriptionForecast
 */
export const getSubscriptionForecast = createSelector(
  getState,
  (_state, { category } = {}) => category,
  (state, category) =>
    /** @type {SubscriptionForecast} */ (getOr([], `subscriptionForecast.${category}`)(state))
);

const getSubscriptionForecastStatus = createSelector(getState, get('subscriptionForecastStatus'));

export const getForecastTickets = createSelector(
  getSubscriptionForecast,
  getSubscriptionProductsContent,
  (forecast, productsContent) => {
    return forecast.map(({ next_charge, discount_rate, items, shipping_estimate }) => {
      return {
        products: pipe(
          map(({ quantity, price, skeleton_type: type }) => ({
            type,
            quantity,
            price,
            label: productsContent?.[type]?.label,
          })),
          sortBy(['price', 'type'])
        )(items),
        chargeDate: next_charge,
        couponPercentage: discount_rate,
        shippingEstimate: shipping_estimate,
      };
    });
  }
);

export const shouldDisplaySubscriptionForecast = createSelector(
  getSubscriptionForecastStatus,
  status => status === forecastStatuses.AVAILABLE
);

/**
 * Status
 */

export const getIsSubscriptionSnoozing = createSelector(
  getStatus,
  status => status === snoozeStatuses.SNOOZING
);

/**
 * Consultation
 */

export const subscriptionCategoryWithMissingConsultation = createSelector(
  getState,
  get('subscriptionCategoryWithMissingConsultation')
);

/**
 * Toasts
 */

export const getSubscriptionConfirmationToast = createSelector(
  (_state, { location } = {}) => location,
  location => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const category = params?.get('category');
    const status = params?.get('status');

    if (status === 'canceled' && category) {
      return `Your ${category} subscription has been cancelled`;
    }
    if (status === 'created' && category) {
      return `Your ${category} subscription is active!`;
    }
    if (status === 'snoozed' && category) {
      return `Your ${category} subscription has been snoozed`;
    }
    if (status === 'updated') {
      return 'Your changes have been saved';
    }
    if (status === 'resubscribed') {
      return 'Welcome back! Your subscription is now active. Edit to make any changes.';
    }
    if (status === 'offer-applied' && category) {
      return 'Your offer has been applied.';
    }
    if (status === 'error') {
      return 'Your changes could not be saved';
    }
    return null;
  }
);

/**
 * Products
 */

const getSubscribedProducts = createSelector(
  getSubscriptionByUXConcern,
  getOr([], 'regular_products')
);

export const getSubscribedProductsNames = createSelector(
  getSubscribedProducts,
  subscribableProducts => {
    return subscribableProducts
      .map(({ quantity, type }) => {
        if (quantity > 0) {
          return type;
        }
        return null;
      })
      .filter(el => el !== null);
  }
);

export const getSubscribedProductsNamesBySubscription = createSelector(
  (_state, { subscription } = {}) => subscription,
  subscription => {
    // const products = subscription?.regular_products;
    const {
      regular_products: products = null,
      category = null,
      must_have_jars: mustHaveJars,
      sub_category: subcategory = null,
    } = subscription;

    if (!products || isEmpty(products)) {
      return null;
    }

    if (isTopicals(category, subcategory)) {
      return products
        .map(({ quantity, type }) => {
          if (quantity > 0) {
            return type;
          }
          return null;
        })
        .filter(el => el !== null);
    }

    if (isSupplements(category, subcategory)) {
      return products
        .map(({ quantity, type }) => {
          if (quantity > 0) {
            if (mustHaveJars) {
              return type;
            }
            /* refills are a variant of supplement_core & supplement_booster
            but we don't have yet a proper way to handle variants on prose-www */
            return productsSlugs.SUPPLEMENTS_POUCH;
          }
          return null;
        })
        .filter(el => el !== null);
    }
    return null;
  }
);

export const getSubscribableProducts = createSelector(
  getHasAnsweredSupplementsQuestions,
  hasAnsweredSupplementsQuestion => {
    const subscribableProducts = defaultProducts.filter(
      productSlug => !nonSubscribableProducts.includes(productSlug)
    );
    return hasAnsweredSupplementsQuestion
      ? subscribableProducts
      : without([productsSlugs.SUPPLEMENT_CORE])(subscribableProducts);
  }
);

export const getSubscribableProductsByCategory = createSelector(
  (_state, { category } = {}) => category,
  category =>
    isHaircareTopicals({ category })
      ? without([productsSlugs.SUPPLEMENT_CORE, ...skincareProducts, ...nonSubscribableProducts])(
          defaultProducts
        )
      : isSkincareTopicals({ category })
      ? without(nonSubscribableProducts)(skincareProducts)
      : [productsSlugs.SUPPLEMENT_CORE]
);

export const getSupplementsSubscribableProducts = createSelector(() =>
  without([productsSlugs.SUPPLEMENT_CORE, ...nonSubscribableProducts])(defaultProducts)
);

export const getSubscriptionProductsQuantities = createSelector(
  getSubscribableProductsByCategory,
  getSubscribedProducts,
  getUserRecommendations,
  (_state, props = {}) => props,
  (subscribableProducts, subscribedProducts, recommendations, props) => {
    const params = new URLSearchParams(props?.location?.search);
    const productSlugFromCart = params?.get('add');

    // Currently subscribed products
    if (!isEmpty(subscribedProducts)) {
      const quantities = reduce(
        (acc, product) => ({ ...acc, [product?.type]: product.quantity }),
        {}
      )(subscribedProducts);

      // Preselect product coming from the cart (URL param add=)
      if (productSlugFromCart && quantities[productSlugFromCart] === 0) {
        return { ...quantities, [productSlugFromCart]: 1 };
      }
      return quantities;
    }

    if (isSupplements(props?.category)) {
      return {
        [productsSlugs.SUPPLEMENT_CORE]: 1,
      };
    }

    // Recommended products (no subscription yet)
    const recommendedQuantites = pipe(
      filter(({ type: product }) => subscribableProducts.includes(product)),
      reduce(
        (acc, { type: product, recommendation: { quantity } }) => ({ ...acc, [product]: quantity }),
        {}
      )
    )(recommendations);

    // Preselect product coming from the cart (URL param add=)
    if (productSlugFromCart && recommendedQuantites[productSlugFromCart] === 0) {
      return { ...recommendedQuantites, [productSlugFromCart]: 1 };
    }
    return recommendedQuantites;
  }
);

export const getSubscriptionProductsFrequencies = createSelector(
  getSubscribableProductsByCategory,
  getSubscribedProducts,
  (_state, category = {}) => category,
  (subscribableProducts, subscribedProducts, category) => {
    if (!isEmpty(subscribedProducts)) {
      return reduce(
        (acc, product) => ({ ...acc, [product?.type]: product.frequency }),
        {}
      )(subscribedProducts);
    }

    return reduce(
      (acc, product) => ({
        ...acc,
        [product]: getDefaultFrequencyByCategory(category),
      }),
      {}
    )(subscribableProducts);
  }
);

export const getSubscriptionProductsNextStart = createSelector(
  getSubscribableProductsByCategory,
  getSubscribedProducts,
  getIsSubscriptionActiveOrOnHold,
  (subscribableProducts, subscribedProducts, isUpdatingSub) => {
    if (!isEmpty(subscribedProducts)) {
      return reduce(
        // NOTE: In case of a new subscription, we shouldn't use the product next_start from the canceled sub if any
        (acc, product) => ({ ...acc, [product?.type]: isUpdatingSub ? product.next_start : null }),
        {}
      )(subscribedProducts);
    }

    return reduce(
      (acc, product) => ({
        ...acc,
        [product]: null,
      }),
      {}
    )(subscribableProducts);
  }
);

// Routine products are either the ones the customer subscribed to or the ones recommended by our algorithms
export const getSubscriptionRoutineProducts = createSelector(
  getSubscribableProductsByCategory,
  getSubscriptionProductsQuantities,
  (subscribableProducts, quantities) =>
    filter(p => {
      return quantities?.[p];
    })(subscribableProducts)
);

// Cross sales products are the ones neither subscribed nor recommended among the subscribableProducts
export const getSubscriptionCrossSaleProducts = createSelector(
  getSubscribableProductsByCategory,
  getSubscriptionProductsQuantities,
  (subscribableProducts, quantities) => {
    return filter(p => quantities?.[p] === 0)(subscribableProducts);
  }
);

/**
 * Ticket
 */

// Initial ticket sent by the API inside the susbcription info
const getInitialSubscriptionTicket = createSelector(getSubscriptionByUXConcern, get('ticket'));

// In preview mode, the API returns the updated ticket to display the changes in real-time
export const getUpdatedSubscriptionTicket = createSelector(getState, get('subscriptionTicket'));

/**
 * When we load the page for the first time, the ticket is the initial one
 * Once we update the subscription form, the ticket is the updated one
 */
export const getSubscriptionTicket = createSelector(
  getInitialSubscriptionTicket,
  getUpdatedSubscriptionTicket,
  (_state, { category } = {}) => category,
  (initialTicket, updatedTicket, category) => {
    /**
     * Due to the fact that Supplements is a subcategory of Haircare
     * And that we visually proposes Supplements as a category to the user
     * We need a mapping from sub_category to category
     */
    const subscriptionCategoryUX = computeSubscriptionCategory({
      category: updatedTicket?.category,
      subcategory: updatedTicket?.sub_category,
    });
    return !isEmpty(updatedTicket) && subscriptionCategoryUX === category
      ? updatedTicket
      : initialTicket;
  }
);

export const getGiftWithPurchaseOnTicketPreview = createSelector(
  getUpdatedSubscriptionTicket,
  subscription => subscription?.gwps?.[0]
);

const getCoupons = createSelector(getSubscriptionTicket, get('coupons'));

const getCouponsFiltered = createSelector(
  getCoupons,
  flow(
    filter(coupon => coupon?.total_price <= 0),
    map(coupon => ({
      property: coupon?.property,
      label: isEmpty(coupon?.label) ? couponLabelFromOrigin(coupon?.origin) : coupon.label,
      value: coupon?.total_price,
    }))
  )
);

export const getCreditCoupons = createSelector(getCouponsFiltered, filter({ property: 'credit' }));

export const getOfferCoupons = createSelector(getCouponsFiltered, filter({ property: 'offer' }));

export const getSubscriptionCoupons = createSelector(
  getCouponsFiltered,
  filter({ property: 'subscription' })
);

const getTicketProducts = createSelector(getSubscriptionTicket, get('products'));

export const getSubscriptionTicketProducts = createSelector(
  getSubscriptionProductsContent,
  getTicketProducts,
  getSubscriptionProductsQuantities,
  getSubscriptionRoutineProducts,
  getSubscriptionCrossSaleProducts,
  (productsContent, products, quantities, routineProducts, crossSaleProducts) => {
    // In upsell/creation mode, since the ticket sent by the API is null, we need to compute all infos
    if (!products) {
      return pipe(
        filter(product => quantities?.[product] > 0),
        map(product => ({
          type: product,
          label: productsContent?.[product].label,
          quantity: quantities?.[product],
          price: quantities?.[product] * productsPrices?.[product],
        }))
      )([...routineProducts, ...crossSaleProducts]);
    }

    // In other cases, the ticket sent by the API has all infos we need
    return pipe(
      map(product => ({
        label: productsContent?.[product?.slug].label,
        quantity: product?.quantity,
        price: product?.total_price,
      }))
    )([...products]);
  }
);

/**
 * Fragrances
 */

export const getFragrancesFiltered = createSelector(
  getFragrancesData,
  getSkincareFragrancesData,
  getUserFragrance,
  getUserSkincareFragrance,
  (_state, { category } = {}) => category,
  (
    haircareFragrances,
    skincareFragrances,
    currentHaircareFragrance,
    currentSkincareFragrance,
    category
  ) => {
    const fragrances =
      category === subscriptionCategories.SKINCARE ? skincareFragrances : haircareFragrances;

    if (!fragrances) return null;

    // Get current fragrance (Consultation hair_profile)
    let fragranceSelected;
    if (category === subscriptionCategories.SKINCARE) {
      // fragrance fetched contains a fragrance_free entry
      fragranceSelected =
        currentSkincareFragrance === 'FREE' ? 'FRAGRANCE_FREE' : currentSkincareFragrance;
    } else {
      // fragrance fetched doesn't contain a fragrance_free entry
      fragranceSelected =
        currentHaircareFragrance === 'FREE'
          ? HAIRCARE_FRAGRANCE_FREE.name
          : currentHaircareFragrance;
    }

    const fragrancesForSubscription = fragrances.map(fragrance => {
      const { details, ingredients, url, name, urlsByAssetName, imageAlt } = getFragranceDetails(
        fragrance.name
      );
      return {
        description: fragrance.name !== fragranceFreeLabel ? details ?? ingredients : null,
        img: urlsByAssetName?.darkFragFreeSignImg ?? url,
        imageAlt,
        label: name,
        name: fragrance.name,
        recommended: fragrance?.recommended ?? false,
        selected: fragranceSelected === fragrance.name,
      };
    });

    // Add Fragrance-Free to the list for Haircare
    if (category === subscriptionCategories.HAIRCARE) {
      fragrancesForSubscription.push({
        img: HAIRCARE_FRAGRANCE_FREE.urlsByAssetName.darkFragFreeSignImg,
        imageAlt: HAIRCARE_FRAGRANCE_FREE.imageAlt,
        label: HAIRCARE_FRAGRANCE_FREE.name,
        name: fragranceFreeLabel,
        selected: fragranceSelected === HAIRCARE_FRAGRANCE_FREE.name,
      });
    }

    return fragrancesForSubscription;
  }
);

/**
 * Tracking data
 */

// Compute the tracking data (payload) to be sent to Heap when a subscription is updated
export const getSubscriptionUpdateTrackingData = createSelector(
  (_state, { updatedSubscriptionData } = {}) => updatedSubscriptionData,
  getSubscribedProducts,
  getSubscriptionNextStart,
  (updatedSubscriptionData, subscribedProductsBeforeUpdate, nextStartBeforeUpdate) => {
    const payload = {};
    payload.subscription_category = updatedSubscriptionData?.category;
    payload.subscription_sub_category = updatedSubscriptionData?.sub_category;
    payload[
      `next_charge_date_${updatedSubscriptionData?.category}_${updatedSubscriptionData?.sub_category}`
    ] = updatedSubscriptionData?.next_start
      ? updatedSubscriptionData.next_start
      : nextStartBeforeUpdate;

    if (!subscribedProductsBeforeUpdate) {
      return payload;
    }

    // If no product has been updated, return payload based on previous subscription data
    if (!updatedSubscriptionData.regular_products) {
      subscribedProductsBeforeUpdate.map(({ frequency, quantity, type }) => {
        // Product still present without update
        if (quantity > 0) {
          payload[`subscription_${type}_add`] = 0;
          payload[`subscription_${type}_update`] = 0;
          payload[`subscription_${type}_frequency_weeks`] = frequency;
          payload[`subscription_${type}_quantity`] = quantity;
        }
        // Product not present
        else {
          payload[`subscription_${type}_add`] = 0;
          payload[`subscription_${type}_update`] = 0;
          payload[`subscription_${type}_frequency_weeks`] = 0;
          payload[`subscription_${type}_quantity`] = 0;
        }
        return null;
      });

      return payload;
    }

    const previousSubscribedProducts = {};
    subscribedProductsBeforeUpdate
      .map(({ frequency, quantity, type }) => {
        if (quantity > 0) {
          previousSubscribedProducts[type] = { frequency, quantity };
        }
        return null;
      })
      .filter(el => el !== null);

    // If some products have been updated, return payload based on current subscription data
    updatedSubscriptionData.regular_products.map(({ frequency, quantity, type }) => {
      // Add new produt
      if (quantity > 0 && !previousSubscribedProducts[type]) {
        payload[`subscription_${type}_add`] = 1;
        payload[`subscription_${type}_update`] = 0;
        payload[`subscription_${type}_frequency_weeks`] = frequency;
        payload[`subscription_${type}_quantity`] = quantity;
      }
      // Update product already present
      else if (
        quantity > 0 &&
        Boolean(previousSubscribedProducts[type]) &&
        (previousSubscribedProducts[type].quantity !== quantity ||
          previousSubscribedProducts[type].frequency !== frequency)
      ) {
        payload[`subscription_${type}_add`] = 0;
        payload[`subscription_${type}_update`] = 1;
        payload[`subscription_${type}_frequency_weeks`] = frequency;
        payload[`subscription_${type}_quantity`] = quantity;
      }
      // Remove product
      else if (!quantity && Boolean(previousSubscribedProducts[type])) {
        payload[`subscription_${type}_add`] = 0;
        payload[`subscription_${type}_update`] = 1;
        payload[`subscription_${type}_frequency_weeks`] = 0;
        payload[`subscription_${type}_quantity`] = 0;
      }
      // Product still present without update
      else if (quantity > 0) {
        payload[`subscription_${type}_add`] = 0;
        payload[`subscription_${type}_update`] = 0;
        payload[`subscription_${type}_frequency_weeks`] = frequency;
        payload[`subscription_${type}_quantity`] = quantity;
      }
      // Product not present
      else {
        payload[`subscription_${type}_add`] = 0;
        payload[`subscription_${type}_update`] = 0;
        payload[`subscription_${type}_frequency_weeks`] = 0;
        payload[`subscription_${type}_quantity`] = 0;
      }
      return null;
    });

    return payload;
  }
);

export const getSubscriptionTicketShippingEstimate = createSelector(
  getSubscriptionTicket,
  subscriptionTicket => subscriptionTicket?.shipping_estimate
);

export const getCurrencyFromSubscriptionTicket = createSelector(
  getSubscriptionTicket,
  getStatus,
  (subscriptionTicket, status) => {
    if (subscriptionTicket?.currency) {
      return subscriptionTicket.currency;
    }
    if (status === Status.SUCCESS) {
      Sentry.captureMessage('Currency not found in subscription data');
    }
    return currencies.USD;
  }
);

export const getRetentionInfos = createSelector(getState, getOr(null, 'retention'));

export const getRetentionInfosStatus = createSelector(getState, getOr(null, 'retentionStatus'));

export const getShipNowOrderStatus = createSelector(getState, getOr(null, 'shipNowOrder.status'));

export const getShipNowOrderErrorMessage = createSelector(
  getState,
  getOr(null, 'shipNowOrder.error.detail')
);

export const getIsPaymentError = createSelector(
  getState,
  flow(get('shipNowOrder.error.code'), includes('payment'))
);

export const getHaircareSubscriptionShippingEstimate = createSelector(
  getActiveSubscriptions,
  activeSubscriptions =>
    activeSubscriptions
      .filter(
        subscription =>
          subscription.category === subscriptionCategories.HAIRCARE &&
          subscription.sub_category === subscriptionSubCategories.TOPICALS
      )
      .reduce(
        (_result, item) => ({
          minDate: item.shipping_estimate.min_date,
          maxDate: item.shipping_estimate.max_date,
        }),
        {}
      )
);

export const getSkincareSubscriptionShippingEstimate = createSelector(
  getActiveSubscriptions,
  activeSubscriptions =>
    activeSubscriptions
      .filter(subscription => subscription.category === subscriptionCategories.SKINCARE)
      .reduce(
        (_result, item) => ({
          minDate: item.shipping_estimate.min_date,
          maxDate: item.shipping_estimate.max_date,
        }),
        {}
      )
);

export const getSupplementsSubscriptionShippingEstimate = createSelector(
  getActiveSubscriptions,
  activeSubscriptions =>
    activeSubscriptions
      .filter(
        subscription =>
          subscription.category === subscriptionCategories.HAIRCARE &&
          subscription.sub_category === subscriptionSubCategories.SUPPLEMENTS
      )
      .reduce(
        (_result, item) => ({
          minDate: item.shipping_estimate.min_date,
          maxDate: item.shipping_estimate.max_date,
        }),
        {}
      )
);
