import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';

import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';
import { useConsultationCategory } from 'Apps/Consultation/hooks/useConsultationCategory';
import Error from 'Apps/Consultation/Scenes/Error';

import {
  isFetchError,
  isFirstOrderOnly,
  isInvalidHairProfile,
  isTrialOutOfStock,
} from 'Services/HTTPError';

import { productsCategories } from 'constants/products';

import logSentryError from 'utils/logSentry';

import { clearCheckoutState as clearCheckoutStateAction } from 'dux/checkout/actions';
import { getFirstHaircareConsultationQuestionRoute } from 'dux/consultation/selectors';
import { answersActions } from 'dux/consultation/slice';

const ErrorContainer = ({
  clearCheckoutState,
  error,
  firstHaircareConsultationQuestionRoute,
  recover,
  retry,
  sentryMessage,
  ...props
}) => {
  const dispatch = useDispatch();
  const consultationCategory = useConsultationCategory();
  const navigate = useNavigate();

  if (isInvalidHairProfile(error)) {
    return (
      <Error
        cta="Review consultation"
        onClick={() => {
          dispatch(answersActions.invalidateConsultation());
          navigate(
            consultationCategory === productsCategories.SKINCARE
              ? FIRST_SKINCARE_QUESTION_ROUTE
              : firstHaircareConsultationQuestionRoute
          );
        }}
        title="We seem to be missing some of your answers! Please take a moment to review your consultation."
      />
    );
  }

  if (isTrialOutOfStock(error) || isFirstOrderOnly(error)) {
    return (
      <Error
        cta="Order Your Prose"
        onClick={() => {
          clearCheckoutState();
          navigate('/checkout/haircare');
        }}
        title={error.detail}
      />
    );
  }

  if (isFetchError(error)) {
    return (
      <Error
        onClick={retry}
        title="Sorry, we cannot open the page because the connection was lost."
      />
    );
  }

  logSentryError(sentryMessage, error);

  return <Error {...props} onClick={recover} />;
};

ErrorContainer.propTypes = {
  clearCheckoutState: PropTypes.func.isRequired,
  error: PropTypes.shape({
    detail: PropTypes.string,
  }),
  firstHaircareConsultationQuestionRoute: PropTypes.string,
  // recovers from an unknown error
  recover: PropTypes.func,
  // retry if failed to fetech error
  retry: PropTypes.func,
  sentryMessage: PropTypes.string,
};

ErrorContainer.defaultProps = {
  firstHaircareConsultationQuestionRoute: FIRST_HAIRCARE_QUESTION_ROUTE,
  sentryMessage: '[ErrorContainer] captured',
};

export default connect(
  state => ({
    firstHaircareConsultationQuestionRoute: getFirstHaircareConsultationQuestionRoute(state),
  }),
  {
    clearCheckoutState: clearCheckoutStateAction,
  }
)(ErrorContainer);
