import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { useGoToCart } from 'hooks/useGoToCart';

import BackIcon from 'Components/BackIcon';
import { Image } from 'Components/Image';
import { Typography } from 'Components/Typography';

import { navbarContent } from 'assets/content/navbar';

import useResponsiveVariant from 'utils/useResponsiveVariant';

const Container = styled.div`
  background: ${theme.colors.neutral[100]};
  box-shadow: 0 2px 3px 0 rgba(180, 180, 180, 0.2);
  display: flex;
  height: ${legacyTheme.props.navbarHeight};
  width: 100%;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  padding: 0px ${legacyTheme.spacing.s32};

  ${legacyTheme.breakpoints.down('md')} {
    padding: 0px 20px;
  }
`;

const HeaderLogoButton = styled.button`
  ${{ ...legacyTheme.utils.resetButton }};
  display: flex;
  &:hover {
    text-decoration: underline;
    color: ${theme.colors.primary[400]};
  }
`;

const BackButton = styled(HeaderLogoButton)`
  position: absolute;
  display: flex;
  flex-direction: row;

  left: 20px;

  ${legacyTheme.breakpoints.up('sm')} {
    ${legacyTheme.spacing.s32}
  }
`;

const StyledBackIcon = styled(BackIcon)`
  filter: brightness(0);
`;

const CheckoutNavBar = () => {
  const goToCart = useGoToCart();

  const { isMobile } = useResponsiveVariant();

  return (
    <Container data-testid="checkout-header-nav">
      <BackButton
        aria-label="Navigate to previous screen"
        data-click="back"
        data-from="header-nav"
        data-testid="header-back-button"
        onClick={goToCart}
        type="button"
      >
        {isMobile ? (
          <StyledBackIcon />
        ) : (
          <Typography color="noir" variant="p2">
            ← Back
          </Typography>
        )}
      </BackButton>

      <HeaderLogoButton
        aria-label="Return Home"
        data-click="logo"
        data-from="header-nav"
        data-testid="checkout-header-logo"
        onClick={goToCart}
        type="button"
      >
        <Image alt={navbarContent.logo.alt} height={22} src={navbarContent.logo.image} width={62} />
      </HeaderLogoButton>
    </Container>
  );
};

export default CheckoutNavBar;
