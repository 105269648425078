import { isNullish } from 'remeda';

import { theme } from '@prose-ui';
import { ClassNames, css, legacyTheme } from '@prose-ui/legacy';

import { Typography, type TypographyProps } from 'Components/Typography';

import { ReactComponent as NoIcon } from 'assets/images/no-icon.svg';
import { ReactComponent as YesIcon } from 'assets/images/yes-icon.svg';

const rootStyles = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.primary[400]};
`;

const checkStyles = css`
  position: absolute;
  background-color: ${theme.colors.neutral[100]};
  box-shadow: 2px 2px 3px 0 ${theme.colors.neutral[600]};
  transition: background-color 100ms ease-in;

  &.selected {
    background-color: ${theme.colors.primary[300]};
  }

  &.disabled {
    background-color: ${theme.colors.neutral[400]};
    box-shadow: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.displayMode {
    background-color: transparent;
    box-shadow: none;
  }
`;

const inputStyles = css`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
  z-index: 1;

  &.disabled {
    cursor: not-allowed;
  }
`;

const labelStyles = css`
  margin-left: ${legacyTheme.spacing.s16};

  &:hover {
    cursor: pointer;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.displayMode {
    color: ${theme.colors.neutral[800]};
  }

  &.disabled {
    color: ${theme.colors.neutral[700]};
  }
`;

const smallSizeStyles = css`
  width: ${legacyTheme.props.checkboxSize.small};
  height: ${legacyTheme.props.checkboxSize.small};
`;

const mediumSizeStyles = css`
  width: ${legacyTheme.props.checkboxSize.medium};
  height: ${legacyTheme.props.checkboxSize.medium};
`;

const largeSizeStyles = css`
  width: ${legacyTheme.props.checkboxSize.large};
  height: ${legacyTheme.props.checkboxSize.large};
`;

const sizeStyleMapping = {
  small: smallSizeStyles,
  medium: mediumSizeStyles,
  large: largeSizeStyles,
};

type CheckProps = { className: string; color: string; dataPrefix: string };

const CheckYes = ({ className, color, dataPrefix }: CheckProps) => (
  <YesIcon
    aria-hidden
    className={className}
    data-testid={`${dataPrefix}-enabled-icon`}
    focusable={false}
    role="presentation"
    stroke={color}
    viewBox="-5 -6 28 28"
  />
);

const CheckNo = ({ className, color, dataPrefix }: CheckProps) => (
  <NoIcon
    aria-hidden
    className={className}
    data-testid={`${dataPrefix}-disabled-icon`}
    focusable={false}
    role="presentation"
    stroke={color}
    viewBox="-7 -7 28 28"
  />
);

type CheckMarkProps = {
  selected: boolean;
} & CheckProps;

const CheckMark = ({ className, dataPrefix, color, selected }: CheckMarkProps) =>
  selected ? (
    <CheckYes className={className} color={color} dataPrefix={dataPrefix} />
  ) : (
    <CheckNo className={className} color={color} dataPrefix={dataPrefix} />
  );

type CheckboxProps = {
  classes?: Record<string, string>;
  className?: string;
  disabled?: boolean;
  id: string;
  label: React.ReactNode;
  labelColor?: TypographyProps<'label'>['color'];
  labelVariant?: TypographyProps<'label'>['variant'];
  name: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large';
  value?: boolean;
  dataPrefix?: string;
  displayMode?: boolean;
  dataTestId?: string;
};

export const Checkbox = ({
  classes,
  className,
  disabled = false,
  id,
  label,
  labelColor = 'inherit',
  labelVariant = 'p1',
  name,
  onBlur,
  onChange,
  size = 'medium',
  value = false,
  dataPrefix = 'checkbox',
  displayMode = false,
  dataTestId,
}: CheckboxProps) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <div className={cx(css(rootStyles), classes?.root, className)} data-testid={dataTestId}>
          <CheckMark
            className={cx(
              css(checkStyles),
              css(sizeStyleMapping[size]),
              displayMode && 'displayMode',
              value && 'selected',
              disabled && 'disabled',
            )}
            color={
              displayMode
                ? theme.colors.primary[400]
                : disabled
                ? theme.colors.neutral[700]
                : theme.colors.neutral[100]
            }
            dataPrefix={dataPrefix}
            selected={value}
          />
          <input
            checked={isNullish(value) ? false : value}
            className={cx(css(inputStyles), css(sizeStyleMapping[size]), disabled && 'disabled')}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type="checkbox"
          />
          <Typography
            className={cx(
              css(labelStyles),
              classes?.label,
              displayMode && 'displayMode',
              disabled && 'disabled',
            )}
            color={labelColor}
            htmlFor={id}
            markupName="label"
            variant={labelVariant}
          >
            {label}
          </Typography>
        </div>
      )}
    </ClassNames>
  );
};
