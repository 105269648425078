import { productsCategories, productsSlugs, productsVariants } from 'constants/products';

import squaredConditionerPicture from 'assets/images/homeRevamp/prose-custom-conditioner.jpg';
import squaredConditionerPictureHover from 'assets/images/homeRevamp/prose-custom-conditioner-close-up.jpg';
import squaredCurlCreamPicture from 'assets/images/homeRevamp/prose-custom-curl-cream.jpg';
import squaredHairMaskPicture from 'assets/images/homeRevamp/prose-custom-hair-mask-packaging.jpg';
import squaredScalpMaskPicture from 'assets/images/homeRevamp/prose-custom-pre-shampoo-scalp-mask.jpg';
import squaredScalpMaskPictureHover from 'assets/images/homeRevamp/prose-custom-pre-shampoo-scalp-mask-in-use.jpg';
import squaredSupplementsPicture from 'assets/images/homeRevamp/prose-custom-root-source-hair-supplements.jpg';
import squaredShampooPicture from 'assets/images/homeRevamp/prose-custom-shampoo.jpg';
import squaredStylingGelPicture from 'assets/images/homeRevamp/prose-hair-oil-front-background.jpg';
import squaredSupplementsPictureHover from 'assets/images/homeRevamp/prose-model-taking-custom-root-source-hair-supplements.jpg';
import squaredCurlCreamPictureHover from 'assets/images/homeRevamp/prose-model-using-custom-curl-cream.jpg';
import squaredShampooPictureHover from 'assets/images/homeRevamp/prose-model-using-custom-shampoo.jpg';
import squaredHairMaskPictureHover from 'assets/images/homeRevamp/prose-model-using-pre-shampoo-hair-mask-close-up.jpg';
import conditionerBgPicture from 'assets/images/productPages/prose-custom-conditioner.jpg';
import conditionerBgLabelledPicture from 'assets/images/productPages/prose-custom-conditioner-close-up.jpg';
import curlCreamBgPicture from 'assets/images/productPages/prose-custom-curl-cream.jpg';
import dryShampooBgPicture from 'assets/images/productPages/prose-custom-dry-shampoo.jpg';
import dryShampooBgLabelledPicture from 'assets/images/productPages/prose-custom-dry-shampoo-in-use.jpg';
import oilBgPicture from 'assets/images/productPages/prose-custom-hair-oil.jpg';
import oilBgLabelledPicture from 'assets/images/productPages/prose-custom-hair-oil-dropper.jpg';
import leaveinBgLabelledPicture from 'assets/images/productPages/prose-custom-leave-in-conditioner-in-use.jpg';
import leaveinBgPicture from 'assets/images/productPages/prose-custom-leave-in-conditioner-spray-top.jpg';
import hairMaskBgPicture from 'assets/images/productPages/prose-custom-pre-shampoo-hair-mask.jpg';
import scalpMaskBgLabelledPicture from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask-in-use.jpg';
import scalpMaskBgPicture from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask-packaging.jpg';
import supplementsBgPicture from 'assets/images/productPages/prose-custom-root-source-hair-supplements.jpg';
import shampooBgPicture from 'assets/images/productPages/prose-custom-shampoo.jpg';
import stylingGelBgPicture from 'assets/images/productPages/prose-custom-styling-gel.jpg';
import stylingGelBgLabelledPicture from 'assets/images/productPages/prose-custom-styling-gel-close-up.jpg';
import supplementsBgLabelledPicture from 'assets/images/productPages/prose-model-taking-custom-root-source-hair-supplements.jpg';
import curlCreamBgLabelledPicture from 'assets/images/productPages/prose-model-using-custom-curl-cream.jpg';
import shampooBgLabelledPicture from 'assets/images/productPages/prose-model-using-custom-shampoo.jpg';
import hairMaskBgLabelledPicture from 'assets/images/productPages/prose-model-using-pre-shampoo-hair-mask-close-up.jpg';
import conditionerPicture from 'assets/images/products/prose-custom-conditioner-bottle.png';
import conditionerPictureWithCap from 'assets/images/products/prose-custom-conditioner-bottle-cap.png';
import curlCreamPicture from 'assets/images/products/prose-custom-curl-cream-bottle.png';
import curlCreamPictureWithCap from 'assets/images/products/prose-custom-curl-cream-bottle-cap.png';
import dryShampooPicture from 'assets/images/products/prose-custom-dry-shampoo-bottle.png';
import hairMaskPicture from 'assets/images/products/prose-custom-hair-mask-bottle.png';
import oilPicture from 'assets/images/products/prose-custom-hair-oil-bottle.png';
import leaveinPictureWithCap from 'assets/images/products/prose-custom-leave-in-conditioner-bottle-cap.png';
import leaveinXtraV1Picture from 'assets/images/products/prose-custom-leave-in-conditioner-bottle-pump.png';
import leaveinPicture from 'assets/images/products/prose-custom-leave-in-conditioner-bottle-spray.png';
import supplementsPicture from 'assets/images/products/prose-custom-root-source-hair-supplements-jars.png';
import scalpMaskPicture from 'assets/images/products/prose-custom-scalp-mask-bottle.png';
import shampooPicture from 'assets/images/products/prose-custom-shampoo-bottle.png';
import shampooPictureWithCap from 'assets/images/products/prose-custom-shampoo-bottle-cap.png';
import stylingGelPicture from 'assets/images/products/prose-custom-styling-gel-bottle.png';
import supplementsPouchPicture from 'assets/images/products/prose-supplements_pouches.png';

export const hair_mask = {
  capacity: '8.5 fl oz',
  cat: 'core',
  description:
    'A deeply hydrating hair mask that restores moisture and smooths hair from lengths to ends, tailored to your unique hair goals. Concentrated and conditioning, the Prose Custom Hair Mask provides up to 3x stronger hair and less breakage after just one use.*',
  label: 'Hair Mask',
  picture: hairMaskPicture,
  bgPicture: hairMaskBgPicture,
  bgLabelledPicture: hairMaskBgLabelledPicture,
  bgPictureAlt: 'Prose custom hair mask packaging',
  productSquaredPicture: squaredHairMaskPicture,
  productSquaredPictureHover: squaredHairMaskPictureHover,
  price: 45,
  productPageUrl: '/products/custom-hair-mask',
  slug: productsSlugs.HAIR_MASK,
  tag: 'Hydrate + Repair',
  type: productsCategories.FORMULA,
  usage: 'Approximately 8-10 weeks use.',
  usageB: '2x a week',
  category: productsCategories.HAIRCARE,
  keyBenefits: <>Color-safe | Keratin-safe</>,
  disclaimer:
    '*Instrumental test of Prose Shampoo & Conditioner & Hair Mask vs. non-conditioning shampoo',
};

export const shampoo = {
  capacity: '8.5 fl oz',
  cat: 'core',
  description:
    'A gentle, clarifying shampoo that does it all, from rebalancing roots to gently hydrating lengths. Proven to increase hair strength and reduce breakage,* Prose Custom Shampoo is uniquely formulated for your specific hair and scalp type to cleanse and nourish with every wash.',
  label: 'Shampoo',
  picture: shampooPicture,
  pictureWithCap: shampooPictureWithCap,
  bgPicture: shampooBgPicture,
  bgLabelledPicture: shampooBgLabelledPicture,
  bgPictureAlt: 'Prose custom shampoo packaging',
  productSquaredPicture: squaredShampooPicture,
  productSquaredPictureHover: squaredShampooPictureHover,
  productPageUrl: '/products/custom-shampoo',
  price: 32,
  slug: productsSlugs.SHAMPOO,
  tag: 'Cleanse + Wash',
  type: productsCategories.FORMULA,
  usage: 'Approximately 8-10 weeks use.',
  usageB: 'Daily',
  category: productsCategories.HAIRCARE,
  keyBenefits: <>Sulfate-free | Color-safe | Keratin-safe | Non-stripping</>,
  disclaimer:
    '*Instrumental test when used with Prose Custom Conditioner vs. non-conditioning shampoo',
};

export const conditioner = {
  capacity: '8.5 fl oz',
  cat: 'core',
  description:
    'A nourishing conditioner personalized to your unique hair goals, Prose Custom Conditioner softens, smooths, and hydrates all hair types and textures, including fine, curly, and colored. It provides up to 3x stronger hair with less breakage after just one use,* restoring your strands without ever weighing them down.',
  label: 'Conditioner',
  picture: conditionerPicture,
  pictureWithCap: conditionerPictureWithCap,
  bgPicture: conditionerBgPicture,
  bgLabelledPicture: conditionerBgLabelledPicture,
  bgPictureAlt: 'Prose custom conditioner packaging',
  productSquaredPicture: squaredConditionerPicture,
  productSquaredPictureHover: squaredConditionerPictureHover,
  productPageUrl: '/products/custom-conditioner',
  price: 32,
  slug: productsSlugs.CONDITIONER,
  tag: 'Hydrate + Soften',
  type: productsCategories.FORMULA,
  usage: 'Approximately 8-10 weeks use.',
  usageB: 'Daily',
  category: productsCategories.HAIRCARE,
  keyBenefits: <>Color-safe | Keratin-safe | Anti-brass (purple) option</>,
  disclaimer: '*Instrumental test of Prose Shampoo and Conditioner vs. non-conditioning shampoo',
};

export const leavein = {
  capacity: '1.7 fl oz',
  cat: 'leavein',
  description:
    'A nurturing oil and a true multi-tasker, Prose Custom Hair Oil can be used as a treatment before washing, a styling primer, or as a finishing touch. No matter your preference, this premium oil helps tame flyaways, smooth unwanted frizz, and seal in moisture for boosted hydration.',
  label: 'Hair Oil',
  picture: oilPicture,
  bgPicture: oilBgPicture,
  bgLabelledPicture: oilBgLabelledPicture,
  bgPictureAlt: 'Prose custom hair oil packaging',
  productSquaredPicture: squaredConditionerPicture,
  productSquaredPictureHover: squaredConditionerPictureHover,
  price: 48,
  productPageUrl: '/products/custom-hair-oil',
  slug: productsSlugs.OIL,
  tag: 'Treat + Style',
  cardTag: 'Award winning',
  type: productsCategories.FORMULA,
  usageB: 'As needed',
  category: productsCategories.HAIRCARE,
  keyBenefits: (
    <>
      Silicone-free <br /> Made with natural oils <br /> Prevents damage and color-fading <br />
      Developed for both wet and dry hair
    </>
  ),
};

export const dry_shampoo = {
  capacity: '1.3 oz',
  description:
    'A translucent, powder dry shampoo that refreshes roots, boosts volume, and extends hairstyles, all while maintaining scalp balance. Personalized to your unique goals, Prose Custom Dry Shampoo is a gentle formula that’s free from butane, isobutane, and propane — harmful propellant gasses commonly found in off-the-shelf alternatives.',
  label: 'Dry Shampoo',
  picture: dryShampooPicture,
  bgPicture: dryShampooBgPicture,
  bgLabelledPicture: dryShampooBgLabelledPicture,
  bgPictureAlt: 'Prose custom dry shampoo packaging',
  productSquaredPicture: squaredConditionerPicture,
  productSquaredPictureHover: squaredConditionerPictureHover,
  price: 28,
  productPageUrl: '/products/custom-dry-shampoo',
  slug: productsSlugs.DRY_SHAMPOO,
  tag: 'Cleanse + Refresh',
  type: productsCategories.FORMULA,
  category: productsCategories.HAIRCARE,
  keyBenefits: <>Non-aerosol | Alcohol-free | Fragrance-free</>,
};

export const curl_cream = {
  capacity: '5.1 fl oz',
  description:
    'A nourishing curl cream to define your natural texture — whether your hair is wavy, curly, or coily. Used as a styler or for touch-ups, Prose Custom Curl Cream is formulated to keep strands hydrated and minimize unwanted frizz, too.',
  label: 'Curl Cream',
  picture: curlCreamPicture,
  pictureWithCap: curlCreamPictureWithCap,
  bgPicture: curlCreamBgPicture,
  bgLabelledPicture: curlCreamBgLabelledPicture,
  bgPictureAlt: 'Prose custom curl cream packaging',
  productSquaredPicture: squaredCurlCreamPicture,
  productSquaredPictureHover: squaredCurlCreamPictureHover,
  price: 28,
  productPageUrl: '/products/custom-curl-cream',
  slug: productsSlugs.CURL_CREAM,
  tag: 'define + Defrizz',
  type: productsCategories.FORMULA,
  category: productsCategories.HAIRCARE,
  keyBenefits: (
    <>
      Developed for both wet and dry hair <br /> Leaves waves and curls soft <br /> Personalized to
      your curl type
    </>
  ),
};

export const leavein_conditioner = {
  capacity: '5.1 fl oz',
  description:
    'A hydrating leave-in conditioner that detangles, tames unwanted frizz, and leaves strands soft, polished, and manageable. Personalized to your unique goals, Prose Custom Leave-In Conditioner primes your hair for styling and protects hair from heat damage up to 450°F.*',
  label: 'Leave-In Conditioner',
  picture: leaveinPicture,
  pictureWithCap: leaveinPictureWithCap,
  bgPicture: leaveinBgPicture,
  bgLabelledPicture: leaveinBgLabelledPicture,
  bgPictureAlt: 'Prose custom leave-in conditioner packaging with spray top',
  productSquaredPicture: squaredConditionerPicture,
  productSquaredPictureHover: squaredConditionerPictureHover,
  price: 28,
  productPageUrl: '/products/custom-leave-in-conditioner',
  slug: productsSlugs.LEAVEIN_CONDITIONER,
  tag: 'Hydrate + Detangle',
  type: productsCategories.FORMULA,
  variants: {
    [productsVariants.LEAVEIN_CONDITIONER_XTRA_V1]: {
      picture: leaveinXtraV1Picture,
    },
  },
  category: productsCategories.HAIRCARE,
  keyBenefits: (
    <>
      Alcohol-free | Smooths flyaways
      <br /> Pump or spray applicator, based on your needs
    </>
  ),
  disclaimer: '*Instrumental test of Prose Leave in Conditioner vs. non-treated hair',
};

export const scalp_mask = {
  capacity: '8.5 fl oz',
  description:
    'An intensive scalp mask personalized with targeted ingredients and micro-exfoliating jojoba beads to help restore balance to all scalp types, even sensitive. Whether you need soothing repair or a gentle detox, Prose Custom Scalp Mask is certified microbiome-friendly to support optimal scalp health.',
  label: 'Scalp Mask',
  picture: scalpMaskPicture,
  bgPicture: scalpMaskBgPicture,
  bgLabelledPicture: scalpMaskBgLabelledPicture,
  bgPictureAlt: 'Prose custom scalp mask packaging',
  productSquaredPicture: squaredScalpMaskPicture,
  productSquaredPictureHover: squaredScalpMaskPictureHover,
  price: 45,
  productPageUrl: '/products/custom-scalp-mask',
  slug: productsSlugs.SCALP_MASK,
  tag: 'Soothe + Balance',
  type: productsCategories.FORMULA,
  category: productsCategories.HAIRCARE,
  keyBenefits: <>Fragrance-free | Color-safe | Keratin-safe</>,
};

export const styling_gel = {
  capacity: '8.5 fl oz',
  cat: 'styling_gel',
  description:
    'A versatile styling gel for your curls, coils, and slicked-back styles, this flake-free hair gel leaves strands soft, without crunch or residue. No matter your style, Prose Custom Styling Gel provides a personalized amount of hold and definition while controlling frizz and shrinkage — even in humid weather.',
  label: 'Styling Gel',
  picture: stylingGelPicture,
  bgPicture: stylingGelBgPicture,
  bgLabelledPicture: stylingGelBgLabelledPicture,
  bgPictureAlt: 'Prose custom styling gel packaging',
  productSquaredPicture: squaredStylingGelPicture,
  productSquaredPictureHover: stylingGelBgLabelledPicture,
  price: 30,
  productPageUrl: '/products/custom-styling-gel',
  slug: productsSlugs.STYLING_GEL,
  tag: 'Hold + Define',
  type: productsCategories.FORMULA,
  usageB: 'As needed',
  dataFrom: 'block-products-push',
  dataClick: 'see-product-styling-gel',
  category: productsCategories.HAIRCARE,
  keyBenefits: (
    <>
      Lightweight formula <br /> Customizable hold level
      <br /> Protects against environmental damage
    </>
  ),
};

export const supplement_core = {
  // capacity: '2 bottles, 30 capsules each - 30-day supply',
  capacity: 'One month supply. Two daily capsules.',
  cat: 'core',
  description: (
    <>
      Your Custom Root Source<sup>®</sup> Hair Supplements provide natural and powerful ingredients
      known to soothe the scalp and to stimulate hair growth,* thickness, and density. Formulated
      with our proprietary blend PureCatalyst<sup>™</sup>, to stimulate the hair bulb to grow
      faster and hold tighter, meaning less shedding.
    </>
  ),
  label: 'Root Source® Hair Supplements',
  shortLabel: 'Root Source®',
  useShortLabel: true,
  displayLabelPdp: 'Custom Hair Supplements',
  displayLabel: (
    <>
      Root Source<sup css={{ fontFamily: 'Simplon Norm', fontSize: 18 }}>®</sup>
    </>
  ),
  labelDetail: (
    <>
      Root Source® Hair Supplements
      <br />2 bottles, one month supply each
      <br /> Two capsules per day
    </>
  ),
  pluralLabel: 'Root Source® Hair Supplements',
  picture: supplementsPicture,
  pictureWithCap: supplementsPicture,
  bgPicture: supplementsBgPicture,
  bgLabelledPicture: supplementsBgLabelledPicture,
  bgPictureAlt: 'Prose custom root source hair supplements packaging',
  productSquaredPicture: squaredSupplementsPicture,
  productSquaredPictureHover: squaredSupplementsPictureHover,
  price: 62,
  productPageUrl: '/products/custom-hair-supplements',
  slug: productsSlugs.SUPPLEMENT_CORE,
  tag: 'Custom Root Source®',
  type: productsCategories.FORMULA,
  category: productsCategories.HAIRCARE,
  keyBenefits: (
    <>
      Formulated without synthetic fillers or additives <br /> No artificial flavors or colors
    </>
  ),
};

export const supplements_pouch = {
  capacity: 'One month supply. Two daily capsules.',
  picture: supplementsPouchPicture,
  label: 'Root Source® Hair Supplements',
  pluralLabel: 'Root Source® Hair Supplements',
  labelDetail: (
    <>
      Root Source® Hair Supplements
      <br />2 refill pouches, 30 capsules each, 30 day supply
      <br /> Two capsules per day
    </>
  ),
};

export const productSlugToProductHaircare = {
  [productsSlugs.HAIR_MASK]: hair_mask,
  [productsSlugs.SCALP_MASK]: scalp_mask,
  [productsSlugs.SHAMPOO]: shampoo,
  [productsSlugs.CONDITIONER]: conditioner,
  [productsSlugs.LEAVEIN_CONDITIONER]: leavein_conditioner,
  [productsSlugs.DRY_SHAMPOO]: dry_shampoo,
  [productsSlugs.CURL_CREAM]: curl_cream,
  [productsSlugs.SUPPLEMENT_CORE]: supplement_core,
  [productsSlugs.STYLING_GEL]: styling_gel,
  [productsSlugs.OIL]: leavein,
};
