import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { withStyles } from 'legacyStyles';

import ScaleLoader from '../Components/ScaleLoader';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: `calc(100vh - ${legacyTheme.props.headerHeight})`,
    [legacyTheme.breakpoints.match('sm')]: {
      minHeight: legacyTheme.props.fullHeight,
    },
  },
};

const Loading = ({ classes, pastDelay }) => (
  <div className={classes?.root}>
    {pastDelay && <ScaleLoader color={theme.colors.neutral['900']} margin="5px" size="40px" />}
  </div>
);

Loading.defaultProps = {
  pastDelay: true,
  classes: null,
};

Loading.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  pastDelay: PropTypes.bool,
};

export default withStyles(Loading, styles);
