import { MY_LIFESTYLE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

const hormones = {
  name: 'hormones',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/hormones`,
  title: 'Are you currently...',
  subtitle: (
    <>
      Changes in your body can impact your skin.
      <br /> Select all that apply.
    </>
  ),
  tip: {
    title: 'why we ask:',
    content: `Changes in your hormone levels trigger changes in your skin throughout your lifetime. It’s important to know that there is no “normal” state for your skin, and it will continue to change as you age.

&nbsp;

Here are a few common life transitions, as they relate to hormones:

&nbsp;

Usually beginning in your forties, **perimenopause** describes the years preceding menopause, when estrogen levels start to fluctuate.


&nbsp;

**Menopause** is a natural part of aging and technically begins 12 months after your last period.

&nbsp;

**Andropause** is often referred to as “male menopause” and describes the age-related decrease in the male hormone testosterone. Be assured, we’ll never share this data — we just want to create a routine that’s truly tailored to you.

&nbsp;

Be assured, we'll never share this data — we just want to create a routine that's truly tailored to you.
`,
  },
  options: [
    { label: 'Diabetic or hypoglycemic', value: 'diabetic' },
    { label: 'Transitioning through menopause, perimenopause, or andropause', value: 'menopause' },
    {
      label: 'Pregnant, trying to get pregnant, or gave birth within the last 12 months',
      value: 'pregnancy',
    },
    { label: 'None of these apply to me', value: 'none', unique: true },
  ],
  multi: true,
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_hormones_skin,
  getAnswers: ({ selected }) => ({ profile_hormones_skin: selected }),
};

export default hormones;
