import { haircareProductsRoute, skincareProductsRoute } from './products';

export const haircareRoutes = [
  ...Object.values(haircareProductsRoute),
  '/products/haircare',
  '/consultation/haircare/*',
];

export const skincareRoutes = [
  ...Object.values(skincareProductsRoute),
  '/pages/skincare',
  '/products/skincare',
  '/consultation/skincare/*',
];
