import { matchPath } from 'react-router-dom';

import { haircareRoutes, skincareRoutes } from 'constants/routes';

type LogicDisplayProps = {
  pathname?: string;
  showTrialOffer?: boolean;
  showSkincareStarterSetPromo?: boolean;
  hasHaircareSubscriptionInAnyState?: boolean;
  hasSkincareSubscriptionInAnyState?: boolean;
  hasCompletedHaircareConsultation?: boolean;
  hasCompletedSkincareConsultation?: boolean;
  isPopupHiddenByAbTest?: boolean;
};

export const trialOfferMixedWithSkincareMinisDisplayLogic = ({
  pathname,
  showTrialOffer,
  showSkincareStarterSetPromo,
  hasHaircareSubscriptionInAnyState,
  hasSkincareSubscriptionInAnyState,
}: LogicDisplayProps) =>
  !haircareRoutes.some((url) => matchPath(url, pathname!)) &&
  !skincareRoutes.some((url) => matchPath(url, pathname!)) &&
  showTrialOffer &&
  showSkincareStarterSetPromo &&
  !hasHaircareSubscriptionInAnyState &&
  !hasSkincareSubscriptionInAnyState;

export const trialOfferDisplayLogic = ({
  showTrialOffer,
  pathname,
  hasHaircareSubscriptionInAnyState,
  hasSkincareSubscriptionInAnyState,
}: LogicDisplayProps) => {
  const isHaircareRoute = haircareRoutes.some((url) => matchPath(url, pathname!));
  const isSkincareRoute = skincareRoutes.some((url) => matchPath(url, pathname!));

  // Determine if the current route is an exclusive subscription route
  // - For haircare: user must have a haircare subscription and no skincare subscription
  // - For skincare: user must have a skincare subscription and no haircare subscription
  const isMatchingExclusiveSubscriptionRoute =
    (isHaircareRoute && hasHaircareSubscriptionInAnyState && !hasSkincareSubscriptionInAnyState) ||
    (isSkincareRoute && hasSkincareSubscriptionInAnyState && !hasHaircareSubscriptionInAnyState);

  // Determine if the user is on a route with valid subscription conditions
  // - For haircare: user must have a haircare subscription, and no skincare subscription
  // - For skincare: user must have a skincare subscription, and no haircare subscription
  const isRouteWithValidSubscription =
    (isHaircareRoute && hasHaircareSubscriptionInAnyState && !hasSkincareSubscriptionInAnyState) ||
    (isSkincareRoute && hasSkincareSubscriptionInAnyState && !hasHaircareSubscriptionInAnyState);

  return showTrialOffer && (isRouteWithValidSubscription || !isMatchingExclusiveSubscriptionRoute);
};

export const skincareMinisDisplayLogic = ({
  showSkincareStarterSetPromo,
  pathname,
  hasHaircareSubscriptionInAnyState,
  hasSkincareSubscriptionInAnyState,
}: LogicDisplayProps) => {
  const isHaircareRoute = haircareRoutes.some((url) => matchPath(url, pathname!));

  // Return true if:
  // - The current route is a haircare route
  // - The skincare starter set promotion is active
  // - The user has a haircare subscription
  // - The user does not have a skincare subscription
  // OR
  // - The current route is not a haircare route and the skincare starter set promotion is active
  return (
    (isHaircareRoute &&
      showSkincareStarterSetPromo && // Promotion for skincare starter set is active
      hasHaircareSubscriptionInAnyState && // User has an active haircare subscription
      !hasSkincareSubscriptionInAnyState) || // User does not have an active skincare subscription
    (!isHaircareRoute && showSkincareStarterSetPromo) // If not a haircare route, return true only if the promotion is active
  );
};
