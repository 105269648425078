import { Typography } from 'Components/Typography';

import BlockLayout from 'Blocks/BlockLayout';

import { LegacyBaseScene as BaseScene } from 'Scenes/LegacyBaseScene';

import content from 'assets/content/coupons';

const InvalidReferralCode = () => (
  <BaseScene header justifyContent="start">
    <BlockLayout contentAlign="center">
      <Typography color="noir" paragraph variant="h1">
        {content.invalidReferralCode.title}
      </Typography>
      <Typography color="grey" variant="p1">
        {content.invalidReferralCode.description}
      </Typography>
    </BlockLayout>
  </BaseScene>
);

export default InvalidReferralCode;
