import * as APIClient from './APIClient';

export const fetch = async pubkey => {
  const response = await APIClient.get(`/v2/orders/${pubkey}/`);
  return response.json();
};

export const post = (token, saveCard = false) => {
  return APIClient.postJson('/v2/orders/', {
    payment: {
      token,
      save_card: saveCard,
    },
  });
};

export const placeOrder = (token, paymentMethod, saveCard = false) => {
  return APIClient.postJson('/v1/place_order/', {
    payment: {
      token: token.id,
      save_card: saveCard,
      payment_mode: paymentMethod,
    },
    card_id: token.card.id,
    cart_type: 'cart',
  });
};

export const placeOrderWithSavedPayment = (cardPubkey, paymentMethod) => {
  return APIClient.postJson('/v1/place_order/', {
    payment: {
      payment_mode: paymentMethod,
      saved_payment_method: cardPubkey,
    },
    card_id: cardPubkey,
    cart_type: 'cart',
  });
};

export const postFreeOrderIntentVersion = () => {
  return APIClient.postJson('/v1/place_order/', {
    cart_type: 'cart',
  });
};

export const getCheckout = checkoutPubkey => {
  return APIClient.getJson(`/v1/checkout/${checkoutPubkey}`);
};

export const postV2 = (token, saveCard = false) => {
  return APIClient.postJson('/v2/cart/place-order', {
    payment: {
      token,
      save_card: saveCard,
    },
  });
};

export const postWithSavedPaymentV2 = cardPubkey => {
  return APIClient.postJson('/v2/cart/place-order', {
    card_id: cardPubkey,
  });
};

export const postFreeOrder = () => APIClient.postJson('/v2/orders');
export const postFreeOrderV2 = () => APIClient.postJson('/v2/cart/place-order');

export const postWithPayPal = payload => APIClient.postJson('/v2/orders', payload);
export const postWithPayPalV2 = payload => APIClient.postJson('/v2/cart/place-order/', payload);

export const patchFeedback = (orderPubkey, payload) => {
  return APIClient.patchJson(`/v2/orders/${orderPubkey}/feedback/`, payload);
};

export const getProductsFeedback = orderPubkey => {
  return APIClient.get(`/v2/orders/${orderPubkey}/open_text_feedback/`);
};

export const patchProductsFeedback = (orderPubkey, payload) => {
  return APIClient.patchJson(`/v2/orders/${orderPubkey}/open_text_feedback/`, payload);
};

export const patchSkincareFeedback = (orderPubkey, payload) => {
  return APIClient.patchJson(`/v2/orders/${orderPubkey}/skincare_feedback/`, payload);
};

export const patchSupplementsFeedback = (orderPubkey, payload) => {
  return APIClient.patchJson(`/v2/orders/${orderPubkey}/supplements_feedback/`, payload);
};

export const patchPumpsPreferences = async payload => {
  const response = await APIClient.patch('/v1/cart_shipment_preference/', payload);
  return response.json();
};

export const fetchPumpsCustomerPreferences = async () => {
  const response = await APIClient.get('/v1/customer_shipment_preference/');
  return response.json();
};

export const patchPumpsCustomerPreferences = async payload => {
  const response = await APIClient.patch('/v1/customer_shipment_preference/', payload);
  return response.json();
};

export const fetchDigitalLeafletWithOrderBoxPubkey = async (/** type string */ orderBoxPubKey) => {
  const response = await APIClient.get(`/v1/digital_leaflet/box/${orderBoxPubKey}`);
  return response.json();
};

export const fetchDigitalLeafletWithOrderPubkey = async (/** type string */ orderPubKey) => {
  const response = await APIClient.get(`/v1/digital_leaflet/order/${orderPubKey}`);
  return response.json();
};
