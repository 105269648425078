import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import * as CartService from 'Services/CartService';

import { defaultHaircareFrequency } from 'constants/subscriptions';

import optimisticCartUpdate from 'utils/optimisticCartUpdate';

import {
  trackAddToCart,
  trackBeginCheckout,
  trackCartLoaded,
  trackCartUpdated,
  trackGtmCartChanged,
  trackRemoveFromCart,
} from 'dux/tracking/actions';
import { appApi } from 'dux/app/api';
import { actions as giftActions } from 'dux/gift/slice';

import {
  getCartData,
  getHasSkincareMinisInItems,
  getSkincareMinisProductsFromCatalog,
} from './selectors';

// Optimistic data logic
const getUpdateCartPendingMeta = ({ arg }, { getState }) => {
  const state = getState();
  const previousData = getCartData(state);
  const optimisticData = optimisticCartUpdate(previousData, arg);
  return optimisticData;
};

/**
 * PATCH
 */
// Update cart from a list of items that changed
export const updateCartItems = createAsyncThunk(
  'checkoutCart/updateCartItems',
  async (items, { dispatch }) => {
    const data = await CartService.queuePatchItems(items);
    dispatch(trackGtmCartChanged(items));
    dispatch(trackCartUpdated());
    return data;
  },
  { getPendingMeta: getUpdateCartPendingMeta }
);

export const updateCart = createAsyncThunk('checkoutCart/update', async (payload, { dispatch }) => {
  const data = await CartService.patch(payload);
  dispatch(trackCartUpdated());
  return data;
});

/**
 * POST
 */
export const loadCartThunk = createAsyncThunk(
  'checkoutCart/load',
  async (payload, { dispatch }) => {
    dispatch(appApi.util.invalidateTags(['gift']));
    dispatch(giftActions.reset());
    const data = await CartService.post(payload.params, payload.checkoutCategory);
    return data;
  }
);

export const loadCart = (params, checkoutCategory) => async dispatch => {
  await dispatch(loadCartThunk({ params, checkoutCategory }));
  await dispatch(trackCartLoaded());
};

export const updateMonogram = createAsyncThunk(
  'checkoutCart/updateMonogram',
  async ({ monogram, item }, { rejectWithValue }) => {
    try {
      await CartService.patch({
        items: [
          {
            category: item.category,
            product: item.product,
            type: item.type,
            quantity: item.quantity,
            customization: { monograms: monogram },
          },
        ],
      });
      return { monogram, item };
    } catch (_error) {
      Sentry.captureMessage('Failed updating monogram on cart');
      return rejectWithValue({ monogram: item.customization.monograms, item });
    }
  }
);

// Optimistic data logic
const getGoToCheckoutPendingMeta = (_params, { getState }) => {
  const state = getState();
  const previousData = getCartData(state);
  return previousData;
};

export const goToCheckout = createAsyncThunk(
  'checkoutCart/goToCheckout',
  async (_payload, { dispatch }) => {
    const updatedCart = await CartService.patch({ is_in_checkout: true });
    dispatch(trackCartUpdated());
    dispatch(trackBeginCheckout());
    return updatedCart;
  },
  { getPendingMeta: getGoToCheckoutPendingMeta }
);

export const updateItemQuantity = (item, quantity) => async (dispatch, getState) => {
  await updateCartItems([
    {
      category: item.category,
      product: item.product,
      quantity,
      type: item.type,
      customization: item.customization,
      subscription: item.subscription,
    },
  ])(dispatch, getState);

  if (!quantity) {
    dispatch(trackRemoveFromCart());
  }
};

export const addToCartAndDefaultToSubscribeWhenPossible = item => async (dispatch, getState) => {
  const state = getState();
  const hasSkincareMinisInItems = getHasSkincareMinisInItems(state);
  const skincareMinisProductsFromCatalog = getSkincareMinisProductsFromCatalog(state);

  if (hasSkincareMinisInItems) {
    await updateCartItems([
      ...skincareMinisProductsFromCatalog.map(({ type, category, subscription }) => ({
        type,
        category,
        quantity: 0,
        subscription: subscription?.subscribable
          ? {
              active: true,
              frequency: subscription?.recommended_frequency ?? 4,
            }
          : null,
      })),
      {
        category: item.category,
        product: item.product,
        quantity: 1, // Hardcoded when clicking on `ADD` button
        type: item.type,
        customization: item.customization,
        subscription: item?.subscription?.subscribable
          ? {
              active: true,
              frequency: item?.subscription?.recommended_frequency ?? defaultHaircareFrequency,
            }
          : null,
      },
    ])(dispatch, getState);
  } else {
    await updateCartItems([
      {
        category: item.category,
        product: item.product,
        quantity: 1, // Hardcoded when clicking on `ADD` button
        type: item.type,
        customization: item.customization,
        subscription: item?.subscription?.subscribable
          ? {
              active: true,
              frequency: item?.subscription?.recommended_frequency ?? defaultHaircareFrequency,
            }
          : null,
      },
    ])(dispatch, getState);
  }
  return dispatch(trackAddToCart());
};

export const updateItemSubscription =
  (item, { active, frequency }) =>
  async (dispatch, getState) =>
    updateCartItems([
      {
        category: item.category,
        product: item.product,
        quantity: item.quantity,
        type: item.type,
        customization: item.customization,
        subscription: { active, frequency },
      },
    ])(dispatch, getState);

export const loadCartSectionsTitle = createAsyncThunk(
  'checkoutCart/loadCartSectionsTitle',
  async () => {
    const data = await CartService.fetchCartSectionsTitle();
    return data;
  }
);
