import { legacyTheme } from '@prose-ui/legacy';

/**
 * @deprecated: Please use breakpoints from @prose-ui/theme instead.
 */
export const { values: breakpoints } = legacyTheme.breakpoints;

// other constants
const bannerHeight = 40;
const navbarHeight = 55;
export const headerHeight = bannerHeight + navbarHeight;
