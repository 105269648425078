import { createMakeAndWithStyles } from 'tss-react';

function useTheme() {
  return {};
}

const { makeStyles, useStyles, withStyles } = createMakeAndWithStyles({ useTheme });

const makeStylesWrapper = (
  styles: Parameters<ReturnType<typeof makeStyles>>[0],
  options?: Parameters<typeof makeStyles>[0],
) => {
  if (options) {
    return makeStyles(options)(styles);
  }
  return makeStyles()(styles);
};
/**
 * @deprecated Please use @prose-ui/styled  and @prose-ui/theme instead
 */
export { makeStylesWrapper as makeStyles, withStyles, useStyles };
