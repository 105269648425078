import { LegacyBaseScene as CommonBaseScene } from 'Scenes/LegacyBaseScene';

const BaseScene = props => (
  <CommonBaseScene
    background="beige"
    FooterProps={{ hideCTA: true }}
    header
    HeaderProps={{
      hideBannerLink: true,
      hideNavbarCTA: true,
    }}
    justifyContent="space-between"
    {...props}
  />
);

export default BaseScene;
