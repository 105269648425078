import { FIRST_HAIRCARE_QUESTION_ROUTE } from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL } from 'constants/cart';

import imageHC from 'assets/images/promotionalModals/haircare-toiletry-bag-gwp.png';
import imageSC from 'assets/images/promotionalModals/prose-custom-skincare-products-stacked.jpg';

const content = {
  contentDefault: {
    banner: 'Get 50% haircare + free gift, or try skincare on Prose',
    popUpModal: {
      image: imageHC,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description:
        'Claim your exclusive trial offer by subscribing to any 3+ hair or skin products. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Toiletry Bag and a total of 50% off your first haircare subscription order
          when you subscribe to 3+ products. Get your Skincare Starter Set when you subscribe to
          Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
          shipping and 15% off. Offers subject to expire and valid only while supplies last. Gift
          with purchase color selection randomized. Cannot be applied to previous orders or
          non-subscribed items. Not valid for accessories or digital gift cards. Exceptions may
          apply. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Try Prose with <b>50% off + a FREE gift, or a complimentary Skincare Starter Set</b> when
          you subscribe to 3+ products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Toiletry Bag and a total of 50% off your first haircare subscription order
          when you subscribe to 3+ products. Get your Skincare Starter Set when you subscribe to
          Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
          shipping and 15% off. Offers subject to expire and valid only while supplies last. Gift
          with purchase color selection randomized. Cannot be applied to previous orders or
          non-subscribed items. Not valid for accessories or digital gift cards. Exceptions may
          apply. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'WINTER WELCOME OFFER',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag ($30 value!),</b> when you subscribe to 3+ hair or
          skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Winter Welcome Offer: Get 50% off + a free gift',
    popUpModal: {
      image: imageHC,
      headerTitle: 'WINTER WELCOME OFFER',
      title: 'Try Prose with 50% off + a free Toiletry Bag ($30 value!)',
      description:
        'Subscribe to any 3+ haircare products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Toiletry Bag and a total of 50% off your first haircare subscription order
          when you subscribe to 3+ products. Get your Skincare Starter Set when you subscribe to
          Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
          shipping and 15% off. Offers subject to expire and valid only while supplies last. Gift
          with purchase color selection randomized. Cannot be applied to previous orders or
          non-subscribed items. Not valid for accessories or digital gift cards. Exceptions may
          apply. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'WINTER WELCOME OFFER',
      description: (
        <>
          Try Prose with <b>50% off + a free Toiletry Bag ($30 value!)</b> when you subscribe to any
          3+ haircare products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Toiletry Bag and a total of 50% off your first haircare subscription order
          when you subscribe to 3+ products. Get your Skincare Starter Set when you subscribe to
          Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
          shipping and 15% off. Offers subject to expire and valid only while supplies last. Gift
          with purchase color selection randomized. Cannot be applied to previous orders or
          non-subscribed items. Not valid for accessories or digital gift cards. Exceptions may
          apply. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'WINTER WELCOME OFFER',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag ($30 value!),</b> when you subscribe to 3+ haircare
          products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Subscribe for 50% off skincare',
    popUpModal: {
      image: imageSC,
      headerTitle: 'FIND YOUR FORMULA',
      title: 'Discover the custom difference',
      description: (
        <>
          500k 5-star product reviews*
          <br />
          Made with natural, clean, safe ingredients
          <br />
          Love your first order (or it’s on us)
        </>
      ),
      cta: {
        consultationCompleted: {
          dataClick: 'order-your-prose',
          dataFrom: 'trial-offer-v2-sc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
        consultationNotCompleted: {
          dataClick: 'get-your-formula',
          dataFrom: 'trial-offer-v2-sc-pop-up',
          ctaLabel: 'try prose for 50% OFF',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'trial-offer-v2-sc-pop-up',
      },
      conditions: (
        <>
          *on Review & Refine®
          <br />
          50% off your first subscription order to skincare. Subsequent subscription orders will
          receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for
          accessories or digital gift cards. Offer subject to expire.
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> skincare + <b>free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      conditions:
        '50% off your first subscription order to skincare. Subsequent subscription orders will receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for accessories or digital gift cards. Offer subject to expire.',
    },
    checkoutCommunication: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> skincare + <b>free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
