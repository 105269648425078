import {
  subscriptionCategories,
  type SubscriptionCategory,
  subscriptionSubCategories,
  type SubscriptionSubCategory,
  type SubscriptionUXCategory,
} from 'constants/subscriptions';

export const isSupplements = (
  category: SubscriptionUXCategory,
  subcategory?: SubscriptionSubCategory,
): category is typeof subscriptionSubCategories.SUPPLEMENTS =>
  subcategory === subscriptionSubCategories.SUPPLEMENTS ||
  category === subscriptionSubCategories.SUPPLEMENTS;

export const isTopicals = (
  category: SubscriptionUXCategory,
  subcategory?: SubscriptionSubCategory,
): category is SubscriptionCategory =>
  subcategory === subscriptionSubCategories.TOPICALS ||
  category === subscriptionCategories.HAIRCARE ||
  category === subscriptionCategories.SKINCARE;

export const isHaircareTopicals = ({
  category,
  subcategory,
}: {
  category: SubscriptionUXCategory;
  subcategory?: SubscriptionSubCategory;
}) =>
  (!subcategory && category === subscriptionCategories.HAIRCARE) ||
  (category === subscriptionCategories.HAIRCARE &&
    subcategory === subscriptionSubCategories.TOPICALS);

export const isSkincareTopicals = ({
  category,
  subcategory,
}: {
  category: SubscriptionUXCategory;
  subcategory?: SubscriptionSubCategory;
}) =>
  (!subcategory && category === subscriptionCategories.SKINCARE) ||
  (category === subscriptionCategories.SKINCARE &&
    subcategory === subscriptionSubCategories.TOPICALS);

export const computeSubscriptionCategory = ({
  category,
  subcategory,
}: {
  category: SubscriptionUXCategory;
  subcategory?: SubscriptionSubCategory;
}): SubscriptionUXCategory => {
  if (isHaircareTopicals({ category, subcategory })) {
    return subscriptionCategories.HAIRCARE;
  }
  if (isSupplements(category, subcategory)) {
    return subscriptionSubCategories.SUPPLEMENTS;
  }
  // By default, skincare
  return category;
};
