import type { ReactNode } from 'react';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

const RadioButton = styled.button<{ selected: boolean }>`
  appearance: none;
  position: relative;

  padding-left: 24px;
  border: none;

  background-color: transparent;
  text-align: left;

  opacity: ${(props) => (props.selected ? 1 : 0.8)};

  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    border: 1px solid ${theme.colors.neutral[800]};
    border-radius: 50%;
  }

  &::after {
    content: '';

    position: ${(props) => (props.selected ? 'absolute' : 'initial')};
    top: ${(props) => (props.selected ? '3px' : 'initial')};
    left: ${(props) => (props.selected ? '3px' : 'initial')};

    width: ${(props) => (props.selected ? '10px' : 'initial')};
    height: ${(props) => (props.selected ? '10px' : 'initial')};

    background-color: ${(props) => props.selected && theme.colors.neutral[800]};
    border-radius: ${(props) => (props.selected ? '50%' : 'initial')};
  }
`;

type AddressRadioProps = {
  children: ReactNode;
  onClick: () => void;
  selected: boolean;
  dataTestId?: string;
};

export const AddressRadio = ({
  children,
  onClick,
  selected,
  dataTestId = 'shipping-method-radio',
}: AddressRadioProps) => (
  <RadioButton
    aria-selected={selected}
    data-testid={dataTestId}
    onClick={onClick}
    selected={selected}
    type="button"
  >
    {children}
  </RadioButton>
);
