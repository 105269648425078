import { useMemo, useRef, useState } from 'react';

import isArray from 'lodash/fp/isArray';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { Typography } from 'Components/Typography';

import useEffectOnResize from 'utils/useEffectOnResize';

const Root = styled.div`
  position: relative;
`;

const DRAWER_ANIMATION_DURATION = 400; // in ms

const EllipsisContainer = styled.div<{ isOpen: boolean; clampedLines: number }>`
  position: absolute;
  top: 0;
  z-index: 1;

  height: fit-content;

  transition: opacity ${DRAWER_ANIMATION_DURATION * 0.5}ms ease;

  ${({ isOpen }) =>
    isOpen
      ? `
    transition-delay: ${DRAWER_ANIMATION_DURATION * 0.5}ms;
    opacity: 0;
    `
      : `
    cursor: pointer;
    opacity: 1;
    `}

  > div {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    ${({ clampedLines }) => `
      -webkit-line-clamp: ${clampedLines};
      line-clamp: ${clampedLines};
      `}
  }

  > div > *:not(:first-of-type) {
    display: none;
  }
`;

const DrawerContainer = styled.div<{ isOpen: boolean; ellipsisHeight: number; fullHeight: number }>`
  position: relative;
  top: 0;

  overflow: hidden;

  transition: all ${DRAWER_ANIMATION_DURATION}ms ease;

  ${({ isOpen, ellipsisHeight, fullHeight }) =>
    isOpen
      ? `
      height: ${fullHeight}px;
      opacity: 1;
      `
      : `
      height: ${ellipsisHeight === 0 ? 'fit-content' : `${ellipsisHeight}px`};
      opacity: 0;
      `}
`;

const Paragraph = styled.p`
  &:first-of-type {
    margin-top: 0;
    margin-bottom: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FoldButton = styled(Typography)`
  margin-top: ${theme.spacing['2x']};
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  position: relative;
`;

type Props = {
  clampedLines?: number;
  dataClick?: string;
  dataFrom?: string;
  children: string | Array<string>; // only supports strings | TODO: support for <span> or <p>
};

const CollapsibleWithEllipsis = ({
  children,
  clampedLines = 2,
  dataClick = undefined,
  dataFrom = undefined,
}: Props) => {
  const ellipsisRef = useRef<HTMLDivElement>(null);
  const drawerInnerDivRef = useRef<HTMLDivElement>(null);

  const [ellipsisHeight, setEllipsisHeight] = useState(0);
  const [fullHeight, setFullHeight] = useState(Infinity);
  const [isOpen, setIsOpen] = useState(false);

  const hasOnlyOneChild =
    typeof children === 'string'; /* assumes we only support string as a first child */

  const needsReadMore = ellipsisHeight < fullHeight;

  const decoratedChildren = useMemo(
    () =>
      hasOnlyOneChild ? (
        children === '' ? null : (
          <Paragraph>{children}</Paragraph>
        )
      ) : (
        children.map((child) => {
          if (child === '') return null;
          return <Paragraph key={child.slice(12)}>{child}</Paragraph>;
        })
      ),
    [children],
  );

  useEffectOnResize(() => {
    const ellipsisElement = ellipsisRef.current;
    if (!ellipsisElement) return;

    setEllipsisHeight(ellipsisRef.current.offsetHeight);

    const drawerInnerDivElement = drawerInnerDivRef.current;
    if (!drawerInnerDivElement) return;

    const firstParagraphElement = drawerInnerDivElement.firstChild as HTMLParagraphElement;
    if (!firstParagraphElement) return;

    const lastElement = (drawerInnerDivElement.lastChild as Element) ?? firstParagraphElement;

    const marginTop = parseFloat(getComputedStyle(firstParagraphElement).marginTop);
    const marginBottom = !lastElement
      ? parseFloat(getComputedStyle(firstParagraphElement).marginBottom)
      : lastElement instanceof HTMLElement
      ? parseFloat(getComputedStyle(lastElement).marginBottom)
      : 0; // maybe this default could be improved

    setFullHeight(drawerInnerDivElement.offsetHeight + marginTop + marginBottom);
  }, [isOpen, children]);

  if (children === '' || (isArray(children) && children.every((child) => child === ''))) {
    return null;
  }

  return (
    <Root>
      <EllipsisContainer
        ref={ellipsisRef}
        aria-hidden="true" // duplicate element for animation purposes only
        clampedLines={clampedLines}
        isOpen={needsReadMore ? isOpen : true}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div>{decoratedChildren}</div>
      </EllipsisContainer>

      <DrawerContainer
        ellipsisHeight={ellipsisHeight}
        fullHeight={fullHeight}
        isOpen={needsReadMore ? isOpen : true}
      >
        <div ref={drawerInnerDivRef}>{decoratedChildren}</div>
      </DrawerContainer>

      {needsReadMore && (
        <FoldButton
          aria-hidden="true" // for simplicity, screenreaders only see the expanded content
          color="noir"
          {...(dataClick ? { 'data-click': dataClick } : {})}
          {...(dataFrom ? { 'data-from': dataFrom } : {})}
          markupName="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          underline
          variant="p2"
        >
          {isOpen ? 'Read less' : 'Read more'}
        </FoldButton>
      )}
    </Root>
  );
};

export default CollapsibleWithEllipsis;
