import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { BaseField } from './BaseField';

/**
 * TextField is basically a BaseField which renders a text input with disabled autocomplete.
 */
const TextField = forwardRef(({ autoCorrect, ...props }, ref) => (
  <BaseField ref={ref} {...props}>
    {inputProps => (
      <input {...inputProps} autoComplete="chrome-off" autoCorrect={autoCorrect ? 'on' : 'off'} />
    )}
  </BaseField>
));

TextField.displayName = 'TextField';
TextField.propTypes = {
  autoCorrect: PropTypes.bool,
  /**
   * All remaining props are passed to BaseField
   */
};

TextField.defaultProps = {
  autoCorrect: true,
};

export default TextField;
