import bgPictureCandleArcadia from 'assets/images/productPages/accessories-collection-candle_arcadia.jpg';
import bgPictureCandleCorsica from 'assets/images/productPages/accessories-collection-candle_corsica.jpg';
import bgPictureCandlePrelude from 'assets/images/productPages/accessories-collection-candle_prelude.jpg';
import SustainabilityPictureDesktop from 'assets/images/productPages/accessories-sustainability-candles-d.jpg';
import SustainabilityPictureMobile from 'assets/images/productPages/accessories-sustainability-candles-m.jpg';
import bgPictureCandleValuesDesktop from 'assets/images/productPages/candle_values-d.jpg';
import bgPictureCandleValuesMobile from 'assets/images/productPages/candle_values-m.jpg';
import fragranceHeroArcadiaImageDesktop from 'assets/images/productPages/fragrance-hero-arcadia-d.jpg';
import fragranceHeroArcadiaImageMobile from 'assets/images/productPages/fragrance-hero-arcadia-m.jpg';
import fragranceHeroCorsicaImageDesktop from 'assets/images/productPages/fragrance-hero-corsica-d.jpg';
import fragranceHeroCorsicaImageMobile from 'assets/images/productPages/fragrance-hero-corsica-m.jpg';
import fragranceHeroPreludeImageDesktop from 'assets/images/productPages/fragrance-hero-prelude-d.jpg';
import fragranceHeroPreludeImageMobile from 'assets/images/productPages/fragrance-hero-prelude-m.jpg';
import BcorpLogo from 'assets/images/skincare/b-corp-logo.png';
import ClimateLogo from 'assets/images/skincare/climate-logo.png';

import { CANDLE_ARCADIA, CANDLE_CORSICA, CANDLE_PRELUDE } from '../accessoriesProducts';

export const candlePageContent = {
  heroModule: {
    dataFrom: 'accessories-pdp-cta',
    selectedVariant: (variant: string) => `Fragrance: ${variant}`,
    subText: 'Up to 50 hours burn time',
    title: 'Candles by Prose',
    imageCarousel: [
      {
        image: bgPictureCandleCorsica,
        alt: 'Prose candle corsica',
      },
      {
        image: bgPictureCandlePrelude,
        alt: 'Prose candle prelude',
      },
      {
        image: bgPictureCandleArcadia,
        alt: 'Prose candle arcadia',
      },
    ],
  },
  fragrancesHero: [
    {
      slug: CANDLE_CORSICA,
      title: 'Corsica',
      subtitle: 'FRESH + AQUATIC',
      descriptions: [
        { title: 'Notes:', descriptions: ['Anjou Pear', 'Peony', 'White Woods'] },
        {
          title: 'reminds us of:',
          descriptions: ['Warm ocean winds, cliff-clinging wildflowers, Mediterranean sunsets.'],
        },
      ],
      images: {
        mobile: fragranceHeroCorsicaImageMobile,
        desktop: fragranceHeroCorsicaImageDesktop,
        alt: 'corsica candle with anjour pear, peaony and White Woods',
      },
      wideScreenOrder: '21',
    },
    {
      slug: CANDLE_PRELUDE,
      title: 'Prelude',
      subtitle: 'FLORAL + FRESH',
      descriptions: [
        { title: 'Notes:', descriptions: ['Rose', 'Geranium', 'Blue Iris'] },
        {
          title: 'reminds us of:',
          descriptions: ['Spring picnics, blossoming boughs, showers of soft pink petals.'],
        },
      ],
      images: {
        mobile: fragranceHeroPreludeImageMobile,
        desktop: fragranceHeroPreludeImageDesktop,
        alt: 'Prelude candle with rose, geranium and blue iris',
      },
      wideScreenOrder: '12',
    },
    {
      slug: CANDLE_ARCADIA,
      title: 'Arcadia',
      subtitle: 'CITRUSY + WOODY',
      descriptions: [
        { title: 'Notes:', descriptions: ['Citrus', 'Basil', 'Cedar'] },
        {
          title: 'reminds us of:',
          descriptions: ['Rushing waterfalls, cedar forests, lush basil, bright citrus trees.'],
        },
      ],
      images: {
        mobile: fragranceHeroArcadiaImageMobile,
        desktop: fragranceHeroArcadiaImageDesktop,
        alt: 'arcadia candle with citrus, basil and cedar',
      },
      wideScreenOrder: '21',
    },
  ],
  sustainability: {
    title: 'Consciously crafted, simply sustainable',
    description:
      'Our candles are made with natural, paraffin-free wax, IFRA-compliant fragrance oils, and a reusable iron vessel you can use long after your candle is finished.',
    imageDesktop: SustainabilityPictureDesktop,
    imageMobile: SustainabilityPictureMobile,
    imageAlt: 'candles-and-flowers',
    logos: {
      bCorp: BcorpLogo,
      climate: ClimateLogo,
    },
  },
  ticker: ['Good Smells'],
  faq: [
    {
      question: 'What does Prose mean by clean fragrance?',
      answer:
        "Prose candles are made with a mix of natural and synthetic fragrance oils compliant with the latest International Fragrance Association guidelines. Our clean fragrances meet the highest standards for safety and transparency of composition. We partnered directly with our fragrance houses to ensure that our fragrances are clean and aligned with our extensive ingredient no-list. Our fragrances will never include intentionally added CMR substances or BHT, which is thought to disrupt endocrine function. We strictly follow the EU cosmetic regulations and the latest IFRA amendments (which means you'll never find lilial or lyral in our products) and we test all of our products under dermatologist control. We are happy to disclose the full composition of our fragrances upon request. Our fragrances are 100% vegan.",
    },
    {
      question: 'How do I make the most of my Prose candle?',
      answer: (
        <>
          Limit each burn to 4 hours, and avoid short burns to curb tunneling. Never leave your
          candle unattended and always burn it on a suitable surface — more than 3 feet away from
          the overhead surfaces, and away from drafts, pets, and children. Do not touch or move a
          burning candle.
          <br />
          <br />
          Each time you light your candle, trim the wick to ¼” and remove all debris from the wax
          pool to reduce smoke and maximize the lifetime of your candle.
          <br />
          <br />
          As Prose candles are made with a blend of natural waxes, some marbling or bubbling on the
          surface of the wax before or after burning is possible. However, this in no way impacts
          the performance of your candle.
        </>
      ),
    },
    {
      question: 'How do I avoid tunneling, and why is it so important?',
      answer:
        'Tunneling occurs when only the center of the candle melts down, which cuts down burn time and can make the candle more difficult to light over time. To avoid tunneling, trim the wick to ¼” and let it burn for 2–3 hours the first time you light it. This will allow the entire surface area of the wax to melt, and will create a burn “memory” for the wax to follow in the future.',
    },
    {
      question: 'How do I clean out my candle and re-use the vessel?',
      answer:
        'We recommend retiring your candle when there is about ½” of wax left. Remove the leftover wax by placing your candle in the freezer for a few hours — this will make it easier to remove the remaining wax.',
    },
    {
      question: 'How do I purchase a Prose candle?',
      answer:
        'At this time, candles can only be purchased if you have a Prose account. Once you create an account and complete the haircare consultation, you can purchase as many Prose candles as you like.',
    },
  ],
  values: {
    header: 'A more thoughtfully made candle',
    background: {
      desktop: bgPictureCandleValuesDesktop,
      mobile: bgPictureCandleValuesMobile,
    },
    list: [
      {
        title: 'CLEAN FRAGRANCE OILS',
        titleEl: (
          <span>
            CLEAN
            <br />
            FRAGRANCE OILS
          </span>
        ),
        subtitleEl: (
          <span>
            from natural raw materials
            <br />+ safe synthetics
          </span>
        ),
      },
      {
        title: 'SOY+COCONUT WAX',
        titleEl: (
          <span>
            SOY+COCONUT
            <br />
            WAX
          </span>
        ),
        subtitleEl: (
          <span>
            for a clean burn + optimal
            <br />
            fragrance bloom
          </span>
        ),
      },
      {
        title: 'LIGHTWEIGHT IRON VESSEL',
        titleEl: (
          <span>
            LIGHTWEIGHT
            <br />
            IRON VESSEL
          </span>
        ),
        subtitleEl: (
          <span>
            for a lower shipping
            <br />
            carbon footprint
          </span>
        ),
      },
    ],
  },
};
