import type { ResponsePromise } from 'ky';

import { authClient } from './AuthClient';

// generates a response object with only the json method
const prepareFileResponse = (response: ResponsePromise) => ({
  download: async (filename?: string) => {
    // adpated from https://gist.github.com/daimagine/cb3c6e75011b340f878452b243241abb

    // extract user agent
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipod|ipad|crios|fxios/.test(userAgent);
    const chrome = /crios/.test(userAgent);
    // create blob
    const blob = await response.blob();
    const blobURL = window.URL.createObjectURL(blob);

    // if its ipad or iphone
    if (ios) {
      const reader = new FileReader();
      reader.onload = () => {
        if (chrome) {
          window.open(blobURL);
        } else {
          window.location.href = blobURL;
        }
      };
      reader.readAsDataURL(blob);
      return;
    }

    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const link = document.createElement('a');
    link.href = blobURL;
    link.style.display = 'none';
    if (filename) {
      link.setAttribute('download', filename);
    } else {
      link.toggleAttribute(
        'download',
        true,
      ); /* no value for `download` attribute keeps the original filename */
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  },

  display: async () => {
    const blob = await response.blob();
    const blobURL = window.URL.createObjectURL(blob);

    if (/CriOS/.test(navigator.userAgent)) {
      const reader = new FileReader();
      reader.onload = () => {
        window.open(blobURL);
      };
      reader.readAsDataURL(blob);
    }
    return window.URL.createObjectURL(blob);
  },
});

const leadingSlashPattern = /^\/?/;
const traillingSlashPattern = /\/?(\?|#|$)/;
const formatRoute = (route: string) =>
  route.replace(leadingSlashPattern, '').replace(traillingSlashPattern, '/$1');

export const get = (route: string) => authClient.get(formatRoute(route));
export const getFile = (route: string, params?: unknown) =>
  prepareFileResponse(
    authClient.get(route.replace(leadingSlashPattern, ''), {
      json: params,
      headers: { Accept: 'application/json', 'Content-Type': 'application/pdf' },
    }),
  );
export const patch = (route: string, params?: unknown) =>
  authClient.patch(formatRoute(route), { json: params });
export const put = (route: string, params?: unknown) =>
  authClient.put(formatRoute(route), { json: params });
export const post = (route: string, params?: unknown) =>
  authClient.post(formatRoute(route), { json: params });
export const del = (route: string) => authClient.delete(formatRoute(route));

export const getJson = async (route: string) => {
  const response = await get(route);
  return response.json();
};

export const patchJson = async (route: string, params: unknown) => {
  const response = await patch(route, params);
  return response.json();
};

export const postJson = async (route: string, params: unknown) => {
  const response = await post(route, params);
  if (response.status === 204) return undefined;
  return response.json();
};
