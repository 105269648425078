import { matchPath, useLocation, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'dux/app/hooks';

import isNil from 'lodash/fp/isNil';

import { legacyTheme, styled } from '@prose-ui/legacy';

import CheckoutNavBar from 'Apps/Checkout/Components/CheckoutNavBar';
import { ConsultationNavBar } from 'Apps/Consultation/Components/ConsultationNavBar';
import { useConsultationStepIsStandalone } from 'Apps/Consultation/hooks/useConsultationStepIsStandalone';

import { SkipLinkA11y } from 'Components/SkipLinkA11y';

import Banner from 'Blocks/Banner';
import { NavBar } from 'Blocks/NavBar';

import { getIsAuthenticated } from 'dux/auth/selectors';
import { getUserFirstName } from 'dux/user/selectors';

const HeaderContent = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${legacyTheme.zIndex.appBar};

  width: 100%;
`;

export type HeaderProps = {
  banner?: boolean;
  showFooterLink?: boolean;
};

export const Header = ({ banner = false, showFooterLink = false }: HeaderProps) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const userName = useAppSelector(getUserFirstName);
  const isStandalone = useConsultationStepIsStandalone();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const { consultationType } = matchPath('/:consultationType/*', pathname)?.params || {};
  const { checkoutStep } = matchPath('/checkout/:category/:checkoutStep', pathname)?.params || {};

  const consultationSubRoute = ['consultation', 'results'].includes(consultationType ?? '');
  const showConsultationNavBar = consultationSubRoute && !isStandalone;
  const showCheckoutNavBar = !isNil(checkoutStep);

  return (
    <HeaderContent>
      <SkipLinkA11y showFooterLink={showFooterLink} />
      {banner && <Banner pathname={pathname} searchParams={params} />}
      {showCheckoutNavBar ? (
        <CheckoutNavBar />
      ) : showConsultationNavBar ? (
        <ConsultationNavBar />
      ) : (
        <NavBar isAuthenticated={isAuthenticated} pathname={pathname} userName={userName} />
      )}
    </HeaderContent>
  );
};
