export const ExpressCheckoutAbTest = {
  TEST_ID: 'LixCqDh-QF-7qH7FKmJYDQ',
  name: 'Express Checkout AB Test - November 2021',
};

export const GrowthbookFakeExperimentExperiment = {
  TEST_ID: 'oHUXOZBgQHy7KCSh97Wjbg',
  name: 'GrowthBook Fake Experiment',
};

export const ShipNowExperiment = {
  TEST_ID: '69f993F50d20cd09C98198',
  name: 'Ship Now Launch AB test - February 2024',
};

export const SubscribeSaveCheckoutModuleExperiment = {
  TEST_ID: 'e6bbd55979c051d98c73758c5e24b9a225928176778ad9c7a66d89b9c8ce7f36',
  name: 'Subscribe and save module at checkout',
};
export const FeedbackAwarenessCtaExperiment = {
  TEST_ID: '8d3bcb79c46428d165b23333ab9ebe39565579a98c4656d1b4c666a005836735',
  name: 'AB Test - "Refine your formula" vs "Provide feedback"',
};
export const SkincareMinisFrequencyExperiment = {
  TEST_ID: 'e612bdac7835631fa125eb5a30da4567f95f27ed6974d3c51ed2d7d6d2203cce',
  name: 'SSS 8-week Default Frequency AB Test',
};
export const PostPurchasePromoLayoutExperiment = {
  TEST_ID: 'f5251fd3efa92646d5a7b45e97567a12d1f46b8a4fae6a389e59f37393efe5ff',
  name: 'Post Purchase Promo Layout AB Test - September 2024',
};

export const SkincareConcernsOrderExperiment = {
  TEST_ID: 'b471cae68150ccf2c376b36318ee95365d36e2ca5c3c2d66f0b1c01dc9886b8b',
  name: 'skincare-concerns-order',
};

export const SssShippingFeeExperiment = {
  TEST_ID: 'f357255cf84500bce5a4ac6fa936ac3cd6116bb71e278b97c43ef57e49d2c0bc',
  name: 'Shipping Fee AB Test',
};
export const PostPurchasePopupExperiment = {
  TEST_ID: 'c92d14abe269c5b5efc2bc26c4d88889fc071afe536f78ff017579eedc298b9b',
  name: 'Post purchase popup',
};

export const SubscriptionItemRemovalExperiment = {
  TEST_ID: 'b7e91d330fbea0e29ad20d754fd7de52e398fadf9797b7ea875ec8afb29946f6',
  name: 'Subscription Item Removal',
};
export const PostPurchasePromoDesignPhase2Experiment = {
  TEST_ID: '383d7a50d8a4319f86b673858441de250624f849fd8e568a148557bcce669d8c',
  name: '',
};

export const KeyActionCardExperiment = {
  TEST_ID: 'b2fd43fd11cfd9fe89252b313699dee10e825bed57a0142cc7e1705020440ac5',
  name: 'account_overview_generic_key_action_card_ab_test_december_2024',
};
