import { forwardRef, type ReactElement } from 'react';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import { LazyImg } from 'Components/LazyImg';
import { Typography } from 'Components/Typography';

import BlockLayout from 'Blocks/BlockLayout';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import TwoUp from './LegacyTwoUp';

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 66.67vw;

  &.image-mode-cover {
    position: relative;
    overflow: hidden;
  }

  &.image-mode-contain {
    /* @Marc: on mobile contain mode, block spacing is before image */
    margin-top: ${legacyTheme.props.verticalSpacingMob};
  }

  &.is-uncropped: {
    height: 95vw;
  }

  ${legacyTheme.breakpoints.up('md')} {
    height: 100%;

    &.has-bottom-border {
      border-bottom: 1px solid;
    }

    &.has-middle-border-left {
      border-left: 1px solid;
    }

    &.has-middle-border-right {
      border-right: 1px solid;
    }

    &.image-mode-contain {
      margin-top: ${legacyTheme.props.verticalSpacingDes};
    }

    &.is-uncropped: {
      height: initial;
    }
  }
`;

const StyledLazyImg = styled(LazyImg)`
  &.image-mode-contain {
    width: 100%;
  }

  &.image-mode-contain-desktop {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.image-mode-cover {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    width: 100%;
    /* @Marc: ensure no white space after translate */
    min-height: 100.5%;

    object-fit: cover;
  }
`;

const ImageCaption = styled(Typography)`
  position: absolute;
  bottom: 30px;
  left: 30px;
`;

const StyledDisclaimer = styled(Typography)`
  margin-top: ${theme.spacing['4x']};
  font-size: ${theme.typography.fontSize.xs};
`;

const useStyles = makeStyles(
  {
    root: {
      '&.image-mode-contain': {
        alignItems: 'center',
      },
      '&.image-mode-contain-desktop': {
        alignItems: 'center',
      },
    },
    blockContent: {
      maxWidth: legacyTheme.width.normal,
      [legacyTheme.breakpoints.up('md')]: {
        maxWidth: legacyTheme.width.half,
      },
      '&.image-mode-contain': {
        /* @Marc: on mobile contain mode, image will be closer to the title */
        marginTop: legacyTheme.props.minimumBlockPadding,
        [legacyTheme.breakpoints.up('md')]: {
          marginTop: legacyTheme.props.verticalSpacingDes,
        },
      },
      '&.image-mode-contain-desktop': {
        /* @Marc: on mobile contain mode, image will be closer to the title */
        marginTop: 72,
        [legacyTheme.breakpoints.up('md')]: {
          marginTop: legacyTheme.props.verticalSpacingDes,
        },
      },
    },
  },
  { name: 'TextImage' },
);

type TextImageProps = {
  backgroundColor?: string;
  children?: ReactElement;
  classes?: {};
  contentAlign?: 'center' | 'left';
  contentAlignMob?: 'center' | 'left';
  dataTestId?: string;
  description?: string;
  descriptionProps?: {};
  disclaimer?: string;
  disclaimerProps?: {};
  hasImageBorder?: boolean;
  hasMiddleBorder?: boolean;
  hasUncroppedMobileImage?: boolean;
  hasWhiteFont?: boolean;
  id?: string;
  imageCaption?: string;
  imageAlt?: string;
  imageDesktop: {
    src: string;
  };
  imageMobile: {
    src: string;
  };
  imageMode?: 'cover' | 'contain' | 'contain-desktop';
  imageTablet?: {
    src: string;
  };
  subtitle?: string;
  subtitleProps?: {};
  title?: string | ReactElement;
  titleProps?: {};
  wideScreenOrder?: '12' | '21';
};

/**
  This component derives from the TwoUp component, with one block being an image, and another
  being some text or other content with internal padding.
  You should configure it using two different images. They will be swaped at the md breakpoint
  (1024px). On wide screens the image is cropped vertically so it respects the content on the
  other block. On narrow screens the image is stacked and respects a 3:2 ratio.
*/
export const TextImage = forwardRef<HTMLDivElement, TextImageProps>(
  (
    {
      backgroundColor = null,
      children = null,
      contentAlign = 'left',
      contentAlignMob = 'center',
      dataTestId = null,
      description = null,
      descriptionProps = {},
      disclaimer = null,
      disclaimerProps = {},
      hasImageBorder = false,
      hasMiddleBorder = false,
      hasWhiteFont = false,
      hasUncroppedMobileImage = false,
      id,
      imageCaption = null,
      imageAlt = '',
      imageDesktop,
      imageMobile,
      imageMode = 'cover',
      imageTablet = undefined,
      subtitle = null,
      subtitleProps = {},
      title = null,
      titleProps = {},
      wideScreenOrder = '12',
      ...props
    },
    ref,
  ) => {
    const { isTablet, isDesktop } = useResponsiveVariant();
    const { classes, cx } = useStyles();

    const image = isDesktop ? imageDesktop : isTablet ? imageTablet : imageMobile;
    const whiteFont = ['vert', 'rouge'].includes(backgroundColor ?? '') || hasWhiteFont;

    return (
      // @ts-expect-error - TwoUp has no TS types yet
      <TwoUp
        ref={ref}
        {...props}
        backgroundColor={backgroundColor}
        classes={{
          root: cx(classes.root, `image-mode-${imageMode}`),
        }}
        id={id}
        wideScreenOrder={wideScreenOrder}
      >
        <ImageContainer
          className={cx(`image-mode-${imageMode}`, {
            'is-uncropped': hasUncroppedMobileImage,
            'has-bottom-border': hasImageBorder,
            'has-middle-border-left': hasMiddleBorder && wideScreenOrder === '21',
            'has-middle-border-right': hasMiddleBorder && wideScreenOrder === '12',
          })}
        >
          {image && (
            // @ts-expect-error - LazyImg has no TS types yet
            <StyledLazyImg alt={imageAlt} className={`image-mode-${imageMode}`} src={image.src} />
          )}
          {imageCaption && (
            <ImageCaption color="white" variant="p1">
              {imageCaption}
            </ImageCaption>
          )}
        </ImageContainer>

        {/* @ts-expect-error - BlockLayout has no TS types yet */}
        <BlockLayout
          classes={{
            content: cx(classes.blockContent, `image-mode-${imageMode}`),
          }}
          contentAlign={contentAlign}
          contentAlignMob={contentAlignMob}
          dataTestId={dataTestId}
        >
          {subtitle && (
            <Typography
              color={whiteFont ? 'white' : 'noir'}
              markupName="p"
              paragraph
              style={{ fontSize: 16 }}
              variant="mono1"
              {...subtitleProps}
            >
              {subtitle}
            </Typography>
          )}
          {title && (
            <Typography
              color={whiteFont ? 'white' : 'noir'}
              markupName="h3"
              paragraph
              variant="h1"
              {...titleProps}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              color={whiteFont ? 'white' : 'grey'}
              paragraph={Boolean(children)}
              variant="p1"
              {...descriptionProps}
            >
              {description}
            </Typography>
          )}
          {disclaimer && (
            <StyledDisclaimer
              color="grey"
              paragraph={Boolean(children)}
              variant="p1"
              {...disclaimerProps}
            >
              {disclaimer}
            </StyledDisclaimer>
          )}
          {children}
        </BlockLayout>
      </TwoUp>
    );
  },
);

TextImage.displayName = 'TextImage';
