import acaciaCollagenImg from 'assets/images/productPages/ingredients/acacia_collagen.png';
import appleCiderVinegarImg from 'assets/images/productPages/ingredients/apple_cider_vinegar.png';
import arginineImg from 'assets/images/productPages/ingredients/arginine.png';
import caviarLimeExtractImg from 'assets/images/productPages/ingredients/caviar_lime_extract.png';
import copaibaImg from 'assets/images/productPages/ingredients/copaiba.png';
import cornStarchImg from 'assets/images/productPages/ingredients/corn-starch.png';
import fermentedRiceWaterImg from 'assets/images/productPages/ingredients/fermented_rice_water.png';
import grapeSeedOilImg from 'assets/images/productPages/ingredients/grape_seed_oil.png';
import hyaluronicImg from 'assets/images/productPages/ingredients/hyaluronic_acid.png';
import kalahariMelonOilImg from 'assets/images/productPages/ingredients/kalahari_melon_oil.png';
import oatOilImg from 'assets/images/productPages/ingredients/oat_oil.png';
import peaExtractImg from 'assets/images/productPages/ingredients/pea_extract.png';
import ungurahuiOilImg from 'assets/images/productPages/ingredients/ungurahui_oil.png';
import carouselImg3 from 'assets/images/productPages/prose-custom-leave-in-conditioner-in-use.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-leave-in-conditioner-pump-top.jpg';
import carouselImg1 from 'assets/images/productPages/prose-custom-leave-in-conditioner-spray-top.jpg';
import carouselImg4 from 'assets/images/productPages/prose-model-posing-with-custom-leave-in-conditioner.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-posing-with-custom-leave-in-conditioner-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-posing-with-custom-leave-in-conditioner-mobile.jpg';
import carouselImg5 from 'assets/images/productPages/prose-ugc-leavein.jpg';

export const leaveInConditionerPageContent = {
  sampleFormulasSlider: [
    {
      title: "JUDE'S HYDRATING + HEAT PROTECTANT LEAVE-IN CONDITIONER",
      ingredients: [
        {
          image: caviarLimeExtractImg,
          name: 'Caviar Lime Extract',
          benefit: 'Shine',
        },
        {
          image: oatOilImg,
          name: 'Oat Oil',
          benefit: 'Color Protection',
        },
        {
          image: acaciaCollagenImg,
          name: 'Acacia collagen',
          benefit: 'Fiber Repairing',
        },
        {
          image: arginineImg,
          name: 'Arginine',
          benefit: 'Heat Protect',
        },
        {
          image: grapeSeedOilImg,
          name: 'Grape Seed Oil',
          benefit: 'Hair Health',
        },
        {
          image: kalahariMelonOilImg,
          name: 'Kalahari Melon  Oil',
          benefit: 'Hydration',
        },
      ],
    },
    {
      title: "LAUREN'S SMOOTHING + SHINE-ENHANCING LEAVE-IN CONDITIONER",
      ingredients: [
        {
          image: fermentedRiceWaterImg,
          name: 'Fermented Rice Water',
          benefit: 'Smoothing',
        },
        {
          image: acaciaCollagenImg,
          name: 'Acacia Collagen',
          benefit: 'Hydration',
        },
        {
          image: caviarLimeExtractImg,
          name: 'Caviar lime Extract ',
          benefit: 'Shine',
        },
        {
          image: peaExtractImg,
          name: 'Pea Protein',
          benefit: 'Heat Protection',
        },
        {
          image: copaibaImg,
          name: 'Copaiba oil',
          benefit: 'Nourishment',
        },
        {
          image: ungurahuiOilImg,
          name: 'Ungurahui  Oil',
          benefit: 'Hair Health',
        },
      ],
    },
    {
      title: "ALEX'S NOURISHING + VOLUMIZING LEAVE-IN CONDITIONER",
      ingredients: [
        {
          image: cornStarchImg,
          name: 'Corn Starch',
          benefit: 'Volume',
        },
        {
          image: appleCiderVinegarImg,
          name: 'Apple Cider Vinegar',
          benefit: 'Shine',
        },
        {
          image: copaibaImg,
          name: 'Copaiba Oil',
          benefit: 'Nourishment',
        },
        {
          image: fermentedRiceWaterImg,
          name: 'Fermented Rice Water',
          benefit: 'Smoothing',
        },
        {
          image: hyaluronicImg,
          name: 'Hyaluronic Acid',
          benefit: 'Hydration',
        },
        {
          image: grapeSeedOilImg,
          name: 'Grape Seed Oil',
          benefit: 'Hair Health',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'vert',
      number: '382',
      description:
        'Following the L.O.C. or L.C.O. methods to nourish your natural curls and coils? Use custom leave-in conditioner along with custom hair oil for the perfect wash-and-go.',
    },
    {
      background: 'vert',
      number: '650',
      description:
        'Struggling with humidity-induced frizz? Lightly dampen your lengths, then apply custom leave-in conditioner to eliminate frizz and flyaways—the water-based formula replenishes strands without weighing them down.',
    },
    {
      background: 'vert',
      number: '471',
      description:
        'For an extra dose of hydration, use Prose custom leave-in conditioner alongside custom hair oil. Apply your leave-in on damp or dry hair, then finish with hair oil to lock in moisture without any greasy residue.',
    },
  ],
  faq: [
    {
      question: 'What hair types does Prose custom leave-in conditioner work for?',
      answer:
        "Prose leave-in conditioner is custom-formulated for every individual, and isn't limited to one hair type. It delivers the right amount of hydration for your needs, and contains a unique blend of active ingredients to meet your hair goals.",
    },
    {
      question:
        'How do I use Prose custom leave-in conditioner? Can I use it as a heat protectant?',
      answer:
        'Custom leave-in conditioner can be applied on damp hair as a hydrating mist and styling primer. It can also be used on damp, towel-dried hair as a detangler before or after washing. More instructions and a fully detailed routine will come with your unique formula. Custom Leave-in can also be used as a heat protectant before heat styling—it’s lab-proven to protect strands  up to 450°F. In the consultation, we’ll ask about your styling routine; be sure to select whichever heat tools you use, and your custom formula will be thermoprotective.',
    },
    {
      question: 'Will Prose custom leave-in conditioner leave any residue or stains?',
      answer:
        "Our non-greasy formula keeps strands hydrated and soft without feeling weighed down. And since it's meant to be left in until your next wash, you can rest easy—it leaves no trace on your pillowcase or sheets.",
    },
    {
      question: 'Is Prose custom leave-in conditioner color-safe?',
      answer:
        'Yes—all Prose products are color-safe, and if you indicate color treatments during the consultation, your custom leave-in may contain additional ingredients for color protection.',
    },
    {
      question: 'Can custom leave-in conditioner meet my Prose ingredient preferences?',
      answer:
        "There's no need to worry—all leave-in conditioner formulas are vegan. Additionally, you can opt for a silicone-free and/or fragrance-free formula during the consultation.",
    },
    {
      question: 'What ingredients are NOT used in Prose custom leave-in conditioner?',
      answer:
        'Prose custom leave-in conditioner is free from the following: alcohol, phthalates, GMOs, sulfates, parabens, phthalates, and mineral oil. Like all Prose products, leave-in conditioner is cruelty-free.',
    },
    {
      question:
        'Can Prose Custom Leave-in Conditioner treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom leave-in conditioner packaging with spray top',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom leave-in conditioner packaging with pump top',
    },
    {
      image: carouselImg3,
      alt: 'Prose custom leave-in conditioner in use',
    },
    {
      image: carouselImg4,
      alt: 'Prose model posing with bottle of custom leave-in conditioner',
    },
    {
      image: carouselImg5,
      alt: 'Models posing with custom leave-in conditioner bottles',
    },
  ],
  noListModule: {
    description:
      'Every ingredient in your Custom Leave-In Conditioner is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A leave-in conditioner with naturally powerful + proven-effective ingredients',
  },
  formulaModule: {
    header: (
      <>
        A personalized leave-in conditioner,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every leave-in conditioner we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model posing with bottle of custom leave-in conditioner',
    },
  },
};
