import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const zitsFrequency = {
  name: 'zits_frequency',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/zits-frequency`,
  title: 'How often do you break out?',
  subtitle: 'Choose what best reflects your skin in the last 3 months.',
  tip: {
    title: 'Why we ask:',
    content:
      'Learning more about the frequency of your breakouts will help us identify the right active ingredients to include in your formulas.',
  },
  options: [
    {
      value: 'rarely',
      label: 'Rarely',
    },
    {
      value: 'occasionally',
      label: 'Occasionally',
    },
    {
      value: 'constantly',
      label: 'Constantly',
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('breakouts'),
  getInitialValue: answers => answers.diag_zits_frequency,
  getAnswers: ({ selected }) => ({
    diag_zits_frequency: selected,
  }),
};

export default zitsFrequency;
