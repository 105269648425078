import { MY_LIFESTYLE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

import { ReactComponent as SleepIcon } from 'assets/images/skincare/sleepIcon.svg';

const sleep = {
  name: 'sleep',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/sleep`,
  title: 'How many hours of sleep do you usually get a night?',
  subtitle: (
    <>
      These options are intended as estimations. <br /> Choose what best reflects your typical
      schedule over the past 3 months.
    </>
  ),
  icon: <SleepIcon />,
  tip: {
    title: 'WHY WE ASK:',
    content:
      'Your skin repairs itself while you sleep, so any disruption in your circadian rhythm can affect its natural ability to regulate and regenerate itself.',
  },
  options: [
    { label: 'Less than 7 hours', value: 'lt_7h' },
    { label: '7 hours or more', value: 'ge_7h' },
    { label: 'I work at night & sleep in the day', value: 'nightworker' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_health_sleep,
  getAnswers: ({ selected }) => ({ profile_health_sleep: selected }),
};

export default sleep;
