import { createSelector } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';
import first from 'lodash/fp/first';
import includes from 'lodash/fp/includes';
import reverse from 'lodash/fp/reverse';

import dayjs from 'dayjs';

import { orderStatuses } from 'Apps/Account/constants/orderStatuses';

import { productsCategories, productsSlugs } from 'constants/products';

// Get the orders state slice
export const getState = state => state.orders;

export const getOrders = createSelector(
  getState,
  state => /** @type {Array<import('./actions.js').Order>} */ (state.orders || [])
);
export const getIsLoading = createSelector(getState, state => state.isLoading || !state.orders);
export const getError = createSelector(getState, state => state.error);

const getLastOrder = first; // not a selector

export const getLastOrderAny = createSelector(getOrders, getLastOrder);

/** @example: const order = useSelector(state => getLastOrderByCategoryWithFallback(state, 'my-category')); */
export const getLastOrderByCategoryWithFallback = createSelector(
  getOrders,
  (_, productCategory) => productCategory,
  (orders, category) => {
    if ([productsCategories.HAIRCARE, productsCategories.SKINCARE].includes(category)) {
      return (
        getLastOrder(filter(order => includes(category, order?.formulaset?.categories))(orders)) ||
        getLastOrder(orders) // fallback behaviour
      );
    }
    return getLastOrder(orders);
  }
);

export const getOrderStatus = createSelector(
  (_state, { order } = {}) => order,
  order => {
    const { status } = order;
    return orderStatuses.includes(status) ? 'processing' : status;
  }
);

export const getLastOrderForFeedback = createSelector(getOrders, orders => {
  return orders.find(o => {
    return o.items.some(
      i =>
        i.variant.is_formula &&
        [
          productsSlugs.SHAMPOO,
          productsSlugs.SCALP_MASK,
          productsSlugs.HAIR_MASK,
          productsSlugs.CONDITIONER,
          productsSlugs.TRIAL_SHAMPOO,
          productsSlugs.TRIAL_CONDITIONER,
        ].includes(i.variant.product.type)
    );
  });
});

export const getFirstSupplementsOrder = createSelector(getOrders, orders => {
  const ordersSortedByAscendingDate = reverse(orders);
  let supplementsFirstOrderNumberOfMonthsSinceCreation = 0;

  // Loop through orders to find the oldest (first) supplements order and compute the number of months from today
  ordersSortedByAscendingDate.map(order => {
    if (order.items) {
      order.items.map(item => {
        if (
          !supplementsFirstOrderNumberOfMonthsSinceCreation &&
          item?.variant?.product?.type === productsSlugs.SUPPLEMENT_CORE &&
          item.created_at
        ) {
          supplementsFirstOrderNumberOfMonthsSinceCreation = dayjs().diff(item.created_at, 'month');
        }
        return item;
      });
    }
    return order;
  });

  return supplementsFirstOrderNumberOfMonthsSinceCreation;
});

export const hasOrderedSupplementsMultipleTimes = createSelector(getOrders, orders => {
  const supplementsOrders = orders.filter(o =>
    o.items.find(i => i.variant.product.type === productsSlugs.SUPPLEMENT_CORE)
  );
  return supplementsOrders.length > 1;
});

export const arePumpsCustomerPreferencesLoading = createSelector(
  getState,
  state => state.isLoading
);

const getPumpsCustomerPreferences = createSelector(
  getState,
  state => state.pumpsCustomerPreferences
);

export const getPumpsCustomerPreferencesByCategory = createSelector(
  getPumpsCustomerPreferences,
  (_state, { category } = {}) => category,
  (pumpsPreferences, category) => pumpsPreferences?.[`${category}_pumps`] ?? false
);
