import PropTypes from 'prop-types';

import { keyframes } from '@prose-ui/legacy';
import { withStyles } from 'legacyStyles';

const circularProgressKeyframes = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: -125px;
  }
`;

const styles = {
  root: {
    alignSelf: 'center',
    '& > svg': {
      display: 'block',
    },
    '& > svg > circle': {
      animation: `${circularProgressKeyframes} 1.4s ease-in-out infinite`,
      strokeDasharray: '80px, 200px',
      strokeDashoffset: '0px',
      stroke: 'currentColor',
    },
  },
};

const CircularProgress = ({ classes, size }) => {
  return (
    <div
      className={classes?.root}
      data-testid="circular-progress"
      role="progressbar"
      style={{ width: size, height: size }}
    >
      <svg viewBox="22 22 44 44">
        <circle cx="44" cy="44" fill="none" r="20.2" strokeWidth="3.6" />
      </svg>
    </div>
  );
};

CircularProgress.defaultProps = {
  size: 16,
  classes: null,
};

CircularProgress.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  size: PropTypes.number,
};

export default withStyles(CircularProgress, styles);
