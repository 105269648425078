import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import posterImageHaircare from 'assets/images/promotionalModals/trial-offer-toiletry-haircare.jpg';
import posterImageHaircareAndSkincare from 'assets/images/promotionalModals/trial-offer-toiletry-haircare-and-skincare.jpg';
import posterImageSkincare from 'assets/images/promotionalModals/trial-offer-toiletry-skincare.jpg';

const content = {
  contentDefault: {
    banner: 'Try Prose with 50% off + a free gift',
    popUpModal: {
      badge: (
        <>
          FREE
          <br />
          GIFT
        </>
      ),
      image: posterImageHaircareAndSkincare,
      headerTitle: 'labor day exclusive',
      title: '50% off + a free Toiletry Bag',
      description: 'Subscribe to 3+ hair or skin products to get your free gift',
      cta: {
        consultationCompleted: {
          linkTo: (route: string) => route || HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions:
        '*Get [1] free toiletry bag when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off. Offer is valid when your subscription order is placed by 09/02/2024 at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'HC + SC Pop-up - Closed',
        modalOpening: 'HC + SC Pop-up - Viewed',
        modalOpening2sec: 'HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'labor day exclusive',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag</b> when you subscribe to 3+ hair or skin products
        </>
      ),
      conditions:
        '*Get [1] free toiletry bag when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off. Offer is valid when your subscription order is placed by 09/02/2024 at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'labor day exclusive',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag</b> when you subscribe to 3+ hair or skin products
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Try custom haircare with 50% off + a free gift',
    popUpModal: {
      badge: (
        <>
          FREE
          <br />
          GIFT
        </>
      ),
      image: posterImageHaircare,
      headerTitle: 'labor day exclusive',
      title: '50% off + a free Toiletry Bag',
      description: 'Subscribe to 3+ hair products to get your free gift',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock the deal',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock the deal',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions:
        '*Get [1] free toiletry bag when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off. Offer is valid when your subscription order is placed by 09/02/2024 at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'HC Pop-up - Closed',
        modalOpening: 'HC Pop-up - Viewed',
        modalOpening2sec: 'HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'labor day exclusive',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag</b> when you subscribe to 3+ hair products
        </>
      ),
      conditions:
        '*Get [1] free toiletry bag when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off. Offer is valid when your subscription order is placed by 09/02/2024 at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'labor day exclusive',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag</b> when you subscribe to 3+ hair products
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Try custom skincare with 50% off + a free gift',
    popUpModal: {
      badge: (
        <>
          FREE
          <br />
          GIFT
        </>
      ),
      image: posterImageSkincare,
      headerTitle: 'labor day exclusive',
      title: '50% off + a free Toiletry Bag',
      description: 'Subscribe to 3+ skin products to get your free gift',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock the deal',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions:
        '*Get [1] free toiletry bag when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off. Offer is valid when your subscription order is placed by 09/02/2024 at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'SC Pop-up - Closed',
        modalOpening: 'SC Pop-up - Viewed',
        modalOpening2sec: 'SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'labor day exclusive',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag</b> when you subscribe to 3+ skin products
        </>
      ),
      conditions:
        '*Get [1] free toiletry bag when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off. Offer is valid when your subscription order is placed by 09/02/2024 at 11:59 PM EST or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'labor day exclusive',
      description: (
        <>
          Get <b>50% off + a free Toiletry Bag</b> when you subscribe to 3+ skin products
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
