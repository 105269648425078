import { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { Box, Modal, Text, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import {
  useGetTrialOfferCategory,
  useTrialOfferPromotionModalContent,
} from 'hooks/useRedeemCouponForTrialOffer';

import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { Image } from 'Components/Image';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';
import { productsCategories } from 'constants/products';

import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import { trackHeapEvent } from 'dux/tracking/actions';
import {
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
} from 'dux/user/selectors';

import { Button } from './LegacyButton';
import { Typography } from './Typography';
import { Video } from './Video';

const Header = styled.div`
  height: 35px;
  padding: 10px 10px 5px 17px;
  background-color: ${theme.colors.tertiary[200]};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const CloseModalButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`;

const StyledModal = styled(Modal)`
  padding: 0px;
  min-width: 330px;

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 550px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colors.neutral[200]};
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const BadgeLabel = styled(Typography)`
  font-size: 18px;
  text-align: center;
  padding-top: 5px;
`;

const Badge = styled.div`
  position: absolute;
  top: 27px;
  right: 27px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 76px;
  height: 76px;
  background-color: ${theme.colors.tertiary[200]};
  border-radius: 76px;
`;

const TextContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;

  ${legacyTheme.breakpoints.up('sm')} {
    padding: 40px;
  }
`;

const HeaderLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

const Title = styled(Typography)`
  font-size: 20px;
  max-width: 220px;

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 430px;
  }
`;

const Description = styled(Typography)`
  font-size: 14px;
  max-width: 300px;
  color: ${theme.colors.primary[400]};

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 430px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 280px;
`;

type TrialOfferPromotionModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const useGetCtaProps = (content: any) => {
  const { pathname } = useLocation();
  const category = useGetTrialOfferCategory();
  const hasCompletedHaircareConsultation = useAppSelector(getHasCompletedHaircareConsultation);
  const hasCompletedSkincareConsultation = useAppSelector(getHasCompletedSkincareConsultation);

  if (category === productsCategories.AGNOSTIC) {
    if (!hasCompletedHaircareConsultation && !hasCompletedSkincareConsultation) {
      return {
        ...content.popUpModal.cta.consultationNotCompleted,
        linkTo: '/consultation/category',
      };
    }
    if (hasCompletedHaircareConsultation && hasCompletedSkincareConsultation) {
      return {
        ...content.popUpModal.cta.consultationCompleted,
        linkTo: '/account/reorder',
      };
    }
    return {
      ...content.popUpModal.cta.consultationCompleted,
      linkTo: pathname,
    };
  }
  if (category === productsCategories.HAIRCARE) {
    if (hasCompletedHaircareConsultation) {
      return {
        ...content.popUpModal.cta.consultationCompleted,
        linkTo: HAIRCARE_CART_URL,
      };
    }
    return {
      ...content.popUpModal.cta.consultationNotCompleted,
      linkTo: FIRST_HAIRCARE_QUESTION_ROUTE,
    };
  }
  if (category === productsCategories.SKINCARE) {
    if (hasCompletedSkincareConsultation) {
      return {
        ...content.popUpModal.cta.consultationCompleted,
        linkTo: SKINCARE_CART_URL,
      };
    }
    return {
      ...content.popUpModal.cta.consultationNotCompleted,
      linkTo: FIRST_SKINCARE_QUESTION_ROUTE,
    };
  }
  return {};
};

const TrialOfferPromotionModal = ({ isOpen = false, onClose }: TrialOfferPromotionModalProps) => {
  const { content, variant } = useTrialOfferPromotionModalContent();
  const { linkTo, dataClick, dataFrom, ctaLabel } = useGetCtaProps(content);

  const dispatch = useAppDispatch();

  if (!content) return null;

  return (
    <StyledModal
      events={{
        onModalClose: () => dispatch(trackHeapEvent(content.popUpModal.tracking.modalClosing)),
        onModalOpen: () => dispatch(trackHeapEvent(content.popUpModal.tracking.modalOpening)),
        timer: {
          onModalOpenAfterTime: () =>
            dispatch(trackHeapEvent(content.popUpModal.tracking.modalOpening2sec)),
        },
      }}
      hideClose
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={10000}
    >
      <Header>
        <HeaderLabel medium variant="mono1">
          {content.popUpModal.headerTitle}
        </HeaderLabel>
        <CloseModalButton
          data-click={content.popUpModal.ctaExit.dataClick}
          data-from={content.popUpModal.ctaExit.dataFrom}
          data-testid="close-modal"
          id="modal-close-button"
          onClick={onClose}
        >
          <Cross height="13" width="13" />
        </CloseModalButton>
      </Header>
      <Content>
        {content.popUpModal.video && (
          <Video
            autoplay
            forcePause={false}
            fullHeight
            fullWidth
            loop
            muted
            posterImage={content.popUpModal.video.posterImage}
            posterImageAlt={content.popUpModal.video.posterImageAlt}
            videoSource={content.popUpModal.video.source}
          />
        )}
        {!content.popUpModal.video && (
          <>
            {content.popUpModal.badge && (
              <Badge>
                <BadgeLabel>{content.popUpModal.badge}</BadgeLabel>
              </Badge>
            )}
            <StyledImage
              alt={content.popUpModal.imageAlt ?? 'Trial offer'}
              src={content.popUpModal.image}
            />
          </>
        )}
        <TextContent>
          <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              gap={{ mobile: '2x', tablet: '3x', desktop: '3x' }}
            >
              <Title
                align="center"
                data-testid={`modal-trial-offer-title-${variant}`}
                id={isOpen ? 'modal-title' : ''}
                variant="h2"
              >
                {content.popUpModal.title}
              </Title>

              <Description align="center" id={isOpen ? 'modal-description' : ''} variant="p1">
                {content.popUpModal.description}
              </Description>
            </Box>
            <Box paddingY={{ mobile: '5x', tablet: '6x', desktop: '6x' }}>
              <StyledButton
                Component={Link}
                data-click={dataClick}
                data-from={dataFrom}
                data-testid={`modal-trial-offer-cta-${variant}`}
                noMargin
                onClick={onClose}
                to={linkTo}
                variant="vert"
              >
                {ctaLabel}
              </StyledButton>
            </Box>
          </Box>
          <Text
            align="center"
            color="neutral.900"
            id={isOpen ? 'modal-conditions' : ''}
            variant="bodySm"
          >
            {content.popUpModal.conditions}
          </Text>
        </TextContent>
      </Content>
    </StyledModal>
  );
};

type TrialOfferPromotionModalWithStateLogicProps = {
  isOpen: boolean;
};

export const TrialOfferPromotionModalWithStateLogic = ({
  isOpen,
}: TrialOfferPromotionModalWithStateLogicProps) => {
  const [open, setOpen] = useState(isOpen);
  const onClose = () => setOpen(false);

  return <TrialOfferPromotionModal isOpen={open} onClose={onClose} />;
};
