import NextImage, {
  getImageProps as getImagePropsFunction,
  type ImageProps as NextImageProps,
} from 'next/image';

export const Image = (props: ImageProps) => <NextImage {...props} />;

export const getImageProps = getImagePropsFunction;

export type StaticImageData = {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
  blurWidth?: number;
  blurHeight?: number;
};
export type ImageProps = NextImageProps;
