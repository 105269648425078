import { textRecipe } from './text.css';

import * as React from 'react';

import Box, { type BoxProps } from '../layout/Box';
import type { TextVariants } from './text.css';

export type TextProps = {
  children: React.ReactNode;
  color?: BoxProps['color'];
  as?: BoxProps['as'];
} & TextVariants &
  Omit<BoxProps, 'textAlign'>;

/**
 * Text component used for titles, body copy, and text labels.
 *
 * `<a>` tags inside this component will be underlined by default.
 *
 * This should be used with 2024 variants.
 * It also includes older variants (considered "legacy").
 * */
export const Text: React.FC<TextProps> = ({
  children,
  variant,
  color = 'primary.400',
  as = 'p',
  fontWeight,
  textAlign,
  fontStyle,
  ...props
}: TextProps) => {
  return (
    <Box
      as={as}
      className={textRecipe({ variant, fontWeight, textAlign, fontStyle })}
      color={color}
      margin="none"
      {...props}
    >
      {children}
    </Box>
  );
};

export default Text;
