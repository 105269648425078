import { createSelector } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import maxBy from 'lodash/fp/maxBy';
import pick from 'lodash/fp/pick';
import some from 'lodash/fp/some';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';

import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';

import { cartSectionName } from 'constants/cart';
import { couponLabelFromOrigin } from 'constants/coupons';
import currencies from 'constants/currencies';
import { productsCategories, productsSlugs, skincareMinisSlugs } from 'constants/products';
import * as statuses from 'constants/statuses';

import { productSlugToProductSkincare } from 'assets/content/skincareProducts';

import getOrNil from 'utils/getOrNil';
import isNotEmpty from 'utils/isNotEmpty';
import { createApiSelectors } from 'utils/reduxUtils';
import { removeAppendedVersion } from 'utils/removeAppendedVersion';
import { genitive } from 'utils/textUtils';

import { shouldShowTrialOffer } from 'dux/featureFlags/selectors';
import { getShowSkincareStarterSetPromo } from 'dux/promotions/selectors';
import * as userSelectors from 'dux/user/selectors';

const getCartSubState = get('checkout.cart');

export const [getCartData, getCartError, getCartStatus] = createApiSelectors(getCartSubState);

export const getItems = createSelector(getCartData, cart => (cart && cart.items) || []);
export const getCatalog = createSelector(getCartData, getOrNil([], 'catalog'));
export const getUpdateStatusIsLoading = createSelector(
  getCartSubState,
  state => state?.updateStatus === 'loading'
);

export const cartHasSubscription = createSelector(getItems, some(get('subscription.active')));

export const getCartCategory = createSelector(getCartData, get('origin'));

export const getSubscriptionedItems = createSelector(getItems, filter(get('subscription.active')));

// Someone is giving a gift
export const getCartGift = createSelector(
  getItems,
  flow(find({ category: productsCategories.GIFT }), getOrNil(null, productsCategories.GIFT))
);

// Someone is giving buying a brush as a gift
export const getCartBrushes = createSelector(
  getItems,
  filter({ category: productsCategories.ITEM, product: productsSlugs.BRUSH })
);

export const getCartHasBrushes = createSelector(getCartBrushes, isNotEmpty);

export const getIsGivingGift = createSelector(getCartGift, isNotEmpty);

export const getIsReceivingGift = createSelector(
  getItems,
  some({
    category: productsCategories.COUPON,
    origin: productsCategories.GIFT,
    type: 'free_products',
  })
);

// Used in shouldProposePumps selector and CartSummary component
export const getNeedPumps = createSelector(getCartData, getOrNil(false, 'need_pumps'));

export const getIsFirstOrder = createSelector(
  userSelectors.getPaidOrderWithPumpsCategories,
  (_state, { checkoutCategory } = {}) => checkoutCategory,
  (paidOrderWithPumpsCategories, checkoutCategory) =>
    !paidOrderWithPumpsCategories?.includes(checkoutCategory)
);

export const shouldProposePumps = createSelector(
  getNeedPumps,
  getIsGivingGift,
  userSelectors.getPaidOrderWithPumpsCategories,
  cartHasSubscription,
  (_state, { checkoutCategory }) => checkoutCategory,
  (needPumps, isGivingGift, paidOrderWithPumpsCategories, hasSubscription, checkoutCategory) =>
    hasSubscription
      ? needPumps && !isGivingGift
      : needPumps &&
        !isGivingGift &&
        (paidOrderWithPumpsCategories?.includes(checkoutCategory) ?? false)
);

export const shouldShowPumpsConfirmation = createSelector(
  getNeedPumps,
  getIsFirstOrder,
  cartHasSubscription,
  (needPumps, isFirstOrderWithPumps, hasSubscription) =>
    needPumps && (!isFirstOrderWithPumps || hasSubscription)
);

/**
 * The API updates the value to true when either:
 * - a customer purchases or receives a digital gift;
 * - a customer purchases a brush for someone else;
 * - a customer purchases a brush for himself and has an incomplete hair_profile.
 *
 * The front-end updates the value to true when the user confirms his cart.
 *
 * By default, the value is false, meaning the customer cannot access the checkout flow
 * (account-details, payment) without having a true value.
 */
export const getIsInCheckout = createSelector(getCartData, get('is_in_checkout'));

export const getIsSubscribeable = createSelector(getCartData, get('can_subscribe'));

export const getPaymentFromCustomer = createSelector(getCartData, get('payment_from_customer'));
const getCartTotalDiscount = createSelector(getCartData, get('total_discount'));

export const getCartNeedAddress = createSelector(getCartData, get('need_address'));
export const getCartShippingAddress = createSelector(getCartData, get('shipping_address'));
export const getCartCurrency = createSelector(getCartData, get('currency'));

export const getCartTotalAmount = createSelector(getCartData, get('total_amount'));
export const getCartTotalPrice = createSelector(getCartData, get('total_price'));
export const getCartTotalShipping = createSelector(getCartData, get('total_shipping'));

/**
 * The tax is computed automatically by the back-end after an address is added to the cart.
 * Some carts might not require an address, in that case we consider the tax as computed.
 */
export const getIsTaxComputed = createSelector(
  getCartNeedAddress,
  getCartShippingAddress,
  (needAddress, shippingAddress) => !(needAddress && !shippingAddress)
);

export const getIsFree = createSelector(getPaymentFromCustomer, total => total === 0);

export const getHasSkincareMinisInItems = createSelector(
  getItems,
  some(item => skincareMinisSlugs.includes(item.type))
);

export const getIsFreeAndCartHasNoSubscription = createSelector(
  getIsFree,
  cartHasSubscription,
  (isFree, hasSubscription) => isFree && !hasSubscription
);

/**
 * In Express Checkout mode, we don't need the shippingAddress filled by the user
 * since given by Apple Pay or Google Pay. We consider the tax as computed.
 */
export const isPayable = createSelector(
  (_state, { expressCheckout }) => expressCheckout,
  getIsFreeAndCartHasNoSubscription,
  getIsTaxComputed,
  (expressCheckout, isFreeAndCartHasNoSubscription, isTaxComputed) =>
    !isFreeAndCartHasNoSubscription && (expressCheckout || isTaxComputed)
);

export const getTotalOrderPaymentFromCustomer = createSelector(
  getPaymentFromCustomer,
  paymentFromCustomer => Math.round(100 * paymentFromCustomer)
);

export const getSubscriptionDiscount = createSelector(getCatalog, catalog =>
  catalog.reduce((acc, item) => item?.subscription?.discount || acc, 20)
);

export const getHasSkincareMinisInCatalog = createSelector(
  getCatalog,
  some(item => skincareMinisSlugs.includes(item.type))
);

export const getSkincareMinisProductsFromCatalog = createSelector(
  getCatalog,
  userSelectors.getUserFirstName,
  (catalog, userFirstname) =>
    flow(
      filter(item => skincareMinisSlugs.includes(item.type)),
      map(item => ({
        ...item,
        label: `${genitive(userFirstname)} ${productSlugToProductSkincare[item.type]?.label}`,
        image: {
          src: productSlugToProductSkincare[item.type].picture,
          alt: `${item.label} packaging`,
        },
      }))
    )(catalog)
);

export const getSkincareMinisRecommendedSectionFromCatalog = createSelector(
  getCatalog,
  catalog => find(item => skincareMinisSlugs.includes(item.type))(catalog)?.recommendation?.section
);

export const getTotalSubscriptionDiscount = createSelector(
  getCartTotalDiscount,
  getIsSubscribeable,
  (totalDiscount, isSubscribeable) => (isSubscribeable ? -totalDiscount : 0)
);

export const getSelectableProducts = createSelector(getCatalog, catalog =>
  catalog.filter(
    item =>
      item.recommendation &&
      [productsCategories.FORMULA, productsCategories.ITEM].includes(item.category)
  )
);

export const getPreselectedProducts = createSelector(
  getSelectableProducts,
  filter({ recommendation: { section: cartSectionName.PRESELECT } })
);

export const getFeaturedProducts = createSelector(
  getSelectableProducts,
  filter({ recommendation: { section: cartSectionName.FEATURED } })
);

export const getRecommendedProducts = createSelector(
  getSelectableProducts,
  filter({ recommendation: { section: cartSectionName.RECOMMENDED } })
);

export const getUpsaleProducts = createSelector(
  getSelectableProducts,
  filter({ recommendation: { section: cartSectionName.UPSALE } })
);

export const getOptionalProducts = createSelector(
  getSelectableProducts,
  filter(t => [cartSectionName.OPTIONAL, 'inadvisable'].includes(t.recommendation.section))
);

export const getPreviewProducts = createSelector(
  getSelectableProducts,
  filter({ recommendation: { section: 'preview' } })
);

export const getIsSaving = createSelector(getCartStatus, status => status === 'saving');

export const getIsDisabled = createSelector(
  getIsSaving,
  getItems,
  (isSaving, items) =>
    isSaving || !items.some(p => p.quantity > 0 && p.category !== productsCategories.COUPON)
);
export const getIsTrialKit = createSelector(getItems, some({ type: productsSlugs.TRIAL_SHAMPOO }));

/**
 * Extract dates for shipping estimate with maximum max value.
 *
 * cart = {
 *    shipping_estimate: {
 *      core: { min: '2019-12-23T12:15:11.713070-05:00', max: '2019-12-28T12:15:11.713070-05:00' },
 *    },
 * };
 */
export const getSortedDeliveryDates = createSelector(
  getCartData,
  flow(
    getOr({}, 'shipping_estimate'),
    values,
    maxBy(estimate => dayjs(new Date(estimate.max)).format('YYYYMMDD')),
    values,
    map(dateString => dayjs(new Date(dateString))),
    sortBy(date => date.format('YYYYMMDD')),
    map(date => date.format('M/D'))
  )
);

// CHORE: move hardcoded text to a content file
export const getDeliveryBy = createSelector(getSortedDeliveryDates, sortedDeliveryDates => {
  if (!sortedDeliveryDates || sortedDeliveryDates.length === 0) {
    return '';
  }
  if (sortedDeliveryDates.length === 1) {
    return `Arrives by ${first(sortedDeliveryDates)}`;
  }
  return `Arrives between ${first(sortedDeliveryDates)} and ${last(sortedDeliveryDates)}`;
});

const getHasPreselected = createSelector(getPreselectedProducts, isNotEmpty);

const getCartItems = createSelector(
  getCartData,
  flow(getOr([], 'items'), filter({ is_cart_visible: true }))
);

const getCartCoupons = createSelector(getCartData, cartData => cartData?.coupons ?? []);

const getCartGiftCards = createSelector(getCartData, cartData => cartData?.gift_cards ?? []);

export const getCartProducts = createSelector(getCartItems, items =>
  items.filter(
    item =>
      item.quantity > 0 &&
      [productsCategories.ITEM, productsCategories.FORMULA, productsCategories.GIFT].includes(
        item.category
      ) &&
      !item.type.includes('gwp')
  )
);

export const getCartProductsQuantity = createSelector(getCartProducts, products =>
  products.reduce((acc, { quantity }) => {
    return acc + quantity;
  }, 0)
);

export const getHasItemsFromGiftSet = createSelector(getCartItems, items =>
  items.some(
    item =>
      item.category === productsCategories.COUPON &&
      item.origin === productsCategories.GIFT &&
      item.type === 'free_products'
  )
);

// ---------------------------------------------------------
// SECTION TITLE & DESC. LOGIC
// ---------------------------------------------------------

// CHORE: move hardcoded text to a content file
const getMainTitle = createSelector(
  getHasPreselected,
  getIsReceivingGift,
  getIsTrialKit,
  userSelectors.getUserFirstName,
  (_state, { checkoutCategory } = {}) => checkoutCategory,
  (hasPreselected, isReceivingGift, isTrialKit, name, checkoutCategory) => {
    if (isTrialKit) {
      return `${genitive(name)} starter kit`;
    }
    if (isReceivingGift) {
      return `${genitive(name)} custom gift set`;
    }
    if (hasPreselected) {
      return 'Add your custom routine';
    }
    return `Your custom ${
      checkoutCategory === productsCategories.SKINCARE ? 'skin' : 'hair'
    } recommendations`;
  }
);

// CHORE: move hardcoded text to a content file
const getMainDescription = createSelector(
  getHasPreselected,
  getIsReceivingGift,
  getIsTrialKit,
  getIsSubscribeable,
  (hasPreselected, isReceivingGift, isTrialKit, canSubscribe) => {
    if (hasPreselected) {
      return '';
    }
    if (isTrialKit) {
      return "Based on your consultation results, here is the starter kit we've built for you. Each product has been formulated for your specific needs.";
    }
    if (isReceivingGift) {
      return 'Based on your consultation results, each product has been formulated for your specific needs.';
    }
    if (canSubscribe) {
      return 'Based on your consultation results, here are the custom products formulated for your specific needs. Update your subscription plan anytime or cancel with no hassle.';
    }
    return "Based on your consultation results, here are the custom products we've selected for you. Each product has been formulated for your specific needs.";
  }
);

// Products helpers
const productIsSupplements = product =>
  product.category === productsCategories.FORMULA && product.type === productsSlugs.SUPPLEMENT_CORE;

// Check if customer has hair goals relative to Supplements
const getConsultationAnswers = createSelector(getCartData, get('formulaset.survey'));

export const getConsultationProfileCountryInCartSurvey = createSelector(
  getCartData,
  get('formulaset.survey.profile_country')
);

export const getConsultationProfileZipcodenCartSurvey = createSelector(
  getCartData,
  get('formulaset.survey.profile_zipcode')
);

const getHairGoals = createSelector(
  getConsultationAnswers,
  pick(['goal_hair_growth', 'goal_hair_shedding'])
);
const shouldShowHairGrowthCopy = createSelector(
  getHairGoals,
  ({ goal_hair_growth, goal_hair_shedding }) =>
    ![null, 0].includes(goal_hair_growth) || ![null, 0].includes(goal_hair_shedding)
);

// CHORE: move hardcoded text to a content file
const getPreselectedSectionTitle = createSelector(() => 'Your selected products');

const getCartSectionsTitle = createSelector(getCartSubState, get('cartSectionTitle.data'));

const getUpsaleCartSectionTitle = createSelector(
  getCartSectionsTitle,
  sections => find({ section: 'upsale' })(sections)?.title
);

/**
 * CHORE: move hardcoded text to a content file
 * Ractory function to make it easier to use DI during tests
 */
export const getUpsaleSectionTitle = (
  state = {},
  {
    getUpsaleProductsDI = getUpsaleProducts,
    shouldShowHairGrowthCopyDI = shouldShowHairGrowthCopy,
  } = {}
) => {
  return createSelector(
    getUpsaleProductsDI,
    shouldShowHairGrowthCopyDI,
    getUpsaleCartSectionTitle,
    (products, showHairGrowthCopy, sectionTitle) => {
      const title =
        sectionTitle ||
        (showHairGrowthCopy && products.some(productIsSupplements)
          ? 'Grow your best hair'
          : 'Enhance your routine');

      return title;
    }
  )(state);
};

/**
 * CHORE: move hardcoded text to a content file
 * Factory function to make it easier to use DI during tests
 */
export const getUpsaleSectionDescription = (
  state = {},
  {
    getUpsaleProductsDI = getUpsaleProducts,
    shouldShowHairGrowthCopyDI = shouldShowHairGrowthCopy,
  } = {}
) => {
  return createSelector(
    getUpsaleProductsDI,
    shouldShowHairGrowthCopyDI,
    (products, showHairGrowthCopy) => {
      const description =
        showHairGrowthCopy && products.some(productIsSupplements)
          ? 'Get more out of your custom recommendations when you add on hair supplements.'
          : null;

      return description;
    }
  )(state);
};

// CHORE: move hardcoded text to a content file
const getFeaturedSectionTitle = createSelector(() => 'New & Noteworthy');

// CHORE: move hardcoded text to a content file
const getFeaturedSectionDescription = createSelector(getFeaturedProducts, products => {
  const description =
    (products.every(productIsSupplements) &&
      'Discover your Root Source® for longer, stronger, fuller hair*.') ||
    null;

  return description;
});

// CHORE: move hardcoded text to a content file
export const getSectionsContent = createSelector(
  getDeliveryBy,
  getPreselectedSectionTitle,
  getFeaturedSectionTitle,
  getFeaturedSectionDescription,
  getUpsaleSectionTitle,
  getUpsaleSectionDescription,
  getMainTitle,
  getMainDescription,
  getSkincareMinisRecommendedSectionFromCatalog,
  shouldShowTrialOffer,
  userSelectors.getHasSkincareSubscriptionInAnyState,
  getShowSkincareStarterSetPromo,
  (
    deliveryBy,
    preselectedTitle,
    featuredTitle,
    featuredDescription,
    upsaleTitle,
    upsaleDescription,
    mainTitle,
    mainDescription,
    skincareMinisRecommendedSectionFromCatalog,
    showTrialOffer,
    hasSkincareSubscriptionInAnyState,
    showSkincareStarterSetPromo
  ) => {
    const isEligibleToTrialOfferAndHasNoSkincareSubscription =
      showTrialOffer && !hasSkincareSubscriptionInAnyState;
    const isSkincareMinisRecommended =
      skincareMinisRecommendedSectionFromCatalog === cartSectionName.RECOMMENDED;
    const isEligibleToSkincareMinis = isSkincareMinisRecommended && showSkincareStarterSetPromo;
    return {
      preselected: {
        title: preselectedTitle,
        deliveryBy,
      },
      main: {
        title: mainTitle,
        description: mainDescription,
        deliveryBy,
      },
      featured: {
        title: featuredTitle,
        description: featuredDescription,
        deliveryBy,
      },
      upsale: {
        title: upsaleTitle,
        description: upsaleDescription,
        deliveryBy,
      },
      optional: {
        title: isSkincareMinisRecommended
          ? 'Get full size skincare'
          : 'Explore other custom products',
        description:
          isEligibleToTrialOfferAndHasNoSkincareSubscription && isEligibleToSkincareMinis
            ? 'Get 15% off + free shipping when you subscribe to any full-size skincare product.'
            : undefined,
        seeMore: 'See more',
        seeLess: 'See less',
      },
    };
  }
);

const getCoupons = createSelector(getCartCoupons, cartCoupons =>
  cartCoupons.map(({ origin, label, property, total_price }) => ({
    property,
    label: isEmpty(label) ? couponLabelFromOrigin(origin) : label,
    value: total_price,
  }))
);

export const getGiftCards = createSelector(getCartGiftCards, giftCards =>
  giftCards.map(({ origin, label, property, total_price }) => ({
    property,
    label: isEmpty(label) ? couponLabelFromOrigin(origin) : label,
    value: total_price,
  }))
);

export const getCartCreditCoupons = createSelector(
  getCoupons,

  coupons => coupons.filter(coupon => coupon.property === 'credit')
);

export const getCartOfferCoupons = createSelector(getCoupons, coupons =>
  coupons.filter(coupon => coupon.property === 'offer')
);

export const getCartSubscriptionCoupons = createSelector(getCoupons, coupons =>
  coupons.filter(coupon => coupon.property === 'subscription')
);

export const getGiftWithPurchaseListInCatalog = createSelector(
  getCatalog,
  filter({ category: 'gwp' })
);

export const getGWPsInCart = createSelector(
  getCartItems,
  filter(
    i =>
      i.category?.includes?.('gwp') ||
      i.type?.includes?.('gwp') ||
      i.variant?.product?.type?.includes?.('gwp')
  )
);

export const getGWPisUnlocked = createSelector(
  (_state, props) => props?.offeredVariantType,
  getGWPsInCart,
  (offeredVariantTypeFromCatalog, gwpsInCart) =>
    Boolean(
      find(gwpInCart => {
        return (
          removeAppendedVersion(gwpInCart?.offered_variant_type) === offeredVariantTypeFromCatalog
        );
      })(gwpsInCart)
    )
);

// ---------------------------------------------------------
// SECTION DISPLAY LOGIC
// ---------------------------------------------------------

export const getHasPreviewProducts = createSelector(getPreviewProducts, previewProducts => {
  return previewProducts?.length > 0;
});

const productIsStylingGel = product =>
  product.category === productsCategories.FORMULA && product.type === productsSlugs.STYLING_GEL;

export const arePreviewProductsInUpsale = createSelector(getUpsaleProducts, products =>
  products.some(product => productIsSupplements(product) || productIsStylingGel(product))
);

export const arePreviewProductsInFeatured = createSelector(getFeaturedProducts, products =>
  products.some(product => productIsSupplements(product) || productIsStylingGel(product))
);

export const arePreviewProductsInOptional = createSelector(getOptionalProducts, products =>
  products.some(product => productIsSupplements(product) || productIsStylingGel(product))
);

/* Display suppplements pouches or supplements jars. More accurate source of
truth than must_have_jars within user profile  because it is updated at the
same time as the cart */
const getSupplementsInCatalog = createSelector(
  getCatalog,
  find({ type: productsSlugs.SUPPLEMENT_CORE })
);

export const getMustHaveJarsInCart = createSelector(
  getSupplementsInCatalog,
  supplementsInCatalog => {
    if (!supplementsInCatalog) {
      return true;
    }
    const { slug: variant } = supplementsInCatalog;
    if (variant.endsWith('_pouch')) {
      return false;
    }
    return true;
  }
);

export const getCurrencyFromCart = createSelector(
  getCartData,
  getCartStatus,
  (cartData, cartStatus) => {
    if (cartData?.currency) {
      return cartData.currency;
    }
    if (cartStatus === statuses.SUCCESS) {
      Sentry.captureMessage('Currency not found in cart data');
    }
    return currencies.USD;
  }
);

export const getEnableSubscriptionAtCart = createSelector(
  userSelectors.getHasActiveHaircareSubscription,
  userSelectors.getHasActiveSkincareSubscription,
  (_state, { checkoutCategory } = {}) => checkoutCategory,
  (hasActiveHaircareSubscription, hasActiveSkincareSubscription, checkoutCategory) =>
    (!hasActiveHaircareSubscription && checkoutCategory === productsCategories.HAIRCARE) ||
    (!hasActiveSkincareSubscription && checkoutCategory === productsCategories.SKINCARE)
);

export const getCartItemsWithoutCoupons = createSelector(getCartItems, items =>
  items.filter(item => item.category !== productsCategories.COUPON)
);
