import type React from 'react';

import { theme } from '@prose-ui';
import { ClassNames, css, keyframes, legacyTheme } from '@prose-ui/legacy';

import CircularProgress from 'Components/CircularProgress';

const slideRightKeyframes = keyframes`
  0% { width: 100%; left: 0; }
  45% { width: 0; left: 100%; }
  50% { width: 0; left: 0; }
  100% { width: 100%; }
`;

const root = css`
  ${legacyTheme.typography.mono2}

  font-weight: 700;
  display: inline-block;
  position: relative;
  min-width: 215px;
  max-width: 100%;
  height: ${legacyTheme.props.buttonHeight};
  line-height: ${legacyTheme.props.buttonHeight};
  margin: 11px 0;
  padding: 0 16px;
  cursor: pointer;
  text-align: center;
  transition: background, color, font-size;
  transition-duration: 300ms;
  color: ${theme.colors.primary[400]};
  white-space: nowrap;
  width: fit-content;
  appearance: none;

  &:hover {
    color: ${theme.colors.primary[400]};
    background: ${theme.colors.accent[200]};
    border: 1px solid ${theme.colors.accent[200]};
  }

  &:visited {
    color: inherit;
  }
`;

const fixedStyle = css`
  z-index: 100;
  width: 100%;
  margin: 0;
  bottom: 0;
  left: 0;
  position: fixed;
`;

const loadingRoot = css`
  color: ${theme.colors.neutral[100]};
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
`;

const sorbet = css`
  color: ${theme.colors.primary[400]};
  background: ${theme.colors.accent[200]};
  border: 1px solid ${theme.colors.accent[200]};

  &:hover {
    color: ${theme.colors.primary[400]};
    background: ${theme.colors.accent[200]};
    border: 1px solid ${theme.colors.accent[200]};
  }
`;

const white = css`
  background: transparent;
  color: ${theme.colors.neutral[100]};
  border: 1px solid ${theme.colors.neutral[100]};

  &:visited {
    color: ${theme.colors.neutral[100]};
  }

  &:hover {
    color: ${theme.colors.primary[400]};
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.neutral[100]};
  }
`;

const whiteFull = css`
  background: ${theme.colors.neutral[100]};
  color: ${theme.colors.primary[400]};
  border: 1px solid ${theme.colors.neutral[100]};

  &:visited {
    color: ${theme.colors.primary[400]};
  }

  &:hover {
    color: ${theme.colors.primary[400]};
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.neutral[100]};
  }
`;

const noir = css`
  background: transparent;
  color: ${theme.colors.primary[400]};
  border: 1px solid ${theme.colors.primary[400]};

  &:visited {
    color: ${theme.colors.primary[400]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[400]};
    border: 1px solid ${theme.colors.primary[400]};
  }

  &.disabled {
    color: ${theme.colors.neutral[700]};
    background: transparent;
    border: 1px solid ${theme.colors.neutral[700]};

    &:hover {
      color: ${theme.colors.neutral[700]};
      background: transparent;
    }
  }
`;

const vertFull = css`
  background: ${theme.colors.primary[300]};
  color: ${theme.colors.neutral[100]};
  border: 1px solid ${theme.colors.primary[300]};

  &:visited {
    color: ${theme.colors.neutral[100]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[300]};
    border: 1px solid ${theme.colors.primary[300]};
  }
`;

const roundedBlack = css`
  background: ${theme.colors.primary[400]};
  color: ${theme.colors.neutral[100]};
  border-radius: 2rem;

  border: 1px solid ${theme.colors.primary[400]};

  &:visited {
    color: ${theme.colors.neutral[100]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[300]};
    border: 1px solid ${theme.colors.primary[300]};
  }
`;

const whiteBg = css`
  background: ${theme.colors.neutral[100]};
  color: ${theme.colors.primary[400]};
  border: 1px solid ${theme.colors.neutral[100]};

  &:visited {
    color: ${theme.colors.primary[400]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: transparent;
    border: 1px solid ${theme.colors.neutral[100]};
  }
`;

const whiteBgOutline = css`
  background: ${theme.colors.neutral[100]};
  color: ${theme.colors.neutral[400]};
  border: 1px solid ${theme.colors.neutral[100]};

  &:hover {
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.primary[400]};
    outline: none;
  }

  &:focus {
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.primary[400]};
    outline: none;
  }
`;

const whiteBgOutlineRadius = css`
  background: ${theme.colors.neutral[100]};
  color: ${theme.colors.primary[100]};
  border: 1px solid ${theme.colors.neutral[100]};
  border-radius: 30px;

  &:hover {
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.neutral[100]};
    outline: none;
  }

  &:focus {
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.primary[400]};
    outline: none;
  }
`;

const vert = css`
  background: ${theme.colors.primary[300]};
  color: ${theme.colors.neutral[100]};
  border: 1px solid ${theme.colors.primary[300]};

  &:visited {
    color: ${theme.colors.neutral[100]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[400]};
    border: 1px solid ${theme.colors.primary[400]};
  }
`;

const lime = css`
  background: ${theme.colors.highlight[200]};
  color: ${theme.colors.primary[400]};
  border: 1px solid ${theme.colors.highlight[200]};

  &:visited {
    color: ${theme.colors.primary[400]};
  }

  &:hover {
    color: ${theme.colors.primary[400]};
    background: ${theme.colors.neutral[100]};
    border: 1px solid ${theme.colors.highlight[200]};
  }

  &.disabled {
    color: ${theme.colors.primary[400]};
    opacity: 0.2;
    background: ${theme.colors.highlight[200]};
    border: 1px solid ${theme.colors.highlight[200]};

    &::after {
      opacity: 0.2;
      background: ${theme.colors.highlight[200]};
    }

    &:visited {
      color: ${theme.colors.primary[400]};
    }

    &:hover {
      color: ${theme.colors.primary[400]};
      opacity: 0.2;
      background: ${theme.colors.highlight[200]};
      border: 1px solid ${theme.colors.highlight[200]};
    }
  }
`;

const rouge = css`
  background: ${theme.colors.error[200]};
  color: ${theme.colors.neutral[100]};
  border: 1px solid ${theme.colors.error[200]};

  &:visited {
    color: ${theme.colors.neutral[100]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[400]};
    border: 1px solid ${theme.colors.primary[400]};
  }
`;

const rougeOutline = css`
  background: transparent;
  color: ${theme.colors.error[200]};
  border: 1px solid ${theme.colors.error[200]};

  &:visited {
    color: ${theme.colors.error[200]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[400]};
    border: 1px solid ${theme.colors.primary[400]};
  }
`;

const vertOutline = css`
  background: transparent;
  color: ${theme.colors.primary[300]};
  border: 1px solid ${theme.colors.primary[300]};

  &:visited {
    color: ${theme.colors.primary[300]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[300]};
    border: 1px solid ${theme.colors.primary[300]};
  }
`;

const yellow = css`
  background: ${theme.colors.supps[200]};
  color: ${theme.colors.primary[400]};
  border: none;

  &:visited {
    color: ${theme.colors.primary[400]};
  }

  &:hover {
    background: ${theme.colors.supps[200]};
    border: none;
  }
`;

const add = css`
  background: ${theme.colors.primary[300]};
  color: ${theme.colors.neutral[100]};
  border: 0 solid ${theme.colors.neutral[100]};
  min-width: inherit;
  height: 55px;
  line-height: 55px;
  margin: inherit;
  width: 117px;
  margin-bottom: 18px;

  &:visited {
    color: ${theme.colors.primary[400]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.primary[300]};
    border: 0 solid ${theme.colors.neutral[100]};
  }
`;

const disabledStyle = css`
  cursor: not-allowed;
  color: ${theme.colors.neutral[100]};
  background: ${theme.colors.neutral[700]};
  border: 1px solid ${theme.colors.neutral[700]};

  &:visited {
    color: ${theme.colors.neutral[100]};
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: ${theme.colors.neutral[700]};
    border: 1px solid ${theme.colors.neutral[700]};
  }
`;

const underlined = css`
  background: none;
  line-height: 18px;
  height: inherit;
  text-transform: uppercase;
  position: relative;
  border: inherit;
  min-width: auto;
  padding: 0;

  &::after {
    content: '';

    position: absolute;
    bottom: -5px;
    left: 0%;

    display: block;

    width: 100%;
    height: 2px;

    background: ${theme.colors.accent[200]};

    transition: all 0.3s ease-out;
  }

  &:hover {
    background: inherit;
    border: inherit;

    &::after {
      animation: ${slideRightKeyframes} 0.6s;
    }
  }

  &.disabled {
    color: ${theme.colors.neutral[700]};

    &::after {
      background: ${theme.colors.neutral[700]};
    }
  }
`;

const underlinedBlack = css`
  background: none;
  line-height: 18px;
  height: inherit;
  text-transform: uppercase;
  position: relative;
  border: inherit;
  min-width: auto;
  padding: 0;

  &::after {
    content: '';

    position: absolute;
    left: 0%;

    display: block;

    width: 100%;
    height: 2px;

    background: ${theme.colors.primary[400]};

    transition: all 0.3s ease-out;
  }

  &:hover {
    background: transparent;
    border: inherit;

    &::after {
      animation: ${slideRightKeyframes} 0.6s;
    }

    &.disabled {
      color: ${theme.colors.neutral[700]};

      &::after {
        background: ${theme.colors.neutral[700]};
      }
    }
  }
`;

const underlinedBlackMono = css`
  font-family: ${theme.typography.fontFamily.label};
  font-size: ${theme.typography.fontSize.sm};
  font-weight: ${theme.typography.fontWeight.normal};
  line-height: 0.5;
  background: none;
  height: inherit;
  text-transform: uppercase;
  position: relative;
  border: inherit;
  min-width: auto;
  padding: 0;

  &::after {
    content: '';

    position: absolute;
    left: 0%;

    display: block;

    width: 100%;
    height: 1px;

    background: ${theme.colors.primary[400]};

    transition: all 0.3s ease-out;
  }

  &:hover {
    background: transparent;
    border: inherit;

    &::after {
      animation: ${slideRightKeyframes} 0.6s;
    }

    &.disabled {
      color: ${theme.colors.neutral[700]};

      &::after {
        background: ${theme.colors.neutral[700]};
      }
    }
  }
`;

const underlinedWhite = css`
  background: none;
  line-height: 18px;
  height: inherit;
  text-transform: uppercase;
  position: relative;
  border: inherit;
  min-width: auto;
  padding: 0;
  color: ${theme.colors.neutral[100]};

  &::after {
    content: '';

    position: absolute;
    left: 0%;

    display: block;

    width: 100%;
    height: 2px;

    background: ${theme.colors.neutral[100]};

    transition: all 0.3s ease-out;
  }

  &:hover {
    color: ${theme.colors.neutral[100]};
    background: transparent;
    border: inherit;

    &::after {
      animation: ${slideRightKeyframes} 0.6s;
    }
  }

  &.disabled {
    color: ${theme.colors.neutral[700]};

    &::after {
      background: ${theme.colors.neutral[700]};
    }
  }
`;

const tabIsActive = css`
  background: none;
  line-height: 18px;
  height: inherit;
  text-transform: uppercase;
  position: relative;
  border: inherit;
  min-width: auto;
  padding: 0;

  &::after {
    content: '';

    position: absolute;
    left: 0%;

    display: block;

    width: 100%;
    height: 2px;

    background: ${theme.colors.primary[400]};

    transition: all 0.3s ease-out;
  }

  &:hover {
    background: transparent;
    border: inherit;

    &::after {
      animation: ${slideRightKeyframes} 0.6s;
    }

    &.disabled {
      color: ${theme.colors.neutral[700]};

      &::after {
        background: ${theme.colors.neutral[700]};
      }
    }
  }
`;

const tab = css`
  background: none;
  line-height: 18px;
  height: inherit;
  text-transform: uppercase;
  position: relative;
  border: inherit;
  min-width: auto;
  padding: 0;

  &::after {
    position: absolute;
    left: 0%;

    display: block;

    width: 100%;
    height: 2px;

    background: ${theme.colors.primary[400]};

    transition: all 0.3s ease-out;
  }

  &:hover {
    background: transparent;
    border: inherit;

    &::after {
      content: '';
      animation: ${slideRightKeyframes} 0.6s;
    }

    &.disabled {
      color: ${theme.colors.neutral[700]};

      &::after {
        display: none;
      }
    }
  }
`;

const variantMapping = {
  roundedBlack,
  sorbet,
  white,
  whiteFull,
  noir,
  vertFull,
  whiteBg,
  whiteBgOutline,
  whiteBgOutlineRadius,
  vert,
  lime,
  rouge,
  rougeOutline,
  vertOutline,
  yellow,
  add,
  underlined,
  underlinedBlack,
  underlinedWhite,
  underlinedBlackMono,
  tabIsActive,
  tab,
};

const fullWidthStyle = css`
  width: 100%;
`;

const marginTopStyle = css`
  margin-top: ${legacyTheme.spacing.s40};
`;

const noMarginStyle = css`
  margin: 0;
`;

const boxShadow = css`
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
`;

export const buttonVariants = /** @type {const} */ [
  'roundedBlack',
  'sorbet',
  'white',
  'whiteFull',
  'noir',
  'vertFull',
  'whiteBg',
  'whiteBgOutline',
  'whiteBgOutlineRadius',
  'vert',
  'lime',
  'rouge',
  'rougeOutline',
  'vertOutline',
  'yellow',
  'add',
  'underlined',
  'underlinedBlack',
  'underlinedWhite',
  'underlinedBlackMono',
  'tabIsActive',
  'tab',
  'none',
];

const filledVariants = buttonVariants.slice(0, 16);
const textVariants = buttonVariants.slice(16);

export type ButtonProps<C extends React.ElementType = React.ElementType> = {
  /**
   * Change the inner html tag used to represent the component.
   */
  Component?: C;

  /**
   * Enables to overrides the element inner styles.
   * Use with caution.
   */
  classes?: { [key: string]: string };
  /**
   * Adds supplementary styles on the element.
   * Use with caution.
   */
  className?: string;

  children: React.ReactNode;
  /**
   * The element will be disabled.
   */
  disabled?: boolean;
  /**
   * The element be postionned at the bottom of the viewport and will
   * occupy 100vw.
   */
  fixed?: boolean;
  /**
   * The element will have width 100%.
   */
  fullWidth?: boolean;
  /**
   * The element will display a loading spinner.
   */
  isLoading?: boolean;
  /**
   * Adds a margin a the top of the element.
   */
  marginTop?: boolean;
  /**
   * Removes all margin on the element.
   */
  noMargin?: boolean;
  /**
   * Changes the element appearance.
   */
  variant?:
    | 'roundedBlack'
    | 'sorbet'
    | 'white'
    | 'whiteFull'
    | 'noir'
    | 'vertFull'
    | 'whiteBg'
    | 'whiteBgOutline'
    | 'whiteBgOutlineRadius'
    | 'vert'
    | 'lime'
    | 'rouge'
    | 'rougeOutline'
    | 'vertOutline'
    | 'yellow'
    | 'add'
    | 'underlined'
    | 'underlinedBlack'
    | 'underlinedWhite'
    | 'underlinedBlackMono'
    | 'tabIsActive'
    | 'tab'
    | 'none';
  /**
   * Adds a box shadow to the bottom of the button.
   */
  hasBoxShadow?: boolean;
  /**
   * Triggers a callback when the element is clicked.
   */
  onClick?: () => void;
  style?: React.CSSProperties;
} & React.ComponentPropsWithoutRef<C>;

/**
 * @deprecated Please use Button instead.
 */
export const Button = <C extends React.ElementType = 'button'>({
  Component,
  className,
  children,
  disabled = false,
  fixed = false,
  fullWidth = false,
  isLoading = false,
  marginTop = false,
  noMargin = false,
  onClick,
  variant = 'sorbet',
  hasBoxShadow = false,
  classes,
  ...props
}: ButtonProps<C>) => {
  const Comp = Component ?? 'button';
  return (
    <ClassNames>
      {({ cx, css }) => (
        <Comp
          className={cx(
            css(root),
            classes?.root,
            variant !== 'none' && filledVariants.includes(variant) && css(variantMapping[variant]),
            filledVariants.includes(variant) && classes?.[variant],
            { [css(fixedStyle)]: fixed },
            { [css(disabledStyle)]: disabled },
            disabled && 'disabled',
            variant !== 'none' && textVariants.includes(variant) && css(variantMapping[variant]),
            textVariants.includes(variant) && classes?.[variant],
            { [css(fullWidthStyle)]: fullWidth },
            { [css(marginTopStyle)]: marginTop },
            { [css(noMarginStyle)]: noMargin },
            { [css(boxShadow)]: hasBoxShadow },
            className,
          )}
          disabled={disabled}
          onClick={onClick}
          {...props}
        >
          {isLoading ? <CircularProgress classes={{ root: css(loadingRoot) }} /> : children}
        </Comp>
      )}
    </ClassNames>
  );
};
