import { useEffect } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import { useAppSelector } from 'dux/app/hooks';

import * as TagSegmentService from 'Services/TagSegmentService';

import { usePostPurchasePopupAbTest } from 'abTesting/postPurchasePopup/hook';

import { productsCategories } from 'constants/products';
import { haircareRoutes, skincareRoutes } from 'constants/routes';

import trialOfferCollapsibleWaterBottleContent from 'assets/content/trialOfferCollapsibleWaterBottleGwpPromotionModal';
import trialOfferGwpDecemberContent from 'assets/content/trialOfferCombGwpPromotionModal';
import trialOfferGwpHeatlessCurlSetBlackFridaySetContent from 'assets/content/trialOfferHeatlessCurlerSetBlackFridayGwpPromotionModal';
import trialOfferGwpHeatlessCurlSetContent from 'assets/content/trialOfferHeatlessCurlerSetGwpPromotionModal';
import trialOfferPetitToteGwpContent from 'assets/content/trialOfferPetitToteGwpPromotionModal';
import trialOfferContent from 'assets/content/trialOfferPromotionModal';
import trialOfferGwpSummerSaleContent from 'assets/content/trialOfferSummerSaleGwpPromotionModal';
import trialOfferToiletryBagGwpPromotionContent from 'assets/content/trialOfferToiletryBagGwpPromotionModal';
import trialOfferGwpToiletry from 'assets/content/trialOfferToiletryGWPPromotionModal';
import trialOfferGwpTravelPouchesContent from 'assets/content/trialOfferTravelPouchesGwpPromotionModal';
import trialOfferToiletryAndCombGwpPromotionContent from 'assets/content/trialOfferTravelToiletryBagAndCombGwpPromotionModal';
import trialOfferWideToothCombGwpPromotionContent from 'assets/content/trialOfferWideToothCombGwpPromotionModal';

import {
  getTrialOfferFlagVariant,
  isFlagsDoneFetching as getIsFlagsDoneFetching,
  shouldShowTrialOffer,
  trialOfferCollapsibleWaterBottleGwp,
  trialOfferCombGwp,
  trialOfferEvergreen,
  trialOfferGwpSummerSale,
  trialOfferHeatlessCurlSetBlackFridaySetGwp,
  trialOfferHeatlessCurlSetGwp,
  trialOfferPetitToteGwp,
  trialOfferToiletryAndCombGwp,
  trialOfferToiletryBagGwp,
  trialOfferToiletryGwp,
  trialOfferTravelPouchesGwp,
  trialOfferWideToothCombGwp,
} from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';
import * as flagVariants from 'dux/featureFlags/constants/flagVariants';

const trialOfferVariantContent = {
  [trialOfferEvergreen]: trialOfferContent,
  [trialOfferCombGwp]: trialOfferGwpDecemberContent,
  [trialOfferToiletryGwp]: trialOfferGwpToiletry,
  [trialOfferTravelPouchesGwp]: trialOfferGwpTravelPouchesContent,
  [trialOfferToiletryAndCombGwp]: trialOfferToiletryAndCombGwpPromotionContent,
  [trialOfferGwpSummerSale]: trialOfferGwpSummerSaleContent,
  [trialOfferHeatlessCurlSetBlackFridaySetGwp]: trialOfferGwpHeatlessCurlSetBlackFridaySetContent,
  [trialOfferHeatlessCurlSetGwp]: trialOfferGwpHeatlessCurlSetContent,
  [trialOfferCollapsibleWaterBottleGwp]: trialOfferCollapsibleWaterBottleContent,
  [trialOfferPetitToteGwp]: trialOfferPetitToteGwpContent,
  [trialOfferWideToothCombGwp]: trialOfferWideToothCombGwpPromotionContent,
  [trialOfferToiletryBagGwp]: trialOfferToiletryBagGwpPromotionContent,
};

export const useGetTrialOfferCategory = () => {
  const { pathname } = useLocation();
  const hasHaircareSubscription = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const hasSkincareSubscription = useAppSelector(getHasSkincareSubscriptionInAnyState);

  const isHaircareRoute = haircareRoutes.some(url => matchPath(url, pathname));
  const isSkincareRoute = skincareRoutes.some(url => matchPath(url, pathname));

  const determineProductCategory = (
    currentRouteSubscription,
    alternateRouteSubscription,
    currentRouteCategory,
    alternateRouteCategory
  ) => {
    if (!currentRouteSubscription && !alternateRouteSubscription) return currentRouteCategory;
    if (!currentRouteSubscription && alternateRouteSubscription) return currentRouteCategory;
    if (currentRouteSubscription && !alternateRouteSubscription) return alternateRouteCategory;
    return null;
  };

  if (isHaircareRoute) {
    return determineProductCategory(
      hasHaircareSubscription,
      hasSkincareSubscription,
      productsCategories.HAIRCARE,
      productsCategories.SKINCARE
    );
  }

  if (isSkincareRoute) {
    return determineProductCategory(
      hasSkincareSubscription,
      hasHaircareSubscription,
      productsCategories.SKINCARE,
      productsCategories.HAIRCARE
    );
  }

  if (!hasHaircareSubscription && !hasSkincareSubscription) {
    return productsCategories.AGNOSTIC;
  }
  if (!hasHaircareSubscription && hasSkincareSubscription) {
    return productsCategories.HAIRCARE;
  }
  if (hasHaircareSubscription && !hasSkincareSubscription) {
    return productsCategories.SKINCARE;
  }
  return null;
};

export const useTrialOfferPromotionModalContent = () => {
  const variant = useAppSelector(getTrialOfferFlagVariant);
  const category = useGetTrialOfferCategory();
  const { pathname } = useLocation();

  const { variantName: postPurchasePopupVariant } = usePostPurchasePopupAbTest({
    isTrackingOnMount: true,
  });

  const content =
    postPurchasePopupVariant === flagVariants.CONTROL && matchPath('/checkout/success', pathname)
      ? trialOfferContent
      : trialOfferVariantContent[variant];

  return {
    content:
      category === productsCategories.AGNOSTIC
        ? content?.contentDefault
        : category === productsCategories.SKINCARE
        ? content?.contentSkincare
        : category === productsCategories.HAIRCARE
        ? content?.contentHaircare
        : null,
    variant,
  };
};

export const useRedeemCouponForTrialOffer = () => {
  const showTrialOffer = useAppSelector(shouldShowTrialOffer);
  const isFlagsDoneFetching = useAppSelector(getIsFlagsDoneFetching);

  useEffect(() => {
    if (showTrialOffer && isFlagsDoneFetching) {
      TagSegmentService.post('onsite_trial_offer_50');
    }
  }, [showTrialOffer, isFlagsDoneFetching]);
};
