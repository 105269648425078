import { useLayoutEffect, useState } from 'react';

const useHeight = ref => {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (!height) {
      setHeight(ref.current?.offsetHeight ?? 0);
    }
    function onResize() {
      setHeight(ref.current?.offsetHeight ?? 0);
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref, height]);
  return height;
};

export default useHeight;
