import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAppDispatch as useDispatch } from 'dux/app/hooks';

import { Button } from 'Components/LegacyButton';
import Title from 'Components/Title';

import { trackHeapEvent } from 'dux/tracking/actions';

import BaseScene from './BaseScene';

const reload = () => window.location.reload();

const Error = ({ onClick, title, cta }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trackHeapEvent('Consultation ErrorScene Viewed', { errorMessage: title }));
  }, []);
  return (
    <BaseScene>
      <Title>{title}</Title>
      <Button data-testid="error-button" onClick={onClick} variant="underlined">
        {cta}
      </Button>
    </BaseScene>
  );
};

Error.defaultProps = {
  cta: "Let's try again",
  onClick: reload,
  title: 'Oops, something went wrong...',
};

Error.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  cta: PropTypes.string,
};

export default Error;
