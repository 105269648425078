import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import imageHC from 'assets/images/promotionalModals/trial-offer-comb-gwp-promotion-hc-modal.jpg';
import imageSC from 'assets/images/promotionalModals/trial-offer-comb-gwp-promotion-sc-modal.jpg';

const content = {
  contentDefault: {
    banner: 'Try Prose with 50% off + a free gift',
    popUpModal: {
      image: imageHC,
      headerTitle: 'the anniversary event',
      title: 'Try Prose with 50% off + a free comb',
      description: 'Subscribe to 3+ hair or skin products to get your free gift.',
      cta: {
        consultationCompleted: {
          linkTo: (route: string) => route || HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions: (
        <>
          *Get 1 free Prose comb when you subscribe to 3+ products in the same category (Haircare or
          Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 10/27/2024 at 11:59 PM EST
          or while supplies last. Gift with purchase selection randomized. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'the anniversary event',
      description: (
        <>
          Get <b>50% off + a free Prose comb</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      conditions: (
        <>
          *Get 1 free Prose comb when you subscribe to 3+ products in the same category (Haircare or
          Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 10/27/2024 at 11:59 PM EST
          or while supplies last. Gift with purchase selection randomized. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'the anniversary event',
      description: (
        <>
          Get <b>50% off + a free Prose comb</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Try custom haircare with 50% off + a free gift',
    popUpModal: {
      image: imageHC,
      headerTitle: 'the anniversary event',
      title: 'Try Prose with 50% off + a free comb',
      description: 'Subscribe to 3+ haircare products to get your free gift.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions: (
        <>
          *Get 1 free Prose comb when you subscribe to 3+ products in the same category (Haircare or
          Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 10/27/2024 at 11:59 PM EST
          or while supplies last. Gift with purchase selection randomized. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'the anniversary event',
      description: (
        <>
          Get <b>50% off + a free Prose comb</b> when you subscribe to 3+ haircare products.
        </>
      ),
      conditions: (
        <>
          *Get 1 free Prose comb when you subscribe to 3+ products in the same category (Haircare or
          Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 10/27/2024 at 11:59 PM EST
          or while supplies last. Gift with purchase selection randomized. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'the anniversary event',
      description: (
        <>
          Get <b>50% off + a free Prose comb</b> when you subscribe to 3+ haircare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Try custom skincare with 50% off + a free gift',
    popUpModal: {
      image: imageSC,
      headerTitle: 'the anniversary event',
      title: 'Try Prose with 50% off + a free comb',
      description: 'Subscribe to 3+ skincare products to get your free gift.',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock your offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions: (
        <>
          *Get 1 free Prose comb when you subscribe to 3+ products in the same category (Haircare or
          Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 10/27/2024 at 11:59 PM EST
          or while supplies last. Gift with purchase selection randomized. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'the anniversary event',
      description: (
        <>
          Get <b>50% off + a free Prose comb</b> when you subscribe to 3+ skincare products.
        </>
      ),
      conditions: (
        <>
          *Get 1 free Prose comb when you subscribe to 3+ products in the same category (Haircare or
          Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 10/27/2024 at 11:59 PM EST
          or while supplies last. Gift with purchase selection randomized. Cannot be applied to
          previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
          For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'the anniversary event',
      description: (
        <>
          Get <b>50% off + a free Prose comb</b> when you subscribe to 3+ skincare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
