import type { SubscriptionUXCategory } from 'constants/subscriptions';

export const productCategoriesToSubscriptionCategory = ({
  category,
  sub_category,
}: {
  category: 'haircare' | 'skincare';
  sub_category: 'topicals' | 'supplements';
}): SubscriptionUXCategory => {
  if (sub_category === 'supplements') return 'supplements';

  return category;
};
