import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

import { ReactComponent as SunsetIcon } from 'assets/images/skincare/skintypeAfternoon.svg';

const skintypeAfternoon = {
  name: 'skintype_afternoon',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/skintype-afternoon`,
  title: 'How does your skin feel at the end of the day?',
  icon: <SunsetIcon />,
  subtitle:
    'Answer this based on your skin in its natural state, before you’ve washed your face or applied any nighttime products.',
  tip: {
    title: 'Why we ask:',
    content:
      'At the end of the day, your skin has been impacted by sun exposure, pollution, blue light, and more. How your skin reacts tells us a lot about how dry or oily it is, and helps us tailor your formulas to you.',
  },
  options: [
    {
      value: 'tights',
      label: 'On the dry side',
      description: 'Face may feel tight or appear matte',
    },
    {
      value: 'balanced',
      label: 'Balanced',
      description: 'Face feels comfortable and relatively hydrated',
    },
    {
      value: 'oily',
      label: 'On the oily side',
      description: 'Face may feel slick or appear shiny',
    },
    {
      value: 'depends_on_area',
      label: 'Mixed',
      description: 'Some areas of the face are a little oily, while others feel dry or tight',
    },
    {
      value: 'not_sure',
      label: 'Not sure / I don’t know',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_skintype_afternoon,
  getAnswers: ({ selected }) => ({
    diag_skintype_afternoon: selected,
  }),
};

export default skintypeAfternoon;
