import { useLocation, useMatch } from 'react-router-dom';

import { LegacyBaseScene as CommonBaseScene } from 'Scenes/LegacyBaseScene';

// ConsultationBaseScene: a common wrapper for consultation Scenes

const ConsultationBaseScene = ({ children, noSurroundingStyle, ...props }) => {
  const location = useLocation();
  const isHaircareScoringPage = useMatch(
    '/consultation/haircare/results/scoring',
    location.pathname
  );
  const isSkincareScoringPage = useMatch(
    '/consultation/skincare/results/scoring',
    location.pathname
  );
  const isScoringPage = !noSurroundingStyle && (isHaircareScoringPage || isSkincareScoringPage);

  return (
    <CommonBaseScene
      fixedCTA
      FooterProps={{ hideCTA: true }}
      header
      HeaderProps={{ hideNavbarCTA: true }}
      relativePosition
      {...props}
    >
      {!isScoringPage ? children : <CommonBaseScene.Body>{children}</CommonBaseScene.Body>}
    </CommonBaseScene>
  );
};

ConsultationBaseScene.propTypes = CommonBaseScene.propTypes;

ConsultationBaseScene.defaultProps = {
  background: 'beige',
  justifyContent: 'space-between',
  padding: true,
  noSurroundingStyle: false,
};

export default ConsultationBaseScene;
