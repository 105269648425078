import { Children, isValidElement } from 'react';

import { theme } from '@prose-ui';
import { ClassNames, css, legacyTheme } from '@prose-ui/legacy';

import { Footer, type FooterProps } from 'Blocks/Footer';

import { Header, type HeaderProps } from 'Containers/Header';

// BaseScene: a common wrapper all scenes

const contentStyles = css`
  --beige-bg: ${theme.colors.neutral[200]};
  --noir-bg: ${theme.colors.primary[400]};
  --earth-bg: ${theme.colors.neutral[500]};
  --none-bg: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: var(--justify-content);
  min-height: calc(100vh - ${legacyTheme.props.navbarHeight});
  background: var(--background, inherit);
  ${legacyTheme.breakpoints.match('sm')} {
    min-height: ${legacyTheme.props.fullHeight});
  }
`;
const contentBannerStyles = css`
  /* hidden banner, show only navbar */
  min-height: calc(100vh - ${legacyTheme.props.headerHeight});
`;
const displayBlockStyles = css`
  display: block;
`;
const relativePositionStyles = css`
  position: relative;
`;

const feedbackPaddingStyles = css`
  /**
   * Mobile
   * 12px = padding above the Review&Refine tag
   * 24px = height of the Review&Refine tag
   * 9px = padding below the Review&Refine tag
   */
  min-height: calc(100vh - ${legacyTheme.props.headerHeight});
  padding-top: calc(${legacyTheme.spacing.s12} + 40px + ${legacyTheme.spacing.s12}) !important;
  ${legacyTheme.breakpoints.up('sm')} {
    padding-top: calc(${legacyTheme.spacing.s24} + 24px + ${legacyTheme.spacing.s32}) !important;
  }
`;
const paddingStyles = css`
  padding: ${legacyTheme.spacing.s32} ${legacyTheme.spacing.s16};
  ${legacyTheme.breakpoints.up('sm')} {
    padding: ${legacyTheme.spacing.s40};
  }
`;
const contentFixedCTAStyles = css`
  padding-bottom: 70px;
  ${legacyTheme.breakpoints.up('sm')} {
    padding-bottom: 48px;
  }
`;
const contentFixedTipAndCTAStyles = css`
  padding-bottom: 120px;
  ${legacyTheme.breakpoints.up('sm')} {
    padding-bottom: 140px;
  }
`;

type BodyProps = { children: React.ReactNode };
const Body = ({ children }: BodyProps) => children;

type BaseSceneOwnProps = {
  background?: 'beige' | 'noir' | 'none' | 'earth';
  banner?: boolean;
  children: React.ReactNode;
  classes?: { content?: string };
  className?: string;
  display?: 'flex' | 'block';
  feedbackPadding?: boolean;
  fixedCTA?: boolean;
  fixedTipAndCTA?: boolean;
  footer?: boolean;
  FooterProps?: FooterProps;
  header?: boolean;
  HeaderProps?: HeaderProps;
  justifyContent?: 'center' | 'inherit' | 'normal' | 'space-between' | 'start';
  padding?: boolean;
  relativePosition?: boolean;
};

// INFO: Make sure BaseScene props aren't passed to its underliying html element
type BaseSceneProps = BaseSceneOwnProps &
  Omit<React.ComponentPropsWithoutRef<'main'>, keyof BaseSceneOwnProps>;

export const LegacyBaseScene = ({
  background = 'none',
  banner = true,
  children,
  classes,
  className,
  display = 'flex',
  header = false,
  HeaderProps,
  feedbackPadding = false,
  fixedCTA = false,
  fixedTipAndCTA = false,
  footer = false,
  FooterProps,
  justifyContent = 'center',
  padding = false,
  relativePosition = false,
  ...props
}: BaseSceneProps) => {
  // vue-like slots
  const body = Children.toArray(children).find(
    (el) => isValidElement<BodyProps>(el) && el.type === Body,
  );

  return (
    <main {...props}>
      {header && <Header banner={banner} showFooterLink={footer} {...HeaderProps} />}
      {isValidElement<BodyProps>(body) ? (
        body.props.children
      ) : (
        <ClassNames>
          {({ cx, css }) => (
            <section
              aria-atomic="true"
              aria-live="assertive"
              className={cx(
                css(contentStyles),
                classes?.content,
                banner && css(contentBannerStyles),
                display === 'block' && css(displayBlockStyles),
                padding && css(paddingStyles),
                fixedCTA && css(contentFixedCTAStyles),
                fixedTipAndCTA && css(contentFixedTipAndCTAStyles),
                feedbackPadding && css(feedbackPaddingStyles),
                relativePosition && css(relativePositionStyles),
                className,
              )}
              data-testid="maincontent"
              id="maincontent"
              style={{
                '--justify-content': justifyContent,
                '--background': `var(--${background}-bg)`,
              }}
            >
              {children}
            </section>
          )}
        </ClassNames>
      )}

      {footer && <Footer {...FooterProps} />}
    </main>
  );
};

LegacyBaseScene.Body = Body;
