import blemishesAfter from 'assets/images/landingPages/skincare/ba-blemishes-after.jpg';
import blemishesBefore from 'assets/images/landingPages/skincare/ba-blemishes-before.jpg';
import hydrationAfter from 'assets/images/landingPages/skincare/ba-hydration-after-cleanser.png';
import hydrationBefore from 'assets/images/landingPages/skincare/ba-hydration-before-cleanser.jpg';
import rednessAfter from 'assets/images/landingPages/skincare/ba-redness-after-moisturizer.jpg';
import rednessBefore from 'assets/images/landingPages/skincare/ba-redness-before-moisturizer.jpg';
import formulaModuleMoisturizerImg from 'assets/images/productPages/formulaModuleMoisturizer.jpg';
import aminoAcidImg from 'assets/images/productPages/ingredients/aminoacid_2.png';
import ceramidesImg from 'assets/images/productPages/ingredients/ceramides.png';
import ectoinImg from 'assets/images/productPages/ingredients/ectoin.png';
import fermentedRiceWaterImg from 'assets/images/productPages/ingredients/fermented_rice_water.png';
import mangoExtractImg from 'assets/images/productPages/ingredients/mango_extract.png';
import redFunghiExtractImg from 'assets/images/productPages/ingredients/red_funghi_extract.png';
import squalaneImg from 'assets/images/productPages/ingredients/squalane.png';
import vitaminCImg from 'assets/images/productPages/ingredients/vitamin_c.png';
import vitaminEImg from 'assets/images/productPages/ingredients/vitamin_e_2.png';
import carouselImg2 from 'assets/images/productPages/moisturizer_carousel_2.jpg';
import carouselImg3 from 'assets/images/productPages/moisturizer_carousel_3.jpg';
import carouselImg4 from 'assets/images/productPages/moisturizer_carousel_4.jpg';
import carouselImg1 from 'assets/images/productPages/prose-custom-moisturizer.jpg';

import { expertsModule, scienceVideo } from '../landingPages/skincare';

export const moisturizerPageContent = {
  expertsModule: {
    ...expertsModule,
    headlineDesktop: 'A moisturizer developed by experts and loved by dermatologists',
    headlineMobile: `A moisturizer developed by experts and
    loved by dermatologists`,
  },
  beforeAndAfter: {
    title: 'results you can see',
    subtitle: 'One Custom Cleanser for all of your concerns, including:',
    results: [
      {
        title: 'hydration',
        beforeImage: hydrationBefore,
        beforeImageAlt: 'A close up on a woman’s dry skin before using Prose skin care routine',
        afterImage: hydrationAfter,
        afterImageAlt:
          'A close up on a woman’s dry skin immediately after using Prose skin care routine',
      },
      {
        title: 'blemishes',
        beforeImage: blemishesBefore,
        beforeImageAlt: 'A close up on a woman’s pimples before using skin care products by Prose',
        afterImage: blemishesAfter,
        afterImageAlt: 'A close up on a woman’s pimples after using skin care products by Prose',
      },
      {
        title: 'Redness',
        beforeImage: rednessBefore,
        beforeImageAlt:
          'A close up on a man’s crows feet wrinkles before using Prose skin care lines',
        afterImage: rednessAfter,
        afterImageAlt:
          'A close up on a man’s crows feet wrinkles after using Prose skin care lines',
      },
    ],
    disclaimer:
      'Individual results after 4 weeks of using Prose Cleanser, Serum, and Moisturizer twice a day - or 1 application of Prose Moisturizer. Results may vary',
  },
  metrics: scienceVideo,
  formulaModule: {
    header: 'A formula that couldn’t exist without you',
    description:
      'Formulated for your one-and-only skin, every product is made to order with clinically proven ingredients tailored to your unique concerns and goals.',
    hero: {
      imageDesktop: formulaModuleMoisturizerImg,
      imageMobile: formulaModuleMoisturizerImg,
      alt: 'woman with creative hair style',
    },
  },
  sampleFormulasSlider: [
    {
      title: 'formula no. 842414 for BRYSON',
      ingredients: [
        {
          image: ceramidesImg,
          name: 'Ceramides',
          benefit: 'Improves skin elasticity and promotes firmness',
        },
        {
          image: vitaminEImg,
          name: 'Vitamin E',
          benefit: 'Helps protect skin cells from damage',
        },
        {
          image: squalaneImg,
          name: 'Squalane',
          benefit: 'Minimizes wrinkle appearance',
        },
      ],
    },
    {
      title: 'formula no. 842415 for HANNAH',
      ingredients: [
        {
          image: mangoExtractImg,
          name: 'Mango Extract',
          benefit: 'Minimizes blemishes and controls shine',
        },
        {
          image: fermentedRiceWaterImg,
          name: 'Fermented Rice Water',
          benefit: 'Soothes and minimizes the look of redness',
        },
        {
          image: aminoAcidImg,
          name: 'Amino Acid',
          benefit: 'Helps reduce hardening of post-blemish skin',
        },
      ],
    },
    {
      title: 'formula no. 842412 for JOELLE',
      ingredients: [
        {
          image: vitaminCImg,
          name: 'Vitamin C',
          benefit: 'Luminates skin and reduces redness',
        },
        {
          image: redFunghiExtractImg,
          name: 'Red Funghi Extract',
          benefit: 'Improves appearance of firmness',
        },
        {
          image: ectoinImg,
          name: 'Ectoin',
          benefit: 'Helps improve skin elasticity',
        },
      ],
    },
  ],
  faq: {
    title: 'Frequently asked questions',
    questions: [
      {
        question: 'How do I use my custom moisturizer?',
        answer: `Your custom moisturizer is designed to care for and protect your skin according to the results of your consultation. We recommend daily use, morning and night after your serum.`,
      },
      {
        question: 'Will my custom moisturizer treat my specific skin condition?',
        answer: `Prose skincare is not medical-grade or intended to treat medical conditions including, but not limited to acne, eczema, or psoriasis. Based on your consultation, your custom formula will contain ingredients known to hydrate, balance, and protect your skin. If you have any specific concerns, we recommend consulting with your doctor before starting Prose skincare.`,
      },
      {
        question: 'What can I expect from the moisturizer’s texture?',
        answer: `Each moisturizer texture is formulated to leave your skin feeling nourished and refreshed. At the end of your consultation, you'll be shown our chemists' top recommendations for your skin — but you can always opt for a different texture if you prefer.`,
      },
      {
        question: `Can I use my custom moisturizer with SPF?`,
        answer: `Since your custom moisturizer doesn't contain SPF, we strongly recommend that you apply your favorite SPF product after your moisturizer.`,
      },
      {
        question: 'Is the packaging recyclable?',
        answer: `Our packaging primarily consists of lightweight glass jars and is recyclable.`,
      },
      {
        question: 'What are my moisturizer fragrance options?',
        answer: (
          <>
            You can choose a fragrance during your consultation — or you can choose to make your
            formula fragrance-free, which we recommend for those with skin sensitivity.
            <br />
            <br />
            Please note that fragrance-free formulas may still contain a slight scent due to the
            active ingredients in your formulas, as we don&apos;t use fragrance-masking additives.
          </>
        ),
      },
      {
        question: 'What if my product looks different from what’s on the website?',
        answer:
          'Because Prose products are truly custom, each formula is unique. This means that color and scent can vary slightly from formula to formula, even if you choose fragrance-free. For example, your moisturizer might appear white or slightly beige, depending on the active ingredients — but no matter the color, your products are always made to order according to our stringent safety and quality standards.',
      },
    ],
  },
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom moisturizer packaging',
    },
    {
      image: carouselImg2,
      alt: 'Single Prose custom moisturizer pots stacked',
    },
    {
      image: carouselImg3,
      alt: 'Someone using Prose custom moisturizer on the face',
    },
    {
      image: carouselImg4,
      alt: 'Prose custom moisturizer paste',
    },
  ],
  numbersModule: {
    title: 'Always-custom, one of a kind results',
    numbers: [
      { number: '92%', label: 'say their skin looks healthy*' },
      { number: '91%', label: 'say their skin is glowy* ' },
      { number: '90%', label: 'say their moisturizer makes their skin feel comfortable**' },
      { number: '86%', label: 'say their moisturizer makes their skin feel soft**' },
      { number: '85%', label: 'say the appearance of fine lines is reduced*' },
      { number: '83%', label: ' say their skin barrier is stronger*' },
    ],
    disclaimers: [
      '*Based on a 4-week use test of 72 people, ages 25 to 70 with sensitive skin, who used Prose Cream Moisturizer twice a day.',
      '**Based on a 4-week clinical study of 66 people, ages 45 to 65 with sensitive skin, who used Prose Gel Moisturizer, Cream, or Rich Cream twice a day.',
    ],
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Moisturizer is rigorously researched, backed by science, and precisely selected to target your unique skin goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    list: [
      'Vegan',
      'Cruelty-free',
      'Silicone-free',
      'Alcohol-free',
      'Paraben-free',
      'Mineral oil-free',
      'Phthalate-free',
      'GMO-free',
      'Available fragrance-free',
    ],
    title: 'A moisturizer with naturally powerful + proven-effective ingredients',
  },
};
