import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { Typography } from 'Components/Typography';

import { productsCategories } from 'constants/products';

import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import { shouldShowTrialOffer } from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';

import { useCheckoutCategory } from '../hooks/useCheckoutCategory';

const Wrapper = styled.div<{ backgroundColor: string }>`
  width: 100%;
  margin-top: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  padding: 10px 8px 8px 8px;
  border-radius: 4px;

  ${legacyTheme.breakpoints.up('sm')} {
    margin-bottom: 16px;
  }
`;

const OrderSummaryPromoCommunication = () => {
  const subscriptionDiscount = useAppSelector(checkoutCartSelectors.getSubscriptionDiscount);
  const checkoutCategory = useCheckoutCategory();

  const showTrialOffer = useAppSelector(shouldShowTrialOffer);
  const hasHaircareSubscriptionInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const hasSkincareSubscriptionInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);

  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const hasSkincareMinisInItems = useAppSelector(checkoutCartSelectors.getHasSkincareMinisInItems);
  const hasSkincareMinisInCatalog = useAppSelector(
    checkoutCartSelectors.getHasSkincareMinisInCatalog,
  );

  const show50DiscountMessage =
    showTrialOffer &&
    ((checkoutCategory === productsCategories.HAIRCARE && !hasHaircareSubscriptionInAnyState) ||
      (checkoutCategory === productsCategories.SKINCARE &&
        !hasSkincareSubscriptionInAnyState &&
        !hasSkincareMinisInCatalog));

  const is50DiscountApplied = show50DiscountMessage && cartHasSubscription;

  const isSubscriptionDiscountApplied = cartHasSubscription;

  return (
    <>
      {hasSkincareMinisInItems && (
        <Wrapper backgroundColor={theme.colors.highlight[200]}>
          <Typography
            data-testid="order-summary-promo-communication"
            markupName="span"
            variant="p2"
          >
            <b>FREE</b> Skincare Starter Set added!
          </Typography>
        </Wrapper>
      )}
      {!hasSkincareMinisInItems && is50DiscountApplied && (
        <Wrapper backgroundColor={theme.colors.tertiary[200]}>
          <Typography
            data-testid="order-summary-promo-communication"
            markupName="span"
            variant="p2"
          >
            <b>50% off</b> + <b>free shipping</b> applied!
          </Typography>
        </Wrapper>
      )}
      {!hasSkincareMinisInItems && !is50DiscountApplied && isSubscriptionDiscountApplied && (
        <Wrapper backgroundColor={theme.colors.highlight[200]}>
          <Typography
            data-testid="order-summary-promo-communication"
            markupName="span"
            variant="p2"
          >
            <b>{subscriptionDiscount}% off</b> + <b>free shipping</b> applied!
          </Typography>
        </Wrapper>
      )}
    </>
  );
};

export default OrderSummaryPromoCommunication;
