import React, { createContext, type ReactNode, useContext, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import ModalPortal from 'Components/ModalPortal';
import { NpsModal } from 'Components/NpsModal';

import { trackHeapEvent } from 'dux/tracking/actions';
import { getNpsModalFlagVariant, isFlagsDoneFetching } from 'dux/featureFlags/selectors';
import * as flagVariants from 'dux/featureFlags/constants/flagVariants';

type ModalContextType = {
  isModalOpen: boolean;
  openNpsModal: (params: { orderPubkey: string }) => void;
  closeNpsModal: () => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

type NpsModalProviderProps = {
  children: ReactNode;
};

export const NpsModalProvider: React.FC<NpsModalProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderPubkey, setOrderPubkey] = useState('');
  // @ts-expect-error dux not typed
  const npsModalFlagVariant = useAppSelector(getNpsModalFlagVariant);
  // @ts-expect-error dux not typed
  const flagsDoneFetching = useAppSelector(isFlagsDoneFetching);

  const openNpsModal = (params: { orderPubkey: string }) => {
    if (flagsDoneFetching && npsModalFlagVariant === flagVariants.VARIANT1) {
      const npsModalDateViewed = window.localStorage.getItem('npsModal');
      const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
      const differenceBetweenDates = new Date().getTime() - parseInt(npsModalDateViewed || '0', 10);

      if (differenceBetweenDates > oneWeekInMs) {
        window.localStorage.setItem('npsModal', new Date().getTime().toString());
        dispatch(trackHeapEvent('Post Purchase NPS Prompt - View'));
        setOrderPubkey(params?.orderPubkey);
        setIsModalOpen(true);
      }
    }
  };

  const closeNpsModal = () => {
    dispatch(trackHeapEvent('Post Purchase NPS Prompt - Close'));
    setIsModalOpen(false);
  };

  const values = useMemo(
    () => ({ isModalOpen, openNpsModal, closeNpsModal }),
    [isModalOpen, flagsDoneFetching, npsModalFlagVariant],
  );

  return (
    <ModalContext.Provider value={values}>
      <ModalPortal isOpen={isModalOpen}>
        <NpsModal onClose={closeNpsModal} orderPubkey={orderPubkey} />
      </ModalPortal>
      {children}
    </ModalContext.Provider>
  );
};

export const useNpsModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a NpsModalProvider');
  }
  return context;
};
