import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useAppSelector } from 'dux/app/hooks';

import isEmpty from 'lodash/fp/isEmpty';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { Field } from 'formik';

import { CHECKOUT_NEW_ADDRESS_FIELD_VALUE } from 'Apps/Checkout/constants/checkoutInputValues';

import AddressFields from 'Components/AddressFields';
import { AddressRadio } from 'Components/AddressRadio';
import { Button } from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import { Typography } from 'Components/Typography';

import { getAddressesData } from 'dux/checkoutAddresses/selectors';
import { getCountriesList } from 'dux/countries/selectors';

import useSelectAddressInfosByCartType from '../hooks/useSelectAddressInfosByCartType';

const Wrapper = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 300px;
  }
`;

const TextButtonNewAddress = styled.button`
  all: unset;
`;

const SelectAddress = ({
  selectedCountry,
  isSubmitting,
  onCancel,
  isCurrentStep,
  resetNewAddressForm,
}) => {
  const { isFree, shippingAddress } = useSelectAddressInfosByCartType();
  const countriesList = useAppSelector(getCountriesList);
  const addresses = useAppSelector(getAddressesData);

  const isEditingAddressSection = shippingAddress && isCurrentStep && !isFree;

  return (
    <Wrapper>
      {isEmpty(addresses) && (
        <AddressFields countriesList={countriesList} selectedCountry={selectedCountry} />
      )}
      {!isEmpty(addresses) && (
        <Field name="addressPubkey">
          {({ field: { value }, form: { setFieldValue } }) => (
            <>
              {addresses.map(
                ({ pubkey, address1, address2, label, name, city, state, zipcode, country }) => (
                  <Fragment key={pubkey}>
                    <AddressRadio
                      aria-label={`Choose address ${name} ${address1}`}
                      onClick={() => setFieldValue('addressPubkey', pubkey)}
                      selected={value === pubkey}
                    >
                      <Typography align="left" color="noirDark" variant="p3">
                        {label && (
                          <>
                            <strong>{label}</strong>
                            <br />
                          </>
                        )}
                        {name}
                        {name && <br />}
                        {address1}
                        <br />
                        {address2 && (
                          <>
                            {address2}
                            <br />
                          </>
                        )}
                        {city}, {state} {zipcode} {country}
                      </Typography>
                    </AddressRadio>
                    <Spacer size={10} />
                  </Fragment>
                )
              )}

              {value === CHECKOUT_NEW_ADDRESS_FIELD_VALUE ? (
                <>
                  <AddressRadio
                    aria-label="Add a new address"
                    onClick={() => setFieldValue('addressPubkey', CHECKOUT_NEW_ADDRESS_FIELD_VALUE)}
                    selected={value === CHECKOUT_NEW_ADDRESS_FIELD_VALUE}
                  >
                    <Typography align="left" color="grey" variant="p3">
                      New address
                    </Typography>
                  </AddressRadio>
                  <Spacer size={20} />
                  <AddressFields countriesList={countriesList} selectedCountry={selectedCountry} />
                  <Spacer size={10} />
                </>
              ) : (
                <TextButtonNewAddress
                  aria-label="Add a new address"
                  data-testid="add-new-address-button"
                  onClick={async () => {
                    await setFieldValue('addressPubkey', CHECKOUT_NEW_ADDRESS_FIELD_VALUE);
                    resetNewAddressForm();
                  }}
                  type="button"
                >
                  <Typography underline variant="p2">
                    Add new address
                  </Typography>
                </TextButtonNewAddress>
              )}
            </>
          )}
        </Field>
      )}

      {isEditingAddressSection && (
        <>
          <Spacer size={10} />
          <Button
            data-testid="checkout-save-shipping-address-button"
            disabled={isSubmitting}
            fullWidth
            isLoading={isSubmitting}
            noMargin
            type="submit"
            variant="vert"
          >
            Save
          </Button>
          <Spacer size={10} />
          {!isSubmitting && (
            <Button fullWidth noMargin onClick={onCancel} type="button" variant="vertOutline">
              Cancel
            </Button>
          )}
        </>
      )}
    </Wrapper>
  );
};

SelectAddress.propTypes = {
  selectedCountry: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  isCurrentStep: PropTypes.bool.isRequired,
  resetNewAddressForm: PropTypes.func.isRequired,
};

SelectAddress.defaultProps = {
  selectedCountry: null,
  onCancel: null,
};

export default SelectAddress;
