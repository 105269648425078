const geolocationCodeToLocale = {
  US: 'en-US',
  CA: 'en-CA',
} as const;

export const formatPriceWithCurrency = ({
  currency,
  currencyDisplay = 'symbol',
  locale = geolocationCodeToLocale.US,
  minimumFractionDigits = 2,
  price,
  style = 'currency',
}: {
  currency: string;
  currencyDisplay?: Intl.NumberFormatOptions['currencyDisplay'];
  locale?: string;
  minimumFractionDigits?: number;
  price: number | string;
  style?: Intl.NumberFormatOptions['style'];
}) => {
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay,
    minimumFractionDigits,
    style,
  });

  // @ts-expect-error - WARN: missing es2023 lib definitions. Available in latest typescript version. Update required
  return formatter.format(price);
};
