import { Box, Text, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';

import { Image, type StaticImageData } from 'Components/Image';
import { TwoUp } from 'Components/TwoUp';
import { Typography } from 'Components/Typography';

const BackgroundImage = styled(Image)`
  width: 100%;
  height: auto;
  object-fit: cover;

  --page-padding: ${theme.spacing['3x']};

  min-height: 184px;
  max-width: calc(var(--content-width, 1400px) / 2 - var(--page-padding));
`;

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
  padding: ${theme.spacing['8x']};

  grid-template-columns: [content-start] 100% [content-end];

  min-height: 254px;

  ${mediaMinWidth('sm')} {
    grid-template-columns:
      minmax(0, ${theme.spacing['14x']})
      [content-start]
      minmax(auto, max-content)
      [content-end]
      minmax(0, 100%);
  }

  & > * {
    grid-column: content-start / content-end;
  }
`;

const Eyebrow = styled(Typography)`
  margin-bottom: ${theme.spacing['6x']};
  font-size: 14px;
`;

const Title = styled(Typography)`
  margin-bottom: ${theme.spacing['3x']};
  font-size: 36px;
`;

type Props = {
  productName: string;
  imageSrc: StaticImageData;
  fragranceDescription: string;
  fragranceName: string;
};

export const FragranceSection = ({
  productName,
  imageSrc,
  fragranceDescription,
  fragranceName,
}: Props) => {
  return (
    <TwoUp
      borders={{ top: true }}
      dataTestId={`${productName.toLowerCase()}-fragrance-section`}
      minHeight={{ desktop: '340px', mobile: 'fit-content' }}
      spaceDistribution={{ desktop: 'equal', mobile: 'contents' }}
    >
      <Box display="flex" justifyContent="flex-end" width="full">
        <BackgroundImage alt="" src={imageSrc} />
      </Box>

      <StyledBox alignContent="center" display="grid" justifyContent="center">
        <Eyebrow medium variant="mono1">
          Your {productName} fragrance
        </Eyebrow>

        <Title variant="h3">{fragranceName}</Title>
        <Text>{fragranceDescription}</Text>
      </StyledBox>
    </TwoUp>
  );
};
