import crossIconImg from 'assets/images/cross_icon.svg';
import menuIconImg from 'assets/images/menu_icon.svg';
import proseLogoImg from 'assets/images/prose_logo_navigation.svg';
import userIconImg from 'assets/images/user_icon.svg';

export const navbarContent = {
  accountLink: 'Your account',
  signinLink: 'Sign in',
  logo: {
    image: proseLogoImg,
    alt: 'Prose black logo',
  },
  menuIcon: {
    closed: {
      image: menuIconImg,
      alt: 'Closed menu icon',
    },
    opened: {
      image: crossIconImg,
      alt: 'Opened menu icon',
    },
  },
  userIcon: {
    image: userIconImg,
    alt: 'User icon',
  },

  menuOptions: [
    {
      text: 'Haircare',
      to: '/products/haircare',
      dataClick: 'haircare',
      dataTestId: 'navbar-haircare-tab',
    },
    {
      text: 'Skincare',
      to: '/pages/skincare',
      dataClick: 'skincare',
      dataTestId: 'navbar-skincare-tab',
    },
    {
      text: 'Accessories',
      to: '/products/accessories',
      dataClick: 'accessories',
      dataTestId: 'navbar-accessories-tab',
    },
    {
      text: 'Our ingredients',
      to: '/ingredients',
      dataClick: 'ingredients',
      dataTestId: 'navbar-ingredients-tab',
    },
    {
      text: 'About us',
      dataTestId: 'navbar-about-us-tab',
      subMenu: [
        { text: 'Our story', to: '/about', dataClick: 'about', dataTestId: 'navbar-about-tab' },
        {
          text: 'Sustainability & social impact',
          to: '/sustainability-and-social-impact',
          dataClick: 'sustainability-and-social-impact',
          dataTestId: 'navbar-sustainability-and-social-impact-tab',
        },
      ],
    },
    {
      text: 'Reviews',
      to: '/reviews',
      dataClick: 'reviews',
      dataTestId: 'navbar-reviews-tab',
    },
    { text: 'Gift Prose', to: '/gift', dataClick: 'gift', dataTestId: 'navbar-gift-tab' },
  ],
};
