import { Typography } from 'Components/Typography';

import { LegacyBaseScene as BaseScene } from 'Scenes/LegacyBaseScene';

const MagicLoading = () => (
  <BaseScene header>
    <Typography align="center" paragraph variant="h1r">
      Welcome back!
    </Typography>
    <Typography align="center" variant="p1">
      Signing you in...
    </Typography>
  </BaseScene>
);

export default MagicLoading;
