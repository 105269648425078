import { MY_SKIN } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { SKIN } from '../constants/progressCategories';

const routineProducts = {
  Component: consultationComponents.RoutineProducts,
  ModalComponent: consultationComponents.MedicalModal,
  name: 'routineProducts',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/other-products`,
  title: 'What products do you use regularly?',
  subtitle: 'Select all that apply, thinking about a typical week.',
  tip: {
    title: 'fun fact:',
    content:
      "Whether you prefer to stick to the essentials or you love experimenting with new products, we'll help you build a custom routine that fits your unique needs — because skincare is always personal.",
  },
  requiredOptionsForModalToOpen: ['medical_topicals'],
  answersGroup: [
    { title: 'Cleansers', slug: 'cleanser' },
    { title: 'Treatments and moisturizers', slug: 'treatments' },
    { title: 'Makeup', slug: 'makeup' },
  ],
  options: [
    {
      value: 'rinse_off_cleanser',
      label: 'Rinse-off cleanser',
      answersGroupSlug: 'cleanser',
    },
    {
      value: 'micellar_water',
      label: 'Micellar water',
      answersGroupSlug: 'cleanser',
    },
    {
      value: 'cleansing_oil',
      label: 'Cleansing oil',
      answersGroupSlug: 'cleanser',
    },
    {
      value: 'toner',
      label: 'Toner',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'spf',
      label: 'SPF',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'serum',
      label: 'Serum',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'scrub',
      label: 'Scrub',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'face_oil',
      label: 'Face oil',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'mask',
      label: 'Mask',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'eye_cream',
      label: 'Eye Cream',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'medical_topicals',
      label: 'Medicated topicals (e.g. tretinoin)',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'shaving_products',
      label: 'Shaving Products (face)',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'moisturizer',
      label: 'Moisturizer',
      answersGroupSlug: 'treatments',
    },
    {
      value: 'eye_makeup',
      label: 'Eye makeup',
      answersGroupSlug: 'makeup',
    },
    {
      value: 'lip_makeup',
      label: 'Lip makeup',
      answersGroupSlug: 'makeup',
    },
    {
      value: 'face_makeup',
      label: 'Face makeup',
      answersGroupSlug: 'makeup',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers?.diag_routine_products,
  getAnswers: ({ selected }) => ({
    diag_routine_products: selected,
  }),
  multi: true,
};

export default routineProducts;
