import blemishesAfter from 'assets/images/landingPages/skincare/ba-blemishes-after-cleanser.jpg';
import blemishesBefore from 'assets/images/landingPages/skincare/ba-blemishes-before-cleanser.jpg';
import hydrationAfter from 'assets/images/landingPages/skincare/ba-hydration-after-cleanser.png';
import hydrationBefore from 'assets/images/landingPages/skincare/ba-hydration-before-cleanser.jpg';
import carouselImg2 from 'assets/images/productPages/cleanser_carousel_2.jpg';
import carouselImg3 from 'assets/images/productPages/cleanser_carousel_3.jpg';
import carouselImg4 from 'assets/images/productPages/cleanser_carousel_4.jpg';
import formulaModuleCleanserImg from 'assets/images/productPages/formulaModuleCleanser.jpg';
import aminoAcidImg from 'assets/images/productPages/ingredients/aminoacid.png';
import bakuchiolImg from 'assets/images/productPages/ingredients/bakuchiol.png';
import caviarLimeExtractImg from 'assets/images/productPages/ingredients/caviar_lime_extract.png';
import dPanthenolImg from 'assets/images/productPages/ingredients/d_panthenol.png';
import glycerinImg from 'assets/images/productPages/ingredients/glycerin.png';
import niacinamideImg from 'assets/images/productPages/ingredients/niacinamide.png';
import rosehipOilImg from 'assets/images/productPages/ingredients/rosehip_oil_2.png';
import vitaminEImg from 'assets/images/productPages/ingredients/vitamin_e_2.png';
import xyltolImg from 'assets/images/productPages/ingredients/xylitol.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-cleanser.jpg';

import { expertsModule, scienceVideo } from '../landingPages/skincare';

export const cleanserContent = {
  expertsModule: {
    ...expertsModule,
    headlineDesktop: 'A cleanser developed by experts and loved by dermatologists',
    headlineMobile: `A cleanser developed by experts and
    loved by dermatologists`,
  },
  beforeAndAfter: {
    title: 'results you can see',
    subtitle: 'One Custom Cleanser for all of your concerns, including:',
    results: [
      {
        title: 'blemishes',
        beforeImage: blemishesBefore,
        beforeImageAlt: 'A close up on a woman’s pimples before using skin care products by Prose',
        afterImage: blemishesAfter,
        afterImageAlt: 'A close up on a woman’s pimples after using skin care products by Prose',
      },
      {
        title: 'hydration',
        beforeImage: hydrationBefore,
        beforeImageAlt: 'A close up on a woman’s dry skin before using Prose skin care routine',
        afterImage: hydrationAfter,
        afterImageAlt:
          'A close up on a woman’s dry skin immediately after using Prose skin care routine',
      },
    ],
    disclaimer:
      'Individual results after 4 weeks of using Prose Cleanser, Serum, and Moisturizer twice a day - or 1 application of Prose Moisturizer. Results may vary',
  },
  metrics: scienceVideo,
  formulaModule: {
    header: 'A formula that couldn’t exist without you',
    description:
      'Formulated for your one-and-only skin, every product is made to order with clinically proven ingredients tailored to your unique concerns and goals.',
    hero: {
      imageDesktop: formulaModuleCleanserImg,
      imageMobile: formulaModuleCleanserImg,
      alt: 'woman with creative hair style',
    },
  },
  sampleFormulasSlider: [
    {
      title: 'Formula No. 842414 for Bryson',
      ingredients: [
        {
          image: bakuchiolImg,
          name: 'Bakuchiol',
          benefit: 'Balaces Complexion',
        },
        {
          image: vitaminEImg,
          name: 'Vitamin E',
          benefit: 'Protects cell damage',
        },
        {
          image: glycerinImg,
          name: 'Glycerin',
          benefit: 'Boosts Hydration',
        },
      ],
    },
    {
      title: 'Formula No. 842415 for Hannah',
      ingredients: [
        {
          image: aminoAcidImg,
          name: 'Aminoacid',
          benefit: 'Helps reduce hardening of post-blemish skin',
        },
        {
          image: xyltolImg,
          name: 'Xylitol',
          benefit: 'Reinforces skin barrier',
        },
        {
          image: dPanthenolImg,
          name: 'D-Panthenol',
          benefit: 'Helps soothe the skin',
        },
      ],
    },
    {
      title: 'Formula no. 842412 for Joelle',
      ingredients: [
        {
          image: niacinamideImg,
          name: 'Niacinamide',
          benefit: 'Improves elasticity and balances complexion',
        },
        {
          image: rosehipOilImg,
          name: 'Rosehip Oil',
          benefit: 'Nourishes the skin and evens out skintone',
        },
        {
          image: caviarLimeExtractImg,
          name: 'Caviar Lime',
          benefit: 'Gently exfoliates for a brighter complexion',
        },
      ],
    },
  ],
  faq: {
    title: 'Frequently asked questions',
    questions: [
      {
        question: 'How do I use my custom cleanser?',
        answer: (
          <>
            Prose cleanser sets the stage for your custom skincare routine and can be used morning
            and night. Dispense 1-2 pumps into wet hands, then lather onto wet face and neck in a
            circular motion. Rinse, then follow with serum and moisturizer.
            <br />
            <br />
            Pro tip: Gently tap the bottle against a countertop before inserting the pump before to
            ensure product doesn&apos;t spill.
          </>
        ),
      },
      {
        question: 'Will my custom cleanser treat my specific skin condition?',
        answer: `Prose skincare is not medical-grade or intended to treat medical conditions including, but not limited to acne, eczema, or psoriasis. Based on your consultation, your custom formula will contain ingredients known to hydrate, balance, and protect your skin. If you have any specific concerns, we recommend consulting with your doctor before using Prose skincare.`,
      },
      {
        question: 'What can I expect from the cleanser’s texture?',
        answer: `Both cleanser textures are gentle on the skin and formulated to leave it feeling soft, smooth, and clean. At the end of your consultation, you'll be shown our chemists' top recommendations for your skin — but you can always opt for a different texture if you prefer.`,
      },
      {
        question: `Is the packaging recyclable?`,
        answer: `Our packaging primarily consists of lightweight glass bottles and minimal plastic. The bottle is recyclable and we encourage you to reuse your cleanser pump for future refills!`,
      },
      {
        question: 'What are my cleanser fragrance options?',
        answer: (
          <>
            You can choose a fragrance during your consultation — or you can choose to make your
            formula fragrance-free, which we recommend for those with skin sensitivity.
            <br />
            <br />
            Please note that fragrance-free formulas may still contain a slight scent due to the
            active ingredients in your formulas, as we don&apos;t use fragrance-masking additives.
          </>
        ),
      },
      {
        question: 'What is the pH level of my custom cleanser?',
        answer: `Prose cleansers are formulated at a pH level that's similar to the skin's natural pH level, making them optimal for proper skin barrier function, hydration, and cell turnover.`,
      },
      {
        question: 'How should I insert the pump into the bottle?',
        answer:
          'Gently tap the bottle against a countertop before inserting the pump before to ensure product doesn’t spill.',
      },
      {
        question: 'What if my product looks different from what’s on the website?',
        answer:
          'Because Prose products are truly custom, each formula is unique. This means that color and scent can vary slightly from formula to formula, even if you choose fragrance-free. For example, your moisturizer might appear white or slightly beige, depending on the active ingredients — but no matter the color, your products are always made to order according to our stringent safety and quality standards.',
      },
    ],
  },
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom cleanser packaging',
    },
    {
      image: carouselImg2,
      alt: 'Single Prose custom cleanser with foam',
    },
    {
      image: carouselImg3,
      alt: 'Someone using Prose custom cleanser on the face',
    },
    {
      image: carouselImg4,
      alt: 'Someone applying Prose custom cleanser on the face',
    },
  ],
  numbersModule: {
    title: 'Always-custom, one of a kind results',
    numbers: [
      { number: '100%', label: 'say their cleanser left their skin clean*' },
      { number: '95%', label: 'say their cleanser made their skin soft*' },
      { number: '95%', label: 'say their cleanser didn’t leave their skin feeling dry**' },
      { number: '91%', label: 'say their cleanser left their skin clear*' },
      { number: '91%', label: 'say their cleanser left their skin smooth*' },
      { number: '86%', label: 'say their cleanser removed oily residue and traces of makeup*' },
    ],
    disclaimers: [
      '*Based on a 4-week clinical study of 22 people, ages 18 to 65 with sensitive skin, who used Prose Gel Cleanser twice a day.',
      '**Based on a 4-week clinical study of 22 people, ages 18 to 65 with sensitive skin, who used Prose Cream Cleanser twice a day.',
    ],
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Cleanser is rigorously researched, backed by science, and precisely selected to target your unique skin goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    list: [
      'Vegan',
      'Cruelty-free',
      'Silicone-free',
      'Alcohol-free',
      'Paraben-free',
      'Mineral oil-free',
      'Phthalate-free',
      'GMO-free',
      'Available fragrance-free',
    ],
    title: 'A cleanser with naturally powerful + proven-effective ingredients',
  },
};
