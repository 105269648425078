import ky, { HTTPError as KyHTTPError } from 'ky';

import { getEnvOrThrow } from 'utils/getEnv';
import { isJsonResponse } from 'utils/isJsonResponse';

import { clearTokens } from './Auth';

export const httpClient = ky.extend({
  prefixUrl: getEnvOrThrow('REACT_APP_ROOT_URL'),
  credentials: 'include',
  hooks: {
    beforeError: [
      async (error) => {
        if (error instanceof KyHTTPError) {
          const { response } = error;
          const isJson = isJsonResponse(response);

          if (isJson) {
            // the error is describe within the response body
            const body = await response.clone().json();

            // @ts-expect-error - we get a different format for this specific error. Fix to be defined with the API
            const code = body?.code?.code;
            if (code === 'token_not_valid') {
              clearTokens();
            }
          }
        }
        throw error;
      },
    ],
  },
});
