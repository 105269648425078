type LazyImgProps = {
  alt: string;
  src: string;
  width?: number | string;
};

export const LazyImg = ({ src, alt, width, ...rest }: LazyImgProps) => (
  <div>
    <img alt={alt} src={src} width={width} {...rest} />
  </div>
);
