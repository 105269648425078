import { useState } from 'react';

import { Link, useMatch, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { useConsultationCategory } from 'Apps/Consultation/hooks/useConsultationCategory';

import { BasicModal } from 'Components/BasicModal';
import { Image } from 'Components/Image';
import { Button } from 'Components/LegacyButton';
import { Typography } from 'Components/Typography';

import { productsCategories } from 'constants/products';

import { type ConsultationCategoryProp } from 'PropTypes/consultationProps';

import * as exitModalContent from 'assets/content/consultation/exitModal';
import { navbarContent } from 'assets/content/navbar';

import { trackHeapEvent } from 'dux/tracking/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';
import { getEmailCaptureExitConsultationModalOpened } from 'dux/consultation/selectors';
import * as userSelectors from 'dux/user/selectors';
import { answersActions } from 'dux/consultation/slice';

import { EmailCaptureExitModal } from './EmailCaptureExitModal';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${legacyTheme.spacing.s36};
`;

const ModalExitButton = styled(Link)`
  margin-top: 24px;
  color: ${theme.colors.neutral[800]};
  border-bottom: 1px solid ${theme.colors.neutral[800]};
`;

const Container = styled.div`
  overflow-y: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${legacyTheme.props.navbarHeight};
  padding: 0 ${legacyTheme.spacing.s32};

  background: ${theme.colors.neutral[100]};
  box-shadow: 0 2px 3px 0 color(rgb 180 180 180 / 20%);

  ${legacyTheme.breakpoints.match('md')} {
    padding: 0 20px;
  }
`;

const StyledButton = styled.button`
  ${legacyTheme.utils.resetButton}
  display: flex;

  &:hover {
    color: ${theme.colors.primary[400]};
    text-decoration: underline;
  }
`;

const BackButton = styled(StyledButton)`
  display: flex;
  flex: 1 1 50%;
  flex-direction: row;
`;

const ExitButton = styled(BackButton)`
  justify-content: flex-end;
`;

type ConsultationExitModalProps = {
  consultationCategory: ConsultationCategoryProp;
  hasCompletedHaircareConsultation?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const ConsultationExitModal = ({
  consultationCategory,
  hasCompletedHaircareConsultation = false,
  isOpen,
  onClose,
}: ConsultationExitModalProps) => (
  <BasicModal dataFrom="consultation-exit-modal" isOpen={isOpen} onClose={onClose}>
    <Typography align="center" paragraph variant="h2">
      {exitModalContent.title(consultationCategory)}
    </Typography>

    <Typography align="center" variant="p2">
      {exitModalContent.body(consultationCategory)}
    </Typography>
    <ButtonsContainer>
      <Button
        data-click="continue"
        data-from="consultation-exit-modal"
        data-testid="consultation-modal-continue-cta"
        noMargin
        onClick={onClose}
        variant="vert"
      >
        {exitModalContent.continueCTA}
      </Button>
      <ModalExitButton
        data-click="exit"
        data-from="consultation-exit-modal"
        data-testid="consultation-modal-exit-cta"
        to={hasCompletedHaircareConsultation ? '/account' : '/'}
      >
        <Typography variant="p2">{exitModalContent.exitCTA}</Typography>
      </ModalExitButton>
    </ButtonsContainer>
  </BasicModal>
);

export const ConsultationNavBar = () => {
  const dispatch = useAppDispatch();
  const emailCaptureExitConsultationModalOpened: boolean = useAppSelector<boolean>(
    getEmailCaptureExitConsultationModalOpened,
  );
  const hasCompletedHaircareConsultation: boolean = useAppSelector(
    userSelectors.getHasCompletedHaircareConsultation,
  );
  const hasCompletedSkincareConsultation: boolean = useAppSelector(
    userSelectors.getHasCompletedSkincareConsultation,
  );
  const isAuthenticated: boolean = useAppSelector<boolean>(getIsAuthenticated);
  const consultationCategory = useConsultationCategory();
  const match = useMatch('/consultation/:category');
  const isOnHomeConsultationPage = match?.pathname === '/consultation';
  const isOnConsultationCategoryChoicePage = match?.params?.category === 'category';

  const navigate = useNavigate();
  const [isExitModalOpened, setExitModalOpened] = useState(false);

  const exitConsultation = (forcedUrl?: string) => {
    if (isOnHomeConsultationPage || isOnConsultationCategoryChoicePage) {
      navigate('/');
    } else if (!isAuthenticated) {
      dispatch(trackHeapEvent('Consultation Email Capture Modal Viewed'));
      dispatch(answersActions.setEmailCaptureExitConsultationModalOpened(true));
    } else if (hasCompletedHaircareConsultation || hasCompletedSkincareConsultation) {
      if (forcedUrl) {
        navigate(forcedUrl);
      } else if (
        (hasCompletedHaircareConsultation &&
          consultationCategory === productsCategories.HAIRCARE) ||
        (hasCompletedSkincareConsultation && consultationCategory === productsCategories.SKINCARE)
      ) {
        navigate('/account');
      } else {
        navigate('/');
      }
    } else {
      dispatch(trackHeapEvent('Consultation Exit Modal Viewed'));
      setExitModalOpened(true);
    }
  };

  return (
    <Container data-testid="consultation-header-nav">
      <BackButton
        aria-label="Navigate to previous screen"
        data-click="back"
        data-from="header-nav"
        data-testid="header-back-button"
        onClick={() => navigate(-1)}
        type="button"
      >
        <Typography color="noir" variant="p2">
          ← Back
        </Typography>
      </BackButton>

      <StyledButton
        aria-label="Return Home"
        data-click="logo"
        data-from="header-nav"
        data-testid="consultation-header-logo"
        onClick={() => exitConsultation('/')}
        type="button"
      >
        <Image alt={navbarContent.logo.alt} height={22} src={navbarContent.logo.image} width={62} />
      </StyledButton>

      <ExitButton
        aria-label="Exit Consultation"
        data-click="exit"
        data-from="header-nav"
        data-testid="consultation-header-exit"
        onClick={() => exitConsultation()}
        type="button"
      >
        <Typography color="noir" variant="p2">
          Exit
        </Typography>
      </ExitButton>
      {consultationCategory && (
        <EmailCaptureExitModal
          consultationCategory={consultationCategory}
          isOpen={emailCaptureExitConsultationModalOpened}
          onClose={() => dispatch(answersActions.setEmailCaptureExitConsultationModalOpened(false))}
        />
      )}
      {consultationCategory && (
        <ConsultationExitModal
          consultationCategory={consultationCategory}
          hasCompletedHaircareConsultation={hasCompletedHaircareConsultation}
          isOpen={isExitModalOpened}
          onClose={() => setExitModalOpened(false)}
        />
      )}
    </Container>
  );
};
