import type { InputValues } from 'Blocks/FormGift';

import type { dateToUtc } from 'utils/dateUtils';

import { appApi } from 'dux/app/api';

import { setSelectedGift } from './actions';

// This shape is derived from the current usage of `values` in FormGift.tsx
// Probably, this type here should be a contract defined by/with API, rather than something derived from UI code only
export type CreateGiftPayload = {
  giftee_info: {
    email: InputValues['emailGiftee'];
    last_name: InputValues['lastnameGiftee'];
    first_name: InputValues['firstnameGiftee'];
  };
  message: InputValues['message'];
  enabled_at: ReturnType<typeof dateToUtc>;
  occasion: InputValues['occasion'];
  variant_slug: InputValues['selectedGiftCard'];
};

type GiftRawData = {
  pubkey: string;
};

const giftApiSlice = appApi.injectEndpoints({
  endpoints: (build) => ({
    createGiftMutation: build.mutation<GiftRawData, CreateGiftPayload>({
      query: (values) => ({
        url: `v1/gifts/`,
        method: 'POST',
        json: values,
      }),
      invalidatesTags: ['gift'],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(setSelectedGift({ pubkey: data.pubkey }));
        });
      },
    }),
  }),
});

export const {
  endpoints: { createGiftMutation },
} = giftApiSlice;
