import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

const useStyles = makeStyles(
  (_theme, _props, classes) => ({
    root: {
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      margin: `${legacyTheme.props.verticalSpacingDes} auto`,
      maxWidth: legacyTheme.width.normal,
      [legacyTheme.breakpoints.match('md')]: {
        margin: `${legacyTheme.props.verticalSpacingMob} auto`,
      },
      [`&.${classes['content-width-narrow']}`]: {
        maxWidth: legacyTheme.width.narrow,
      },
      [`&.${classes['content-width-half']}`]: {
        maxWidth: legacyTheme.width.half,
      },
      [`&.${classes['content-width-wide']}`]: {
        maxWidth: legacyTheme.width.wide,
      },
      [`&.${classes['content-width-veryWide']}`]: {
        maxWidth: legacyTheme.width.veryWide,
      },
    },
    'align-center': {
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
    'align-left': {
      alignItems: 'flex-start',
    },
    'align-mob-center': {
      [legacyTheme.breakpoints.match('md')]: {
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
    'align-mob-left': {
      [legacyTheme.breakpoints.match('md')]: {
        alignItems: 'flex-start',
        textAlign: 'left',
      },
    },
  }),
  { name: 'BlockLayout' }
);

/**
  Displays content in a vertical slice of the screen. Wide screens have a margin of **144px** and
  narrow screens **72px**. By default content cannot get larger then **624px**. There is also a
  minimal horizontal padding of **24px** that can be seen in mobile screens.
  Some configuration is possible, like changing the background color, defining a wider content
  or defining alignment.
*/
const BlockLayout = forwardRef(
  (
    {
      children,
      contentAlign,
      contentAlignMob,
      contentWidth,
      backgroundColor,
      backgroundImage,
      textPadding,
      style,
      dataTestId,
      className,
      blockLayoutMargin,
      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);
    return (
      <section
        ref={ref}
        className={cx(classes.root, className)}
        data-testid={dataTestId}
        style={{
          padding: `0 ${textPadding}px`,
          backgroundColor: theme.colors.legacy[backgroundColor] || backgroundColor,
          backgroundImage: backgroundImage && `url(${backgroundImage.src})`,
          ...style,
        }}
        {...props}
      >
        <div
          className={cx(
            classes.content,
            classes[`content-width-${contentWidth}`],
            contentAlign && classes[`align-${contentAlign}`],
            contentAlignMob && classes[`align-mob-${contentAlignMob}`]
          )}
          style={{ margin: blockLayoutMargin }}
        >
          {children}
        </div>
      </section>
    );
  }
);
BlockLayout.displayName = 'BlockLayout';

BlockLayout.propTypes = {
  /**
   * Currently used colors:
    'beige',
    'verylightPink',
    'darkerBeige',
    'noirDark',
    'noir',
    'rouge',
    'blueLight',
    'vert',
    'blue',
    'lilas',
    'royal',
    'silver',
    'cream',
    'white',
    listed to help migration to the 2024 colors
   */
  backgroundColor: PropTypes.string,
  backgroundImage: srcOrNextImageProp,
  children: PropTypes.node.isRequired,
  /** For style overriding purposes */
  classes: PropTypes.objectOf(PropTypes.string),
  contentAlign: PropTypes.oneOf(['center', 'left']),
  contentAlignMob: PropTypes.oneOf(['center', 'left']),
  contentWidth: PropTypes.oneOf(['narrow', 'half', 'normal', 'wide', 'veryWide']),
  style: PropTypes.shape({}),
  textPadding: PropTypes.number,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  blockLayoutMargin: PropTypes.string,
};

BlockLayout.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  contentAlign: null,
  contentAlignMob: null,
  contentWidth: 'normal',
  classes: null,
  style: {},
  textPadding: 24,
  dataTestId: null,
  className: null,
  blockLayoutMargin: null,
};

export default BlockLayout;
