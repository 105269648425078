import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const profileBackgrounds = {
  name: 'profile_backgrounds',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/profile-backgrounds`,
  title: 'Has your face ever reacted negatively to a skincare or makeup product?',
  subtitle: 'Select all that apply.',
  tip: {
    title: 'why we ask:',
    content:
      'This helps us assess your skin’s reactivity to cosmetics. Reactivity is different from sensitivity: Sensitivity is a general behavior, whereas reactivity is a response to a trigger. Learning which reactions you may be prone to helps us tailor your formula to you.',
  },
  options: [
    {
      value: 'warm_flush',
      label: 'Yes, a warm flush or redness',
    },
    {
      value: 'itching',
      label: 'Yes, tightness, itching or discomfort',
    },
    {
      value: 'breakouts',
      label: 'Yes, breakouts',
    },
    {
      value: 'none',
      label: "Not that I've noticed",
      unique: true,
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_backgrounds,
  getAnswers: ({ selected }) => ({
    profile_backgrounds: selected,
  }),
  multi: true,
};

export default profileBackgrounds;
