import { Typography } from 'Components/Typography';

import { MY_SKIN } from '../constants';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { SKIN } from '../constants/progressCategories';

import chin from 'assets/images/consultation/oilLocation/oil-location-chin.png';
import forehead from 'assets/images/consultation/oilLocation/oil-location-forehead.png';
import nose from 'assets/images/consultation/oilLocation/oil-location-nose.png';
import tZone from 'assets/images/consultation/oilLocation/oil-location-t-zone.png';

const oilLocation = {
  name: 'oil_location',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/oil-location`,
  title: 'Where is your face usually oily or shiny?',
  subtitle: 'Select all that apply, thinking about the last 3 months.',
  tip: {
    title: 'Need some help?',
    content:
      "Oily skin results from overproduction of sebum. While too much sebum can cause problems, it's actually vital to skin health. It protects against dehydration, keeps skin moisturized and supple, and helps your skin's microbiome stay balanced.",
    richContent: {
      Component: consultationTipsComponents.SliderImageAndContent,
      slides: () => [
        {
          id: 'tZone',
          heapTracker: 'Oil Location T-Zone',
          image: {
            src: tZone,
            alt: 'Illustration of a face with T-Zone highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                T-Zone
              </Typography>
              <Typography align="center" variant="p3">
                {`It's common to have an oily T-zone. This area has more sebaceous glands, so it
                naturally has more sebum, or oil.`}
              </Typography>
            </>
          ),
        },
        {
          id: 'forehead',
          heapTracker: 'Oil Location Forehead',
          image: {
            src: forehead,
            alt: 'Illustration of a face with the forehead highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Forehead
              </Typography>
              <Typography align="center" variant="p3">
                The sebaceous glands on the forehead can sometimes overproduce oil due to a rich
                diet.
              </Typography>
            </>
          ),
        },
        {
          id: 'nose',
          heapTracker: 'Oil Location Nose',
          image: {
            src: nose,
            alt: 'Illustration of a face with the nose highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Nose
              </Typography>
              <Typography align="center" variant="p3">
                The nose often becomes oily in response to environmental aggressors like pollution.
              </Typography>
            </>
          ),
        },
        {
          id: 'chin',
          heapTracker: 'Oil Location Chin',
          image: {
            src: chin,
            alt: 'Illustration of a face with the chin highlighted',
          },
          content: (
            <>
              <Typography align="center" bold variant="p3">
                Chin
              </Typography>
              <Typography align="center" variant="p3">
                Oil production from the sebaceaous glands on or around the chin can be amplified by
                hormonal changes.
              </Typography>
            </>
          ),
        },
      ],
    },
  },
  options: [
    {
      value: 'everywhere',
      label: 'All over',
    },
    {
      value: 't_zone',
      label: 'On my T-zone (forehead and nose)',
    },
    {
      value: 'forehead',
      label: 'On my forehead',
    },
    {
      value: 'nose',
      label: 'On and around my nose',
    },
    {
      value: 'chin',
      label: 'On my chin',
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('oiliness'),
  getInitialValue: answers => answers.diag_oil_location,
  getAnswers: ({ selected }) => ({
    diag_oil_location: selected,
  }),
  multi: true,
};

export default oilLocation;
