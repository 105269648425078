import * as OrderService from 'Services/OrderService';
import * as OrdersService from 'Services/OrdersService';

import { productsCategories } from 'constants/products';

import logSentryError from 'utils/logSentry';

import { cartHasSubscription } from 'dux/checkoutCart/selectors';

import {
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_NOT_NEEDED,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_PUMPS_CUSTOMER_PREFERENCES_FAILURE,
  FETCH_PUMPS_CUSTOMER_PREFERENCES_REQUEST,
  FETCH_PUMPS_CUSTOMER_PREFERENCES_SUCCESS,
} from './actionTypes';

const requestFetchOrders = () => {
  return { type: FETCH_ORDERS_REQUEST };
};

const receiveFetchOrders = orders => {
  return { type: FETCH_ORDERS_SUCCESS, orders };
};

const receiveFetchOrdersError = error => {
  return { type: FETCH_ORDERS_FAILURE, error };
};

/** @typedef {{variant: { product: { type: string }}}} OrderItem */
/**
 * @typedef {{
 * is_subscription: boolean,
 * is_supposed_delivered: boolean | null,
 * items: Array<OrderItem>,
 * pubkey: string,
 * shipping_estimate: {min: string, max: string},
 * status: 'paid' | 'preparing' | 'processing' | 'packing' | 'shipped',
 * }} Order
 * */
export const requestUserOrders = () => async dispatch => {
  dispatch(requestFetchOrders());
  try {
    const orders = /** @type {Array<Order>} */ (await OrdersService.getCompletedOrders());
    dispatch(receiveFetchOrders(orders));
  } catch (err) {
    dispatch(receiveFetchOrdersError(err.code));
    logSentryError(`[dux/orders] requestUserOrders`, err);
  }
};

const shouldFetchOrders = ({ orders }) => {
  if (orders.isLoading) {
    return false;
  }
  if (!orders.orders) {
    return true;
  }
  return orders.didInvalidate;
};

export function fetchOrdersIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchOrders(getState())) {
      return dispatch(requestUserOrders());
    }
    // Dummy action for log purpose
    return dispatch({ type: FETCH_ORDERS_NOT_NEEDED });
  };
}

export const fetchPumpsCustomerPreferences = () => async dispatch => {
  dispatch({ type: FETCH_PUMPS_CUSTOMER_PREFERENCES_REQUEST });
  try {
    const pumpsCustomerPreferences = await OrderService.fetchPumpsCustomerPreferences();
    dispatch({ type: FETCH_PUMPS_CUSTOMER_PREFERENCES_SUCCESS, pumpsCustomerPreferences });
  } catch (error) {
    dispatch({ type: FETCH_PUMPS_CUSTOMER_PREFERENCES_FAILURE, error: error?.message });
    logSentryError(`[dux/orders] fetchPumpsCustomerPreferences`, error);
  }
};

export const setPumpsPreferences =
  ({ category, wantsPumps, isSubscribing = false }) =>
  async (_dispatch, getState) => {
    const state = getState();
    const hasSubscription = cartHasSubscription(state);

    const pumpsProductsCategory =
      category === productsCategories.SKINCARE ? 'skincare_pumps' : 'haircare_pumps';

    try {
      /**
       * Either the user witout subscription subscribes from account
       * Or the user subscribes from cart
       * Choice affects this order and future ones
       */
      if (isSubscribing || hasSubscription) {
        await OrderService.patchPumpsCustomerPreferences({
          [pumpsProductsCategory]: wantsPumps,
        });
      } else {
        // One-off order (no subscription)n choice affects this order only
        await OrderService.patchPumpsPreferences({
          [pumpsProductsCategory]: wantsPumps,
        });
      }
    } catch (error) {
      logSentryError(`[dux/orders] setPumpsPreferences`, error);
    }
  };
