import { forwardRef, useState } from 'react';

import { theme } from '@prose-ui';
import { ClassNames, css, cx, legacyTheme, styled } from '@prose-ui/legacy';

import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import type { StaticImageData } from './Image';

const ImageContainer = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
`;

const Root = styled.div`
  position: relative;

  padding-bottom: 18px;
  min-width: 80px;

  /* stylelint-disable-next-line selector-class-pattern */
  &.checkoutTheme {
    width: 100%;
    max-width: 300px;
    margin-top: ${legacyTheme.spacing.s8};
    margin-bottom: ${legacyTheme.spacing.s8};
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.checkoutOptimizationTheme {
    width: 100%;
    margin: 0;
    padding-bottom: 10px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.checkoutOptimizationThemeError {
    padding-bottom: 14px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.maxWidth {
    max-width: 400px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.fullWidth {
    width: 100%;
  }

  &.margin {
    margin-top: ${legacyTheme.spacing.s8};
    margin-bottom: ${legacyTheme.spacing.s8};
  }

  &.disabled {
    opacity: 0.4;
  }
`;

const InputRoot = styled.div`
  position: relative;
  background: ${theme.colors.neutral[100]};
  min-height: 55px;

  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: solid 0.5px ${theme.colors.neutral[600]};
    box-shadow: 2px 2px 3px 0 ${theme.colors.neutral[600]};
    mix-blend-mode: multiply;
  }

  &.underline {
    &::after {
      pointer-events: none; /* Transparent to the hover style. */
      /* Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242 */
      content: '';

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scaleX(0);

      border-bottom: 2px solid ${theme.colors.primary[400]};

      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &.error::after {
      transform: scaleX(1); /* error is always underlined in red */
      border-bottom-color: ${theme.colors.error[200]};
    }

    &.focused::after {
      transform: scaleX(1);
    }
  }
`;

const Label = styled.label`
  ${legacyTheme.typography.p1};
  color: ${theme.colors.neutral[800]};
  position: absolute;
  top: 0;
  left: ${legacyTheme.spacing.s16};
  transform-origin: top left;
  transform: translate(0, 19px) scale(1);
  pointer-events: none;
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.shrink {
    transform: translate(0, ${legacyTheme.spacing.s8}) scale(0.75);

    overflow: hidden;
    /* If label is > 1 line, we want to show an ellipsis */
    width: 100%;

    color: ${theme.colors.primary[400]};
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.labelError {
    color: ${theme.colors.error[200]};
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.hiddenLabel {
    display: none;
  }
`;

const CharCount = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: ${legacyTheme.spacing.s12};
  color: ${theme.colors.neutral[800]};

  &.alert {
    color: ${theme.colors.error[200]};
  }
`;

const ErrorMessage = styled.p`
  ${legacyTheme.typography.p3};
  color: ${theme.colors.error[200]};
  position: absolute;
  margin: 0;
  left: calc(2 * ${legacyTheme.spacing.s8});
  bottom: -4px;
`;

const InfoMessage = styled.p`
  ${legacyTheme.typography.p3};
  color: ${theme.colors.primary[400]};
  position: absolute;
  margin: 0;
  left: calc(2 * ${legacyTheme.spacing.s8});
  bottom: -4px;
`;

const component = css`
  ${legacyTheme.typography.p1};
  color: ${theme.colors.neutral[800]};
  background: transparent;
  margin-top: 20px;
  width: 100%;
  padding: 4px 16px;
  border: none;
  overflow: hidden;
  white-space: var(--white-space, nowrap);
  text-overflow: ellipsis;
`;

const componentNoLabel = css`
  margin-top: ${legacyTheme.spacing.s16};
`;

type CheckoutOptimisationTheme = typeof CHECKOUT_DEFAULT_THEME | typeof CHECKOUT_OPTIMISATION_THEME;
type ControlsElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

type BaseFieldChildrenProps = {
  id: string;
  value?: string;
  name: string;
  className: string;
  onFocus: (event: React.FocusEvent<ControlsElements>) => void;
  onBlur: (event: React.FocusEvent<ControlsElements>) => void;
  onChange?: (event: React.ChangeEvent<any>) => void;
  disabled: boolean;
};

export type BaseFieldProps = {
  /**
   * Enables to overrides the element inner styles.
   * Use with caution.
   */
  classes?: {
    root?: string;
    inputRoot?: string;
    component?: string;
  };
  className?: string;

  /**
   * Function to render the element, will be called with the component props
   */
  children: (props: BaseFieldChildrenProps) => React.ReactNode;

  /**
   * Special disabled state UI
   */
  disabled?: boolean;

  /**
   * Label will be hidden, this is an a11y feature
   */
  hiddenLabel?: boolean;

  /**
   * The id of the children component.
   * Use this property to make `label` and `helperText` accessible for screen readers.
   */
  id: string;

  /**
   * An image on the right of the input.
   */
  image?: {
    src: string | StaticImageData;
    alt: string;
  };

  /**
   * The label content.
   */
  label?: React.ReactNode;

  /**
   * The value of the `Input` element, required for a controlled component.
   */
  value?: string;

  /**
   * The error  message to display under the component.
   * Error message have priority over info.
   */
  error?: string;

  /**
   * The info message to display under the component.
   * Info message are overriden by error message.
   */
  info?: string;

  /**
   * The element will have width 100%.
   * Limited by the maxWidth prop.
   */
  fullWidth?: boolean;

  /**
   * The element will have a max width of 400px.
   * True by default for backward comp purposes.
   * To be deprecated because too much contextual.
   */
  maxWidth?: boolean;

  /**
   * The element will receive a default margin
   * (top and bottom).
   */
  margin?: boolean;

  /**
   * add an UI for characters remaining
   */
  maxCount?: number;
  maxCountAlert?: number;

  onFocus?: BaseFieldChildrenProps['onFocus'];
  onBlur?: BaseFieldChildrenProps['onBlur'];
  onChange?: BaseFieldChildrenProps['onChange'];

  /**
   * Changes the element appearance
   */
  theme?: CheckoutOptimisationTheme;

  /**
   * Controls text wrapping. Non exhaustive list to be updated as needed
   */
  whiteSpace?: 'nowrap' | 'normal';
};

/**
 * Currently used a base for all *Field components.
 * Cannot be used alone, have to be extended to be usefull.
 * default max width: **400px**.
 */
export const BaseField = forwardRef<HTMLDivElement, BaseFieldProps>(
  (
    {
      className,
      classes,
      children,
      disabled = false,
      id,
      image,
      label,
      value,
      error,
      fullWidth = false,
      hiddenLabel = false,
      info,
      margin = false,
      maxCount,
      maxCountAlert = 9,
      maxWidth = true,
      onFocus,
      onBlur,
      theme: variant,
      whiteSpace = 'nowrap',
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (event: React.FocusEvent<ControlsElements>) => {
      if (!focused) {
        setFocused(true);
      }
      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = (event: React.FocusEvent<ControlsElements>) => {
      if (focused) {
        setFocused(false);
      }
      if (onBlur) {
        onBlur(event);
      }
    };

    const childrenProps = {
      id,
      value,
      name: id,
      onFocus: handleFocus,
      onBlur: handleBlur,
      disabled,
      ...props,
    };

    return (
      <Root
        ref={ref}
        className={cx(
          classes?.root,
          {
            checkoutTheme: variant === CHECKOUT_DEFAULT_THEME,
            maxWidth,
            fullWidth,
            margin,
            disabled,
            checkoutOptimisationTheme: variant === CHECKOUT_OPTIMISATION_THEME,
            checkoutOptimisationThemeError: variant === CHECKOUT_OPTIMISATION_THEME && error,
          },
          className,
        )}
        style={{ '--white-space': whiteSpace }}
      >
        <InputRoot
          className={cx(
            classes?.inputRoot,
            /* INFO: both classes are required for visual styling */
            {
              focused,
              error: Boolean(error),
            },
            'underline',
          )}
        >
          {label && (
            <Label
              className={cx({
                shrink: value || focused,
                labelError: Boolean(error),
                hiddenLabel,
              })}
              htmlFor={id}
            >
              {label}
            </Label>
          )}
          <ClassNames>
            {({ cx, css }) =>
              children({
                ...childrenProps,
                className: cx(
                  css(component),
                  classes?.component,
                  (!label || hiddenLabel) && css(componentNoLabel),
                ),
              })
            }
          </ClassNames>
          {image && (
            <ImageContainer>
              <img
                alt={image.alt}
                src={typeof image.src === 'string' ? image.src : image.src.src}
              />
            </ImageContainer>
          )}
          {maxCount && (
            <CharCount
              className={cx({
                alert: value && maxCount - value.length <= maxCountAlert,
              })}
            >
              {value && maxCount - value.length}
            </CharCount>
          )}
        </InputRoot>
        {error && (
          <ErrorMessage
            aria-live="assertive"
            className={cx({
              errorMessageCheckoutOptimisation: variant === CHECKOUT_OPTIMISATION_THEME,
            })}
            data-testid="field-error"
            role="alert"
          >
            {error}
          </ErrorMessage>
        )}
        {info && !error && <InfoMessage>{info}</InfoMessage>}
      </Root>
    );
  },
);

BaseField.displayName = 'BaseField';
