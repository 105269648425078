import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import { SelectField } from 'Components/SelectField';

type CountrySelectProps = {
  ariaInvalid?: boolean; // Meta data for screen readers
  ariaRequired?: boolean; // Meta data for screen readers
  error?: string;
  label?: string;
  list?: {
    label: string;
    value: string;
  }[];
  onChange: () => void;
  testId?: string;
  theme?: typeof CHECKOUT_DEFAULT_THEME | typeof CHECKOUT_OPTIMISATION_THEME;
  value?: string;
};

export const CountrySelect = ({
  ariaInvalid = false,
  ariaRequired = false,
  error,
  label,
  list = [],
  onChange,
  testId,
  theme,
  value,
}: CountrySelectProps) => (
  <SelectField
    aria-invalid={ariaInvalid ? 'true' : 'false'}
    aria-required={ariaRequired ? 'true' : 'false'}
    data-testid={testId}
    error={error}
    id="country"
    label={label}
    list={list}
    onChange={onChange}
    theme={theme}
    value={value}
  />
);
