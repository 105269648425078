import dayjs, { type ConfigType, Dayjs } from 'dayjs';

import { countriesCode } from 'constants/countries';

import config from '../Config';

/** Plural
 * Params
 *  quantity: Int - value to be tested
 *  displayQuantity: Bool - Should the value be returned in the string
 *  singular: String - Singular form of the word
 *  pluralForm: String - If provided will be returned for quantity > 1
 *    or it will default to singular with an -s
 */
export const plural = (
  quantity: number,
  displayQuantity: boolean,
  singular: string,
  pluralForm?: string,
): string => {
  let s = '';

  if (displayQuantity) {
    s = `${quantity} `;
  }

  if (quantity < 2) {
    s += singular;
  } else if (pluralForm) {
    s += pluralForm;
  } else {
    s += `${singular}s`;
  }

  return s;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/** Genitive
 * Decline the name to the Genitive case
 *  Heidi -> Heidi's
 *  Boris -> Boris'
 */
export const genitive = (name: string): string =>
  name[name.length - 1] === 's' ? `${name}’` : `${name}’s`;

/** list to comma separated string last one and
 * ['a', 'b', 'c'] => 'a, b and c'
 * ['a'] => 'a'
 */
export const commasAndListToString = (list: string[]): string | undefined => {
  if (list.length === 1) return list[0];
  const allButLast = list.slice(0, list.length - 1);
  const last = list[list.length - 1];
  return `${allButLast.join(', ')} and ${last}`;
};

// extract only digits from string
export const parseDigits = (str: string): string => (str.match(/\d+/g) || []).join('');

export const parseLetters = (str: string): string => (str.match(/[A-Za-z]+/g) || []).join('');

// format full phone number, fail if not valid
export const formatPhone = (str: string): string | null => {
  if (typeof str !== 'string') {
    throw new Error('formatPhone: Expected a string input');
  }

  const digits = parseDigits(str);

  if (digits.length !== 10) {
    throw new Error('formatPhone: Phone number must be 10 digits');
  }

  const match = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match !== null) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

// format phone number as you type
export const formatPhoneInput = (str: string): string => {
  const digits = parseDigits(str);

  if (digits.length < 3) {
    return digits;
  }
  if (digits.length === 3) {
    return `(${digits})`;
  }
  if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}`;
  }
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

export const addWeekdays = (dateInput: ConfigType, daysInput: number): Dayjs => {
  let date = dayjs(dateInput) || dayjs();
  let days = daysInput || config.shippingDelay;

  while (days > 0) {
    date = date.add(1, 'days');
    // skip saturday and sunday
    if (![6, 0].includes(date.day())) {
      days -= 1;
    }
  }

  return date;
};

const formatCanadaZipcode = ({
  value,
  previousValue,
}: {
  value: string;
  previousValue: string;
}): string => {
  if (value?.length === 3 && previousValue?.length === 2) {
    return `${value?.toUpperCase()} `;
  }
  // This condition to avoid the user being blocked at the space step when deleting the zipcode
  if (value?.length === 4 && previousValue?.length === 3 && value?.[value.length - 1] !== ' ') {
    return `${value.slice(0, 3).toUpperCase()} ${value[3]?.toUpperCase()}`;
  }
  // The first part of the condition is to prevent the user from entering multiple spaces in between the zipcode
  if ((value?.length === 5 && value?.[value.length - 1] === ' ') || value.length > 7) {
    return previousValue;
  }
  return value?.toUpperCase();
};

export const formatZipcodeByCountryCode = (
  payload: { value: string; previousValue: string },
  country: string,
): string => {
  switch (country) {
    case countriesCode.CA:
      return formatCanadaZipcode(payload);
    default:
      return payload.value;
  }
};

export const convertArrayToStringWithComas = (array: string[]): string =>
  array.reduce((acc, curr, i) => (i === 0 ? `${curr}` : `${acc}, ${curr}`));
