// import reviewsLogoEsquireImg from 'assets/images/logo_esquire.png';
import aloeVeraJuiceImg from 'assets/images/productPages/ingredients/aloe-vera-juice.png';
import appleCider from 'assets/images/productPages/ingredients/apple_cider_vinegar.png';
import inuline from 'assets/images/productPages/ingredients/arginine.png';
import cornStarch from 'assets/images/productPages/ingredients/corn-starch.png';
import riceWater from 'assets/images/productPages/ingredients/fermented_rice_water.png';
import cornMaldtodextrine from 'assets/images/productPages/ingredients/maltodextrin.png';
import oatFlourImg from 'assets/images/productPages/ingredients/oat-flour.png';
import okra from 'assets/images/productPages/ingredients/okra.png';
import pea from 'assets/images/productPages/ingredients/pea-protein.png';
import quinoa from 'assets/images/productPages/ingredients/quinoa-extract.png';
import redAglae from 'assets/images/productPages/ingredients/red-algae.png';
import sunflowerSeeds from 'assets/images/productPages/ingredients/sunflower-seeds.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-styling-gel.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-styling-gel-close-up.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-styling-gel-products.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-posing-with-custom-styling-gel-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-posing-with-custom-styling-gel-mobile.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-using-custom-styling-gel.jpg';
import carouselImg5 from 'assets/images/productPages/prose-ugc-stylinggel.jpg';

export const stylingGelPageContent = {
  sampleFormulasSlider: [
    {
      title: "YAELE'S DEFINING + MOISTURIZING STYLING GEL",
      ingredients: [
        {
          image: oatFlourImg,
          name: 'Oat Protein',
          benefit: 'Deep Moisture',
        },
        {
          image: cornMaldtodextrine,
          name: 'Corn Maltodextrine',
          benefit: 'Strong Touchable Hold',
        },
        {
          image: riceWater,
          name: 'Fermented Rice Water',
          benefit: 'Hair Strength',
        },
        {
          image: pea,
          name: 'Pea Protein',
          benefit: 'Heat Protection',
        },
        {
          image: redAglae,
          name: 'Red Aglae',
          benefit: 'Anti-Pollution',
        },
        {
          image: okra,
          name: 'Okra',
          benefit: 'Coil Definition',
        },
      ],
    },
    {
      title: "ALEXIS'S SMOOTHING + STRENGTHENING STYLING GEL",
      ingredients: [
        {
          image: cornMaldtodextrine,
          name: 'Corn Maltodextrine',
          benefit: 'Medium Touchable Hold',
        },
        {
          image: riceWater,
          name: 'Fermented Rice Water',
          benefit: 'Frizz Control',
        },
        {
          image: pea,
          name: 'Pea Protein',
          benefit: 'Strenghtening',
        },
        {
          image: aloeVeraJuiceImg,
          name: 'Aloe Vera',
          benefit: 'Hydration',
        },
        {
          image: inuline,
          name: 'Inuline',
          benefit: 'Smoothing',
        },
        {
          image: redAglae,
          name: 'Sea Moss',
          benefit: 'Curl Definition',
        },
      ],
    },
    {
      title: "EMILY'S HYDRATING + FRIZZ CONTROLLING STYLING GEL",
      ingredients: [
        {
          image: redAglae,
          name: 'Sea Moss',
          benefit: 'Coil Definition',
        },
        {
          image: appleCider,
          name: 'Apple Cider Vinegar',
          benefit: 'Shine',
        },
        {
          image: redAglae,
          name: 'Red Algae',
          benefit: 'Moisture Retention',
        },
        {
          image: cornStarch,
          name: 'Corn Starch',
          benefit: 'Frizz Control',
        },
        {
          image: quinoa,
          name: 'Quinoa',
          benefit: 'Heat Protection',
        },
        {
          image: sunflowerSeeds,
          name: 'Sunflower Seed',
          benefit: 'Antioxidant',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'vert',
      number: '316',
      description:
        'To refresh curls or slicked back styles, mist hair with water, section by section, before applying gel.',
    },
    {
      background: 'vert',
      number: '586',
      description:
        'In need of a haircut? As the last step of your daily styling routine, apply a bit of styling gel on the ends of curls or coils to help protect fragile split ends until your next trim. The gel will smooth hair fibers and create a barrier that helps to protect against future splitting.',
    },
    {
      background: 'vert',
      number: '701',
      description:
        'To add more shine and softness to  twists or braids, combine styling gel and curl cream in your palm and work the mixture through your hair.',
    },
  ],
  faq: [
    {
      question: 'What hair textures does Prose Custom Styling Gel work for?',
      answer:
        'Prose Styling Gel is custom formulated for versatile styling on textures ranging from waves to coils (and everything in between). Personalized based on your preferences, it provides the right amount of hold without flakes or crunch.',
    },
    {
      question: 'How do I use Styling Gel?',
      answer:
        "Prose Styling Gel is versatile and can be used to create and maintain a variety of hairstyles. This includes, but isn't limited to: wash n'gos, slicked styles, braids, edges, and twists. For best results, we recommend applying it on wet hair.",
    },
    {
      question: 'Can I layer my gel with other styling products?',
      answer: (
        <>
          Yes! Prose Styling Gel fits seamlessly into your haircare routine. Layer it with other
          stylers such as Curl Cream or Leave-in Conditioner for extra shine, hold, and definition
          plus nourishment for your strands. You can learn more about how to layer different styling
          products <a href="https://prose.com/blog/loc-method-hair.php">here</a>.
        </>
      ),
    },
    {
      question: 'How much hold does Styling Gel provide?',
      answer:
        "Your Prose Styling Gel will be customized to provide the ideal level of hold for your hair based on the results of your consultation. And don't worry–it'll never flake or crunch!",
    },
    {
      question: "Why does Prose Styling Gel look different from other gels that I've used?",
      answer:
        "Prose Styling Gel is an innovative formula with natural ingredients that can cause variations in each batch. The product may look cloudy, but that's normal. The color has no impact on the formula's freshness, efficacy, or consistency.",
    },
    {
      question: 'What ingredients are NOT included in Prose Custom Styling Gel formulas?',
      answer:
        'Prose Custom Styling Gel is customized to your needs using clean, innovative ingredients to provide hold, care, and protection. It will always be made without the following: oil, silicone, alcohol, phthalates, GMOs, sulfates, parabens, phthalates, and mineral oil. Like all Prose products, the Styling Gel is cruelty-free.',
    },
    {
      question: 'Can Prose Custom Styling Gel treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  formulaModule: {
    header: (
      <>
        A personalized styling gel,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every styling gel we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model posing with container of custom styling gel',
    },
  },
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom styling gel packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom styling gel packaging shown close up',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom styling gel',
    },
    {
      image: carouselImg4,
      alt: 'Assortment of Prose custom styling gel containers',
    },
    {
      image: carouselImg5,
      alt: 'Models posing with custom styling gel bottles',
    },
  ],
  numbersModule: {
    title: 'A styling gel scientifically tested for efficacy',
    numbers: [
      { number: '92%', label: 'agree their hair was easy to style' },
      { number: '91%', label: 'agree that the gel doesn’t flake' },
      { number: '88%', label: 'agree the gel works on different hairstyles' },
      { number: '84%', label: 'agree that their curls & coils were more defined' },
      { number: '82%', label: 'noticed less frizz' },
      { number: '86%', label: 'noticed less shrinkage' },
    ],
    disclaimer:
      '*Results of use test performed on 104 subjects with hair type 2C to 4C after 15 days of use (somewhat agree to strongly agree).',
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Styling Gel is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A styling gel with naturally powerful + proven-effective ingredients',
  },
};
