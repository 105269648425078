import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      display: 'flex',
      [legacyTheme.breakpoints.match('md')]: {
        flexDirection: 'column',
      },
    },
    half: {
      width: '50%',
      [legacyTheme.breakpoints.match('md')]: {
        width: '100%',
      },
      '&.wide-order-1': {
        order: 1,
        [legacyTheme.breakpoints.match('md')]: {
          order: 'inherit',
        },
      },
      '&.wide-order-2': {
        order: 2,
        [legacyTheme.breakpoints.match('md')]: {
          order: 'inherit',
        },
      },
    },
  },
  { name: 'TwoUp' }
);

/**
  Dispose two elements on top of each other for narrow screens. For wide screens, each element
  takes half the screen.
*/
const TwoUp = forwardRef(
  ({ backgroundColor, children, style, wideScreenOrder, screenWidth, ...props }, ref) => {
    if (children?.length !== 2) {
      throw new Error('TwoUp component expect a two element array as children');
    }

    // style override through classes prop
    const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);
    return (
      <div
        ref={ref}
        className={classes.root}
        style={{
          backgroundColor: theme.colors.legacy[backgroundColor] || backgroundColor,
          ...style,
        }}
        {...props}
      >
        <div
          className={cx(classes.half, `wide-order-${wideScreenOrder.charAt(0)}`)}
          data-testid="slides-left"
          style={{ width: screenWidth?.[wideScreenOrder.charAt(0)] }}
        >
          {children[0]}
        </div>
        <div
          className={cx(classes.half, `wide-order-${wideScreenOrder.charAt(1)}`)}
          style={{ width: screenWidth?.[wideScreenOrder.charAt(1)] }}
        >
          {children[1]}
        </div>
      </div>
    );
  }
);

TwoUp.displayName = 'TwoUp';

TwoUp.defaultProps = {
  backgroundColor: null,
  style: {},
  wideScreenOrder: '12',
  classes: null,
  screenWidth: null,
};

TwoUp.propTypes = {
  /**
    Background color of the blocks (if specified)
  */
  backgroundColor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  /** For style overriding purposes */
  classes: PropTypes.objectOf(PropTypes.string),
  style: PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
  /**
    Order of the blocks
  */
  wideScreenOrder: PropTypes.oneOf(['12', '21']),
  screenWidth: PropTypes.shape({ 1: PropTypes.string, 2: PropTypes.string }),
};

/**
 * @deprecated Please use TwoUp instead.
 */
export default TwoUp;
