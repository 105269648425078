import { productsSlugs } from 'constants/products';
import * as consultationComponents from '../constants/consultationComponents';
import * as consultationTipsComponents from '../constants/consultationTipsComponents';
import { PREFERENCES_GOALS } from '../constants/progressCategories';

import gelMoisturizer from 'assets/images/consultation/moisturizer/tip-gel-moisturizer.jpg';
import lightMoisturizer from 'assets/images/consultation/moisturizer/tip-light-moisturizer.png';
import richMoisturizer from 'assets/images/consultation/moisturizer/tip-rich-moisturizer.png';

const moisturizerTextures = {
  Component: consultationComponents.SkincareTextures,
  productSlug: productsSlugs.MOISTURIZER,
  name: 'moisturizerTextures',
  category: PREFERENCES_GOALS,
  progressCategory: PREFERENCES_GOALS,
  route: `/consultation/skincare/${PREFERENCES_GOALS}/moisturizer-preferences`,
  title: 'Choose your moisturizer texture:',
  subtitle: 'Color and scent can vary slightly from formula to formula.',
  tip: {
    title: 'Need help deciding?',
    richContent: {
      Component: consultationTipsComponents.SliderOfSlider,
      contentComesFirst: true,
      sliders: [
        {
          sliderId: 'gel',
          sliderName: 'Gel Cream',
          sliderTitleLegend: 'Gel Cream Moisturizer',
          sliderBodyLegend:
            'Our Gel cream has a light texture that feels weightless and leaves your skin moisturized and soft, perfect for oil-prone skin.',
          slidesContent: [
            {
              id: 'gelCream',
              heapTracker: 'gelCream',
              image: {
                src: gelMoisturizer,
                alt: 'gelCream',
              },
            },
          ],
        },
        {
          sliderId: 'Cream',
          sliderName: 'Cream',
          sliderTitleLegend: 'Cream Moisturizer',
          sliderBodyLegend:
            'Our Cream has a classic texture that absorbs quickly and leaves your skin feeling smooth and well-hydrated.',
          slidesContent: [
            {
              id: 'cream',
              heapTracker: 'cream',
              image: {
                src: lightMoisturizer,
                alt: 'cream',
              },
            },
          ],
        },
        {
          sliderId: 'richMoisturizer',
          sliderName: 'Rich Cream',
          sliderTitleLegend: 'Rich Cream Moisturizer',
          sliderBodyLegend:
            'Our Rich Cream has a luxuriously buttery texture that leaves your skin feeling protected without the shine, perfect for skin that’s prone to dryness.',
          slidesContent: [
            {
              id: 'richMoisturizer',
              heapTracker: 'richMoisturizer',
              image: {
                src: richMoisturizer,
                alt: 'richMoisturizer',
              },
            },
          ],
        },
      ],
    },
  },
  shouldBeIncluded: () => true,
  getInitialValue: answers => ({
    pref_texture_moisturizer: answers?.pref_texture_moisturizer,
  }),
  getAnswers: ({ selected }) => ({
    pref_texture_moisturizer: selected,
  }),
};

export default moisturizerTextures;
