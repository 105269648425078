import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import isEmpty from 'lodash/fp/isEmpty';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import type { ShippingAddress } from 'types/shippingAddress';

import { CHECKOUT_ADDRESS_ENTERED } from 'Apps/Checkout/constants/checkoutAddressValidationStatus';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';

import { AddressRadio } from 'Components/AddressRadio';
import { BasicModal } from 'Components/BasicModal';
import { Button } from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import { Typography } from 'Components/Typography';

import {
  resetAddressChoice,
  resetAddressInvalidFields,
  validateAddressChoice,
} from 'dux/checkoutAddressesValidation/actions';
import { trackHeapEvent } from 'dux/tracking/actions';
import { getAddressValidationRecommendedFields } from 'dux/checkoutAddressesValidation/selectors';
import * as featureFlagsSelectors from 'dux/featureFlags/selectors';

type CheckoutSteps = typeof import('Apps/Checkout/constants/checkoutSteps');

const StyledModal = styled(BasicModal)`
  border-radius: 4px;
`;

const AddressOption = styled(Typography)`
  padding: ${legacyTheme.spacing.s16} ${legacyTheme.spacing.s24};

  border: 1px solid ${theme.colors.neutral[600]};
  border-radius: 4px;

  ${legacyTheme.breakpoints.up('sm')} {
    width: 530px;
  }
`;

const FlexBox = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    display: flex;
  }
`;

type AddressFields = {
  address1: string;
  city: string;
  state: string;
  zipcode: string;
};
const setAddress =
  (setFieldValue: (field: string, value: string) => null) => (address: AddressFields) => {
    const fields = ['address1', 'city', 'zipcode'] as const;
    fields.forEach((field) => {
      setFieldValue(`newAddress.${field}`, address[field] || '');
    });
  };

type CheckoutStep = CheckoutSteps[keyof CheckoutSteps];

type AddressValidationModalProps = {
  address: ShippingAddress;
  isSubmitting: boolean;
  step: CheckoutStep;
  submit: Function;
  setFieldValue: (value: string) => null;
};

export const RecommendedAddressModal = ({
  address,
  isSubmitting,
  step,
  setFieldValue,
  submit,
}: AddressValidationModalProps) => {
  const dispatch = useAppDispatch();
  const addressRecommendedFields = useAppSelector(getAddressValidationRecommendedFields);
  const shouldShowAddressValidation = useAppSelector(
    // @ts-expect-error - Selectors has no TS types for now
    featureFlagsSelectors.shouldShowAddressValidation,
  );
  const [isOpen, setIsOpen] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<'entered' | 'recommended'>('recommended');

  const closeModal = () => {
    dispatch(resetAddressInvalidFields());
    setSelectedAddress('recommended');
    setIsOpen(false);
  };

  const confirmRecommendedAddress = async () => {
    if (selectedAddress === 'recommended') {
      const newFields = {
        ...addressRecommendedFields,
      };

      setAddress(setFieldValue)(newFields);
    }

    await dispatch(validateAddressChoice(CHECKOUT_ADDRESS_ENTERED));
    submit();
    closeModal();
  };

  useEffect(() => {
    if (
      step === checkoutSteps.SHIPPING_ADDRESS &&
      shouldShowAddressValidation &&
      !isEmpty(address) &&
      !isEmpty(addressRecommendedFields)
    ) {
      setIsOpen(true);
      dispatch(trackHeapEvent('Questionable address modal viewed'));
    }
    if (step !== checkoutSteps.SHIPPING_ADDRESS) {
      /**
       * Every time we reach the shipping address step
       * we want to run the address validation
       */
      dispatch(resetAddressChoice());
    }
  }, [addressRecommendedFields, step, shouldShowAddressValidation]);

  return (
    isOpen && (
      <StyledModal isOpen={isOpen} onClose={closeModal}>
        <Typography
          data-testid="recommended-address-modal-title"
          id={isOpen ? 'modal-title' : ''}
          markupName="h2"
          variant="h2"
        >
          Please confirm your address
        </Typography>

        <div id={isOpen ? 'modal-description' : ''}>
          <Spacer size={16} />

          <AddressOption markupName="p" variant="p1">
            <AddressRadio
              aria-label="Choose entered address"
              dataTestId="recommended-address-modal-radio-entered"
              onClick={() => setSelectedAddress('entered')}
              selected={selectedAddress === 'entered'}
            >
              <Typography variant="mono3">You entered</Typography>
              <Spacer size={8} />
              {address.address1}
              <br />
              {address.city}, {address.state}, {address.zipcode}
            </AddressRadio>
          </AddressOption>

          <Spacer size={16} />

          <AddressOption markupName="p" variant="p1">
            <AddressRadio
              aria-label="Choose recommended address"
              dataTestId="recommended-address-modal-radio-recommended"
              onClick={() => setSelectedAddress('recommended')}
              selected={selectedAddress === 'recommended'}
            >
              <Typography variant="mono3">Recommended</Typography>
              <Spacer size={8} />
              {addressRecommendedFields.address1}
              <br />
              {addressRecommendedFields.city}, {addressRecommendedFields.state},{' '}
              {addressRecommendedFields.zipcode}
            </AddressRadio>
          </AddressOption>
        </div>

        <Spacer size={16} />

        <FlexBox>
          <Button
            data-testid="recommended-address-modal-button-back"
            disabled={isSubmitting}
            fullWidth
            isLoading={isSubmitting}
            noMargin
            onClick={closeModal}
            variant="noir"
          >
            Back
          </Button>
          <Spacer size={22} />
          <Button
            data-testid="recommended-address-modal-button-confirm"
            fullWidth
            noMargin
            onClick={confirmRecommendedAddress}
            variant="vert"
          >
            Confirm
          </Button>
        </FlexBox>
      </StyledModal>
    )
  );
};
