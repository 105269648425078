import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const firmness = {
  name: 'firmness',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/firmness`,
  title: "Let's assess your skin's firmness:",
  subtitle: 'Select all that apply.',
  tip: {
    title: 'Let’s assess your skin’s firmness:',
    content:
      'With your face relaxed, gently pinch the fleshy part of your cheek and hold for 5 seconds. Release and count the time it takes to completely flatten back out. If it takes less than a full second, we consider that “quickly”. The speed that your skin flattens back out speaks directly to your skin elasticity.',
  },
  options: [
    {
      value: 'quickly_back',
      label: 'When I pinch my cheek, my skin bounces back quickly',
    },
    {
      value: 'slowly_back',
      label: 'When I pinch my cheek, my skin holds the shape for a moment',
    },
    {
      value: 'pillow',
      label: 'I often wake up with pillow lines on my face',
    },
    {
      value: 'u_shape',
      label: 'I’ve noticed that my jawline has softened',
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('lack_firmness'),
  getInitialValue: answers => answers.diag_firmnesses,
  getAnswers: ({ selected }) => ({
    diag_firmnesses: selected,
  }),
  multi: true,
};

export default firmness;
