import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { useStyles, withStyles } from 'legacyStyles';

const styles = {
  root: {
    ...legacyTheme.typography.h1r,
    maxWidth: 661,
    margin: '14px 0',
    textAlign: 'center',
    color: theme.colors.primary[400],

    '& sup': {
      top: '-0.7em',
      fontFamily: legacyTheme.typography.simplonNorm,
      fontSize: 14,
      [legacyTheme.breakpoints.up('md')]: {
        top: '-0.9em',
      },
    },
  },
  large: {
    maxWidth: 915,
  },
  white: {
    color: theme.colors.neutral[100],
  },
};

const Title = ({ classes, children, dataTestid, forceLarge, theme: variant }) => {
  const { cx } = useStyles();

  return (
    <h1
      className={cx(classes.root, {
        [classes.large]: children?.length > 60 || forceLarge,
        [classes.white]: variant === 'white',
      })}
      data-testid={dataTestid}
    >
      {children}
    </h1>
  );
};

Title.defaultProps = {
  dataTestid: '',
  forceLarge: false,
  theme: 'noir',
};

Title.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
  forceLarge: PropTypes.bool,
  theme: PropTypes.oneOf(['noir', 'white']),
};

export default withStyles(Title, styles);
