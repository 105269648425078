import { MY_LIFESTYLE } from '../../constants';
import { LIFESTYLE } from '../../constants/progressCategories';

import image from 'assets/images/consultation/age/skinAgeQuestion.png';

const age = {
  name: 'age',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  route: `/consultation/skincare/${MY_LIFESTYLE}/age`,
  title: 'Let’s start personalizing your skincare',
  subtitle: 'From millions of possible formulas, only one is uniquely yours.',
  guideText: 'First, please select your age: ',
  descriptionImage: {
    alt: 'Prose skincare set',
    source: image,
    width: 117,
    height: 87,
  },
  tip: {
    title: 'Why we ask:',
    content: `Over time, hormonal fluctuations and decreased collagen can change the texture, density, and strength of our skin. We’ll use this info to give you extra support at any stage.

&nbsp;

*Please note our skincare products are not intended for use by individuals under the age of 18. These products have not been specifically formulated or tested for this age group.`,
    couldBounce: true,
  },
  options: [
    { value: '20', label: 'Under 30' },
    { value: '30', label: 'In my 30s' },
    { value: '40', label: 'In my 40s' },
    { value: '50', label: 'In my 50s' },
    { value: '60', label: 'In my 60s' },
    { value: '70', label: '70 or over' },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.profile_age_range,
  getAnswers: ({ selected }) => ({ profile_age_range: selected }),
  public: true,
};

export default age;
