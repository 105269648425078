import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

const zitsCause = {
  name: 'zits_cause',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/zits-cause`,
  title: 'Have any of these triggered your breakouts in the last 3 months?',
  subtitle: 'Select all that apply. This will help us assess if you’re experiencing hormonal acne.',
  tip: {
    title: 'why we ask:',
    content: `
Learning more about the triggers of your breakouts will help us identify the right actives to include in your formulas. Hormonal acne, for example, often calls for antioxidant-rich ingredients that help soothe, reduce redness, and calm skin.

&nbsp;

Be assured, we’ll never share this data — we just want to create a routine that’s truly tailored to you.
`,
  },
  options: [
    {
      value: 'zits_medication',
      label: 'Medication',
    },
    {
      value: 'zits_stress',
      label: 'Stress',
    },
    {
      value: 'zits_menstrual_cycle',
      label: 'Menstrual cycle',
    },
    {
      value: 'none',
      label: 'None of these apply to me',
      unique: true,
    },
  ],
  shouldBeIncluded: ({ answers }) => answers?.diag_concerns?.includes('breakouts'),
  getInitialValue: answers => answers.diag_zits_cause,
  getAnswers: ({ selected }) => ({
    diag_zits_cause: selected,
  }),
  multi: true,
};

export default zitsCause;
