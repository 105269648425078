import { useAppSelector } from 'dux/app/hooks';

import lowerCase from 'lodash/lowerCase';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { Button } from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import TicketLineV2 from 'Components/TicketLineV2';
import { Typography } from 'Components/Typography';
import OrderSummaryPromoCommunication from '../Components/OrderSummaryPromoCommunication';

import { countriesCode } from 'constants/countries';
import { productsSlugs, skincareMinisSlugs } from 'constants/products';
import { getFrequencyLabelForValue } from 'constants/subscriptions';

import { TicketGroupedLines } from 'Blocks/TicketGroupedLines';

import { plural } from 'utils/textUtils';
import useResponsiveVariant from 'utils/useResponsiveVariant';

import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import { getProductImageForProduct } from 'dux/products/selectors';

import useCartOrderSummaryInfosByCartType from '../hooks/useCartOrderSummaryInfosByCartType';

const TicketBorder = styled.hr`
  margin: 16px 0;
  border: none;
  border-top: 1px solid ${theme.colors.neutral[600]};

  ${legacyTheme.breakpoints.up('sm')} {
    margin: 24px 0;
  }
`;

const ProductsContainer = styled.div<{ hasPaddingTop?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: ${({ hasPaddingTop }) => hasPaddingTop && '16px'};
`;

const TotalPricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CTAInTicket = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SatisfactionGuaranteeMessage = styled(Typography)`
  font-size: 10px;
`;

type CartOrderSummaryProps = {
  ctaButtonProps?: {
    text: string;
    disabled: boolean;
    isLoading: boolean;
    onClick: () => void;
    type: string;
    variant: string;
  };
  wantsPumps?: boolean;
  isCheckout?: boolean;
};

export const CartOrderSummary = ({
  ctaButtonProps,
  isCheckout = false,
  wantsPumps = true,
}: CartOrderSummaryProps) => {
  const {
    cart,
    currency,
    freeOrder,
    creditCoupons,
    offerCoupons,
    cartProducts,
    cartProductsQuantity,
    giftsWithPurchase,
    mustHaveJarsInCart,
    shouldProposePumps,
    subscriptionCoupons,
    cartHasSubscription,
    deliveryBy,
    hasItemsFromGiftSet,
    isCartV2,
    giftCards,
    isTaxComputed,
  } = useCartOrderSummaryInfosByCartType();
  const { isMobile } = useResponsiveVariant();

  const isMobileCheckout = isCheckout && isMobile;

  const getProductImage = useAppSelector(getProductImageForProduct);

  const consultationProfileCountry = useAppSelector(
    checkoutCartSelectors.getConsultationProfileCountryInCartSurvey,
  );

  const shouldDisplayTaxes = isCheckout && isTaxComputed;
  const total = shouldDisplayTaxes
    ? cart.payment_from_customer
    : cart.total_price + cart.total_shipping;
  const totalAmount = cart.total_amount;
  const totalShipping = cart.total_shipping;
  const totalTaxes = cart.total_taxes;

  return (
    <>
      {!isCartV2 && !isCheckout && !isMobile && <OrderSummaryPromoCommunication />}
      {!isCartV2 && (!cartProducts || cartProducts.length === 0) && (
        <Typography align="center" color="grey">
          Your cart is empty
        </Typography>
      )}

      <ProductsContainer
        data-testid="gift-with-purchase-cart-summary"
        hasPaddingTop={isMobileCheckout}
      >
        {giftsWithPurchase?.map((gwp) => (
          <TicketLineV2
            key={gwp.variant?.type || gwp.offered_variant_type}
            label={`(1) ${gwp.label || gwp.variant?.label}`}
            price={gwp.total_price_display || gwp.total_price}
          />
        ))}

        {cartProducts?.length > 0 &&
          // @ts-expect-error useCartOrderSummaryInfosByCartType and checkout selectors are not typed yet
          cartProducts.map((product) => {
            const hidePumpsImage =
              !isCartV2 && !cartHasSubscription && shouldProposePumps && !wantsPumps;

            const productImage = isCheckout
              ? getProductImage({
                  product,
                  noPumps: hidePumpsImage,
                  mustHaveJars: mustHaveJarsInCart,
                })
              : null;

            const productLabel = `(${product.quantity})\u00a0${product.label}`;
            const productKey = `${product.variant_slug || product.product_type || product.type}-${
              product.category
            }`;
            return (
              <TicketLineV2
                key={`${productKey}-ticket-line`}
                currency={currency}
                dataTestId={`order-summary-${productKey}-${
                  hidePumpsImage ? 'without' : 'with'
                }-pumps`}
                label={productLabel}
                price={product.total_price}
                priceDisplay={product.total_price_display}
                productKey={productKey}
                shipmentLabel={
                  // minis are subscribable but next subscription orders will be full size products
                  !skincareMinisSlugs.includes(product.type) && product.subscription?.frequency
                    ? `Ships ${lowerCase(
                        getFrequencyLabelForValue({
                          value: product.subscription?.frequency,
                          isSupplements: product.type === productsSlugs.SUPPLEMENT_CORE,
                        }),
                      )}`
                    : undefined
                }
                sideImage={productImage}
              />
            );
          })}
      </ProductsContainer>

      {(!isCartV2 || Boolean(cartProducts?.length)) && <TicketBorder />}

      <TotalPricesContainer>
        {/* Used on order history and cart success */}
        <TicketLineV2
          currency={currency}
          label={`Subtotal (${plural(cartProductsQuantity, true, 'item')})`}
          price={totalAmount}
        />

        {!hasItemsFromGiftSet && (
          <TicketGroupedLines
            currency={currency}
            groupName="discounts"
            items={[
              ...(cartHasSubscription ? subscriptionCoupons : []),
              ...offerCoupons,
              ...creditCoupons,
            ]}
          />
        )}
        {(isCartV2 || deliveryBy) && (
          <TicketLineV2
            currency={currency}
            label="shipping"
            price={totalShipping === 0.0 ? 'Free' : totalShipping}
          />
        )}
        {shouldDisplayTaxes && <TicketLineV2 currency={currency} label="Tax" price={totalTaxes} />}

        {!hasItemsFromGiftSet && shouldDisplayTaxes && (
          <TicketGroupedLines currency={currency} groupName="Gift Cards" items={giftCards} />
        )}

        <TicketLineV2
          currency={currency}
          dataTestId="checkout-total-ticket-line-v2"
          id="ticket-line-total"
          isBold
          label={shouldDisplayTaxes || hasItemsFromGiftSet ? 'Total' : 'Total (pre-tax)'}
          price={freeOrder ? 'Free' : hasItemsFromGiftSet ? 'Gift' : total}
        />
      </TotalPricesContainer>
      <Spacer size={24} />
      {!isCheckout && !isMobile && ctaButtonProps && (
        <>
          <CTAInTicket
            data-testid="checkout-ticket-cta"
            disabled={ctaButtonProps.disabled}
            isLoading={ctaButtonProps.isLoading}
            noMargin
            onClick={ctaButtonProps.onClick}
            text={ctaButtonProps.text}
            type={ctaButtonProps.type}
            // @ts-expect-error - ctaButtonProps.variant type too broad
            variant={ctaButtonProps.variant}
          >
            {ctaButtonProps.text}
          </CTAInTicket>
          <Spacer size={24} />
        </>
      )}
      {consultationProfileCountry === countriesCode.CA && (
        <>
          <Typography align="center" color="grey" variant="p3">
            No additional duties and taxes collected upon delivery.
          </Typography>
          <Spacer size={24} />
        </>
      )}
      {!isCheckout && (
        <>
          <SatisfactionGuaranteeMessage align="center" color="grey" variant="mono3">
            LOVE YOUR FIRST ORDER (OR IT&apos;S ON US)
          </SatisfactionGuaranteeMessage>
          <Spacer size={40} />
        </>
      )}
    </>
  );
};
