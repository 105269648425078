export const feedbackCategories = {
  HAIRCARE: 'haircare',
  SKINCARE: 'skincare',
} as const;

export const feedbackSubCategories = {
  SUPPLEMENTS: 'supplements',
  TOPICALS: 'topicals',
} as const;

export const feedbackUXCategories = [
  feedbackCategories.HAIRCARE,
  feedbackCategories.SKINCARE,
  feedbackSubCategories.SUPPLEMENTS,
] as const;

export type FeedbackUXCategory = (typeof feedbackUXCategories)[number];
