import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

import { ReactComponent as ShowerIcon } from 'assets/images/skincare/skintypeShower.svg';

const skintypeShower = {
  name: 'skintype_shower',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/skintype-shower`,
  title: 'How does your face feel right after you wash it?',
  icon: <ShowerIcon />,
  subtitle:
    'Answer this based on washing with water or a gentle cleanser, not a treatment or a scrub.',
  tip: {
    title: 'Why we ask:',
    content: 'How your skin reacts to cleansing is a useful way to assess how dry or oily it is.',
  },
  options: [
    {
      value: 'tights',
      label: 'On the dry side',
      description: 'Face may feel tight or “squeaky clean”',
    },
    {
      value: 'balanced',
      label: 'Balanced',
      description: 'Face feels comfortable and relatively hydrated',
    },
    {
      value: 'oily',
      label: 'On the oily side',
      description: 'Face may feel slick or appear shiny',
    },
    {
      value: 'depends_on_area',
      label: 'Mixed',
      description: 'Some areas of the face are a little oily, while others feel tight',
    },
    {
      value: 'not_sure',
      label: 'Not sure / I don’t know',
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_skintype_shower,
  getAnswers: ({ selected }) => ({
    diag_skintype_shower: selected,
  }),
};

export default skintypeShower;
